import { Box, Typography, IconButton, Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../api";
import { useNavigate } from 'react-router-dom';


const UpcomingDeposits = () => {

  //for the dashboard theme
  const theme = useTheme();
  // to change the color on theme selected
  const colors = tokens(theme.palette.mode);

  //the base url for the dashboard
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  //pagination
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // to show the count
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  //pagination buttons
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  // const [upcomingdeposits, setUsersList] = useState([]);

  //users list + filtered user lists
  const [upcomingDeposits, setUpcomingDeposits] = useState([]);

  //to filter the user list
  const [searchInput, setSearchInput] = useState("");

  //should make it dynamic
  // const access_token = getAccessToken();

  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchUpcomingDeposits(1, searchInput);
  };

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
    console.log('hiiii')
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  //calling the fetched API
  useEffect(() => {
    fetchUpcomingDeposits(page, pageSize, searchInput);
  }, [page, searchInput]);

  //Fetching the API
  const fetchUpcomingDeposits = async (pageNumber, pageSize, searchQuery) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data
    const apiUrl = `${base_url}/api/v1/ccapi/upcoming-deposits?page=${pageNumber}&page_size=${pageSize}&search_query=${searchQuery}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data.count);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      //this stores all the data of the users we need and displays it in the table
      setUpcomingDeposits(data.results);
      //   console.log(data);
      //   console.log(data.results);


    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  // const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
  //   pageNumbers.push(i);
  // }

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "Deposit ID",

    },
    {
      field: "name",
      headerName: "Name",
      valueGetter: (params) => params.row.investor.name,
      minWidth: 200,


    },
    {
      field: "email",
      headerName: "Email",
      valueGetter: (params) => params.row.investor.email,
      minWidth: 200,


    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.investor.phone_number,
      minWidth: 100,

    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 100,
    },
    {
      field: "installment_date",
      headerName: "Installment Date",
      renderCell: (params) => {
        const timestamp = params.row.installment_date;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      }

    },
    {
      field: "is_paused",
      headerName: "Paused",
      valueGetter: (params) => {
        if (params.value) {
          return "Yes";
        } else {
          return "No"
        }
      },
      minWidth: 100,
    },
    {
      field: "saving_plan_name",
      headerName: "Plan Name",
      minWidth: 100,
    },
    {
      field: "View Savings Plans",
      headerName: "View More Details",
      renderCell: ({ ...params }) => (
        <RouterLink
          style={{ textDecoration: "none" }}
          to={`/activeSavingPlan/${params.row.investor_id}/${params.row.investor.phone_number}/${params.row.investor.name ? params.row.investor.name : "No Name"}`}
        >
          {/* {console.log(params.row.name)} */}
          <Button variant="outlined" color="success">
            View More Details
          </Button>
        </RouterLink>
      ),
      minWidth: 200,
    },
  ];
  return (
    <>
      <Box m="20px">
        <Box>
          <Header
            title="Upcoming Deposit List"
            subtitle={`Total Number of Upcoming Deposits: ${data}`}
          />
        </Box>
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Box m="40px 0 0 0"  >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="#">
                Sip User List
              </Link>
              {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
            </Breadcrumbs>
          </div>
          <Box height="75vh" width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none"
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rowHeight={65}
              rows={upcomingDeposits}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <div>
                  <div>Deposit ID: {selectedRow.id}</div>
                  <div>Name: {selectedRow.investor.name}</div>
                  <div>Email ID: {selectedRow.investor.email}</div>
                  <div>Phone Number: {selectedRow.investor.phone_number}</div>
                  <div>Amount: {selectedRow.amount}</div>
                  <div>Installment Date: {selectedRow.installment_date}</div>
                  {/* <div>Paused: {selectedRow.is_paused}</div> */}
                  <div>Plan Name: {selectedRow.saving_plan_name}</div>
                  <div>Created Date: {selectedRow.created_at}</div>
                  {/* Add other fields from the selectedRow */}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default UpcomingDeposits;