import { Box, Typography, IconButton, Button, Modal } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../../api";
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport style={{ background: "#fff !important", margin: "5px" }} />
    </GridToolbarContainer>
  );
}
//   const today = dayjs();

const EgoldBankDetailsList = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [egoldPlanList, setEgoldPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedOptionForTransaction, setselectedOptionForTransaction] = useState('allTransaction');
  const [quantity, setQuantity] = useState(false);
  const [amount, setAmount] = useState(false);
  const [allTransaction, setAllTransactions] = useState(true);
  // filter for plan type ONE TIME/ SUBSCRIPTION
  const [selecteOptionForPlanType, setselecteOptionForPlanType] = useState('allPlanTypes');
  const [oneTime, setOneTime] = useState(false);
  const [sip, setSip] = useState(false);
  const [allPlanTypes, setAllPlanTypess] = useState(true);
  const [selecteOptionForAutopayStatus, setselecteOptionForAutopayStatus] = useState('allAutopay');
  const [allAutopayStatus, setAllAutopayStatus] = useState(true);
  const [activeAutopay, setActiveAutopay] = useState(false);
  const [pausedAutopay, setPausedAutopay] = useState(false);
  const [cancelledAutopay, setCancelledAutopay] = useState(false);

  // bank details filter active/inactive
  const [activePlans, setactivePlans] = useState(false);
  const [notActivePlans, setnotActivePlans] = useState(false);
  const [allPlans, setallPlans] = useState(true);
  const [selectedOption, setSelectedOption] = useState('allPlans');

  // bank details filter delete status
  const [deletedStatus, setDeletedStatus] = useState(false);
  const [notDeletedStatus, setNotDeletedStatus] = useState(false);
  const [allDeleteStatus, setAllDeleteStatus] = useState(true);
  const [selectedOptionforDelete, setSelectedOptionForDelete] = useState('allDeletedStatus');

  // augnont added to bank

  const [augmontAdded, setAugmontAdded] = useState(false);
  const [augmontNotAdded, setAugmontNotAdded] = useState(false);
  const [allAugmontStatus, setAllAugmontStatus] = useState(true);
  const [selectedOptionForAugmont, setSelectedOptionForAugmont] = useState('allAugmontStatus');

  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchBankDetailsListData(page, pageSize, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchBankDetailsListData(page, pageSize, searchInput);
    }
  }

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //calling the fetched API
  useEffect(() => {
    fetchBankDetailsListData(page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, allDeleteStatus, deletedStatus, notDeletedStatus, allAugmontStatus, augmontAdded, augmontNotAdded);
  }, [page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, allDeleteStatus, deletedStatus, notDeletedStatus, allAugmontStatus, augmontAdded, augmontNotAdded]);

  //Fetching the API
  const fetchBankDetailsListData = async (pageNumber, pageSize, searchQuery, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, allDeleteStatus, deletedStatus, notDeletedStatus, allAugmontStatus, augmontAdded, augmontNotAdded) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data based on the search input

    let apiUrl = `${base_url}/api/v1/ccapi/egold/augmont-bank-detail?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }

    if (allPlans) {
      // If allPlans is true, don't add the is_active parameter to the apiUrl
    } else if (activePlans) {
      apiUrl += '&is_active=True';
    } else if (notActivePlans) {
      apiUrl += '&is_active=False';
    }
    if (allDeleteStatus) {
      // If allPlans is true, don't add the is_active parameter to the apiUrl
    } else if (deletedStatus) {
      apiUrl += '&is_deleted=True';
    } else if (notDeletedStatus) {
      apiUrl += '&is_deleted=False';
    }

    if (allAugmontStatus) {
      // If allPlans is true, don't add the is_active parameter to the apiUrl
    } else if (augmontAdded) {
      apiUrl += '&is_augmont_added=True';
    } else if (augmontNotAdded) {
      apiUrl += '&is_augmont_added=False';
    }
    if (allDeleteStatus) {
      // If allPlans is true, don't add the is_active parameter to the apiUrl
    } else if (deletedStatus) {
      apiUrl += '&is_deleted=True';
    } else if (notDeletedStatus) {
      apiUrl += '&is_deleted=False';
    }

    if (allTransaction) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (quantity) {
      apiUrl += '&transaction_in=QUANTITY';
    } else if (amount) {
      apiUrl += '&transaction_in=AMOUNT';
    }

    if (allPlanTypes) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (oneTime) {
      apiUrl += '&plan_type=ONE TIME';
    } else if (sip) {
      apiUrl += '&plan_type=SUBSCRIPTION';
    }

    if (allAutopayStatus) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (activeAutopay) {
      apiUrl += '&autopay_status=ACTIVE';
    } else if (pausedAutopay) {
      apiUrl += '&autopay_status=PAUSE';
    } else if (cancelledAutopay) {
      apiUrl += '&autopay_status=CANCELLED';
    }

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      //this stores all the data of the users we need and displays it in the table
      setEgoldPlanList(data.results);
      //   console.log(data);
      //   console.log(data.results);


    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchBankDetailsListData(page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, allDeleteStatus, deletedStatus, notDeletedStatus, allAugmontStatus, augmontAdded, augmontNotAdded);
  };


  //   filter for bank details active/inactive
  const handleCheckboxChangeForAllUser = () => {
    setallPlans(true);
    setactivePlans(false);
    setnotActivePlans(false);
  };

  const handleCheckboxChangeForactivePlansUser = () => {


    setnotActivePlans(false);
    setallPlans(false);
    setactivePlans(true);
  };

  const handleCheckboxChangeFornotActivePlansUser = () => {
    setactivePlans(false);
    setallPlans(false);
    setnotActivePlans(true);
  };


  //   filter for bank details active/inactive
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);

    switch (event.target.value) {
      case 'allPlans':
        handleCheckboxChangeForAllUser();
        break;
      case 'activePlans':
        handleCheckboxChangeForactivePlansUser();
        break;
      case 'inactivePlans':
        handleCheckboxChangeFornotActivePlansUser();
        break;
      default:
        break;
    }
  };

  const clearActiveStatusFilters = () => {
    setSelectedOption('allPlans');
    setallPlans(true);
    setactivePlans(false);
    setnotActivePlans(false);
  };
  //   filter for delete status
  const handleSelectChangeForAllDeleteStatus = () => {
    setAllDeleteStatus(true);
    setDeletedStatus(false);
    setNotDeletedStatus(false);
  };

  const handleSelectChangeForDeleted = () => {
    setAllDeleteStatus(false);
    setDeletedStatus(true);
    setNotDeletedStatus(false);
  };

  const handleSelectChangeForNotDeleted = () => {
    setAllDeleteStatus(false);
    setDeletedStatus(false);
    setNotDeletedStatus(true);
  };

  //   filter for delete status 
  const handleSelectChangeDeleteStatus = (event) => {
    setSelectedOptionForDelete(event.target.value);

    switch (event.target.value) {
      case 'allDeletedStatus':
        handleSelectChangeForAllDeleteStatus();
        break;
      case 'deleted':
        handleSelectChangeForDeleted();
        break;
      case 'notDeleted':
        handleSelectChangeForNotDeleted();
        break;
      default:
        break;
    }
  };

  const clearDeleteStatusFilters = () => {
    setSelectedOptionForDelete('allDeletedStatus');
    setAllDeleteStatus(true);
    setDeletedStatus(false);
    setNotDeletedStatus(false);
  };

  // filter for augmont status
  const handleSelectChangeForAllAugmontStatus = () => {
    setAllAugmontStatus(true);
    setAugmontAdded(false);
    setAugmontNotAdded(false);
  };

  const handleSelectChangeForAugmontAdded = () => {
    setAllAugmontStatus(false);
    setAugmontAdded(true);
    setAugmontNotAdded(false);
  };

  const handleSelectChangeForAugmontNotAdded = () => {
    setAllAugmontStatus(false);
    setAugmontAdded(false);
    setAugmontNotAdded(true);
  };

  // filter for augmont status 
  const handleSelectChangeAugmontStatus = (event) => {
    setSelectedOptionForAugmont(event.target.value);

    switch (event.target.value) {
      case 'allAugmontStatus':
        handleSelectChangeForAllAugmontStatus();
        break;
      case 'augmontAdded':
        handleSelectChangeForAugmontAdded();
        break;
      case 'augmontNotAdded':
        handleSelectChangeForAugmontNotAdded();
        break;
      default:
        break;
    }
  };

  const clearAugmontStatusFilters = () => {
    setSelectedOptionForAugmont('allAugmontStatus');
    setAllAugmontStatus(true);
    setAugmontAdded(false);
    setAugmontNotAdded(false);
  };


  //columns needed in the table
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "ID",
    },

    {
      field: "account_number",
      headerName: "Account Number",
    },
    {
      field: "account_name",
      headerName: "Account Name",
    },
    {
      field: "ifsc_code",
      headerName: "IFSC Code",
    },
    {
      field: "bank_id",
      headerName: "Bank ID",
    },
    {
      field: "verified_name",
      headerName: "Verified Name",
    },
    {
      field: "is_augmont_added",
      headerName: "Is Augmont Added",
      valueGetter: (params) => params.row.is_augmont_added ? "Yes" : "No",
    },
    {
      field: "augmont_user.portfolio_purchased_egold",
      headerName: "Portfolio Purchased Egold",
      valueGetter: (params) => params.row.augmont_user.portfolio_purchased_egold
    },
    {
      field: "augmont_user.portfolio_invested_egold",
      headerName: "Portfolio Invested Egold",
      valueGetter: (params) => params.row.augmont_user.portfolio_invested_egold
    },
    {
      field: "augmont_user.portfolio_benefit_egold",
      headerName: "Portfolio Benefit Egold",
      valueGetter: (params) => params.row.augmont_user.portfolio_benefit_egold
    },
    {
      field: "augmont_user.kyc_verification_date",
      headerName: "KYC Verification Date",
      renderCell: (params) => {
        const date = new Date(params.row.augmont_user.kyc_verification_date);
        return date.toLocaleDateString();
      },
    },
    {
      field: "augmont_user.created_at",
      headerName: "User Created At",
      renderCell: (params) => {
        const date = new Date(params.row.augmont_user.created_at);
        return date.toLocaleDateString();
      },
    },
    {
      field: "is_active",
      headerName: "Is Active",
      valueGetter: (params) => params.row.is_active ? "Yes" : "No",
    },
    {
      field: "is_deleted",
      headerName: "Is Deleted",
      valueGetter: (params) => params.row.is_deleted ? "Yes" : "No",
    },
    //   {
    //     field: "created_at",
    //     headerName: "Created At",
    //     renderCell: (params) => {
    //       const date = new Date(params.row.created_at);
    //       return date.toLocaleDateString();
    //     },
    //   }
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title="UPI Details"
            subtitle={`Total Number of UPI: ${data.count}`}
          />
        </Box>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search user_number / user_name / email_id / unique_id / transaction_id / merchant_txn_id"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        {/* DataTable */}

        <Box m="30px 0 0 0" height="75vh">
          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>

            <Box>
              <Select
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <MenuItem value="allPlans">Bank Accounts Status</MenuItem>
                <MenuItem value="activePlans">Active Bank Accounts</MenuItem>
                <MenuItem value="inactivePlans">Inactive Bank Accounts</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearActiveStatusFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selectedOptionforDelete}
                onChange={handleSelectChangeDeleteStatus}
              >
                <MenuItem value="allDeletedStatus">Bank Delete Status</MenuItem>
                <MenuItem value="deleted">Deleted</MenuItem>
                <MenuItem value="notDeleted">Not Deleted</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearDeleteStatusFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selectedOptionForAugmont}
                onChange={handleSelectChangeAugmontStatus}
              >
                <MenuItem value="allAugmontStatus">Augmont Bank Status</MenuItem>
                <MenuItem value="augmontAdded">Augmont Added</MenuItem>
                <MenuItem value="augmontNotAdded">Augmont Not Added</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearAugmontStatusFilters}>X</Button>
              </Box>
            </Box>


            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>

          </Box>


          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                E-Gold Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"

              >
                E-Gold UPI Details
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                background: `#fff !important`,
                margin: "5px"
              },
              marginTop: "5px",
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={egoldPlanList}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== 'Sl_No' && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseModal} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Pagination Buttons */}
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EgoldBankDetailsList;