import { Box, Typography, IconButton, Button, Modal } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../../api";
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function CustomToolbar() {
  return (
    <div sx={{ margin: "3px", background: "#fff", padding: "5px" }} >
      <GridToolbarContainer>

        <GridToolbarExport style={{ margin: "2px" }} />

      </GridToolbarContainer>
    </div>
  );
}
//   const today = dayjs();

const EgoldWithdrawalList = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [egoldPlanList, setEgoldPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  // withdrawal in filter
  const [allWithdrawalInStatus, setAllWithdrawalInStatus] = useState(true);
  const [withdrawalInCash, setWithdrawalInCash] = useState(false);
  const [withdrawalInGold, setWithdrawalInGold] = useState(false);
  const [selectedOptionForWithdrawalIn, setSelectedOptionForWithdrawalIn] = useState('allWithdrawalInStatus');


  // filter for withdrawal in status pending, completed, processed

  const [allStatus, setAllStatus] = useState(true);
  const [statusPending, setStatusPending] = useState(false);
  const [statusCompleted, setStatusCompleted] = useState(false);
  const [statusRejected, setStatusRejected] = useState(false);
  const [selectedOptionForStatus, setSelectedOptionForStatus] = useState('allStatus');

  // processed filter

  const [allProcessedStatus, setAllProcessedStatus] = useState(true);
  const [processedTrue, setProcessedTrue] = useState(false);
  const [processedFalse, setProcessedFalse] = useState(false);
  const [selectedOptionForProcessed, setSelectedOptionForProcessed] = useState('allProcessedStatus');

  // completed filter

  const [allCompletedStatus, setAllCompletedStatus] = useState(true);
  const [completedTrue, setCompletedTrue] = useState(false);
  const [completedFalse, setCompletedFalse] = useState(false);
  const [selectedOptionForCompleted, setSelectedOptionForCompleted] = useState('allCompletedStatus');



  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchWithdrawalListData(page, pageSize, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchWithdrawalListData(page, pageSize, searchInput);
    }
  }

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //calling the fetched API
  useEffect(() => {
    fetchWithdrawalListData(page, pageSize, searchInput, startDate, endDate, allWithdrawalInStatus, withdrawalInCash, withdrawalInGold, allStatus, statusPending, statusCompleted, statusRejected, allProcessedStatus, processedTrue, processedFalse, allCompletedStatus, completedTrue, completedFalse,);
  }, [page, pageSize, searchInput, startDate, endDate, allWithdrawalInStatus, withdrawalInCash, withdrawalInGold, allStatus, statusPending, statusCompleted, statusRejected, allProcessedStatus, processedTrue, processedFalse, allCompletedStatus, completedTrue, completedFalse,]);

  //Fetching the API
  const fetchWithdrawalListData = async (pageNumber, pageSize, searchQuery, startDate, endDate, allWithdrawalInStatus, withdrawalInCash, withdrawalInGold, allStatus, statusPending, statusCompleted, statusRejected, allProcessedStatus, processedTrue, processedFalse, allCompletedStatus, completedTrue, completedFalse) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data based on the search input

    let apiUrl = `${base_url}/api/v1/ccapi/egold/v2/egold-cash-withdrawal?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }


    if (allWithdrawalInStatus) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (withdrawalInCash) {
      apiUrl += '&withdrawal_in=AMOUNT';
    } else if (withdrawalInGold) {
      apiUrl += '&withdrawal_in=QUANTITY';
    }

    if (allStatus) {
    }
    else if (statusPending) {
      apiUrl += '&withdrawal_status=PENDING';
    } else if (statusCompleted) {
      apiUrl += '&withdrawal_status=COMPLETED';
    } else if (statusRejected) {
      apiUrl += '&withdrawal_status=REJECTED';
    }

    if (allProcessedStatus) {
    }
    else if (processedTrue) {

      apiUrl += '&is_processed=True';
    } else if (processedFalse) {
      apiUrl += '&is_processed=False';
    }

    if (allCompletedStatus) {
    }
    else if (completedTrue) {
      apiUrl += '&is_completed=True';
    }
    else if (completedFalse) {
      apiUrl += '&is_completed=False';
    }

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      //this stores all the data of the users we need and displays it in the table
      setEgoldPlanList(data.results);
      //   console.log(data);
      //   console.log(data.results);


    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchWithdrawalListData(page, pageSize, searchInput, startDate, endDate, allWithdrawalInStatus, withdrawalInCash, withdrawalInGold, allStatus, statusPending, statusCompleted, statusRejected, allProcessedStatus, processedTrue, processedFalse);
  };

  const handleSelectChangeForAllWithdrawalInStatus = () => {
    setAllWithdrawalInStatus(true);
    setWithdrawalInCash(false);
    setWithdrawalInGold(false);
  };

  const handleSelectChangeForWithdrawalInCash = () => {
    setAllWithdrawalInStatus(false);
    setWithdrawalInCash(true);
    setWithdrawalInGold(false);
  };

  const handleSelectChangeForWithdrawalInGold = () => {
    setAllWithdrawalInStatus(false);
    setWithdrawalInCash(false);
    setWithdrawalInGold(true);
  };

  const handleSelectChangeWithdrawalInStatus = (event) => {
    setSelectedOptionForWithdrawalIn(event.target.value);

    switch (event.target.value) {
      case 'allWithdrawalInStatus':
        handleSelectChangeForAllWithdrawalInStatus();
        break;
      case 'withdrawalInCash':
        handleSelectChangeForWithdrawalInCash();
        break;
      case 'withdrawalInGold':
        handleSelectChangeForWithdrawalInGold();
        break;
      default:
        break;
    }
  };

  const clearWithdrawalInStatusFilters = () => {
    setSelectedOptionForWithdrawalIn('allWithdrawalInStatus');
    setAllWithdrawalInStatus(true);
    setWithdrawalInCash(false);
    setWithdrawalInGold(false);
  };
  //   filter for withdrawal status pending, completed, rejected
  const handleSelectChangeForAllStatus = () => {
    setAllStatus(true);
    setStatusPending(false);
    setStatusCompleted(false);
    setStatusRejected(false);
  };

  const handleSelectChangeForStatusPending = () => {
    setAllStatus(false);
    setStatusPending(true);
    setStatusCompleted(false);
    setStatusRejected(false);
  };

  const handleSelectChangeForStatusCompleted = () => {
    setAllStatus(false);
    setStatusPending(false);
    setStatusCompleted(true);
    setStatusRejected(false);
  };

  const handleSelectChangeForStatusRejected = () => {
    setAllStatus(false);
    setStatusPending(false);
    setStatusCompleted(false);
    setStatusRejected(true);
  };

  const handleSelectChangeStatus = (event) => {
    setSelectedOptionForStatus(event.target.value);

    switch (event.target.value) {
      case 'allStatus':
        handleSelectChangeForAllStatus();
        break;
      case 'statusPending':
        handleSelectChangeForStatusPending();
        break;
      case 'statusCompleted':
        handleSelectChangeForStatusCompleted();
        break;
      case 'statusRejected':
        handleSelectChangeForStatusRejected();
        break;
      default:
        break;
    }
  };

  const clearStatusFilters = () => {
    setSelectedOptionForStatus('allStatus');
    setAllStatus(true);
    setStatusPending(false);
    setStatusCompleted(false);
    setStatusRejected(false);
  };

  const handleSelectChangeForAllProcessedStatus = () => {
    setAllProcessedStatus(true);
    setProcessedTrue(false);
    setProcessedFalse(false);
  };

  const handleSelectChangeForProcessedTrue = () => {
    setAllProcessedStatus(false);
    setProcessedTrue(true);
    setProcessedFalse(false);
  };

  const handleSelectChangeForProcessedFalse = () => {
    setAllProcessedStatus(false);
    setProcessedTrue(false);
    setProcessedFalse(true);
  };

  const handleSelectChangeProcessedStatus = (event) => {
    setSelectedOptionForProcessed(event.target.value);

    switch (event.target.value) {
      case 'allProcessedStatus':
        handleSelectChangeForAllProcessedStatus();
        break;
      case 'processedTrue':
        handleSelectChangeForProcessedTrue();
        break;
      case 'processedFalse':
        handleSelectChangeForProcessedFalse();
        break;
      default:
        break;
    }
  };

  const clearProcessedStatusFilters = () => {
    setSelectedOptionForProcessed('allProcessedStatus');
    setAllProcessedStatus(true);
    setProcessedTrue(false);
    setProcessedFalse(false);
  };

  //   filter for withdrawal completed, not completed

  const handleSelectChangeForAllCompletedStatus = () => {
    setAllCompletedStatus(true);
    setCompletedTrue(false);
    setCompletedFalse(false);
  };

  const handleSelectChangeForCompletedTrue = () => {
    setAllCompletedStatus(false);
    setCompletedTrue(true);
    setCompletedFalse(false);
  };

  const handleSelectChangeForCompletedFalse = () => {
    setAllCompletedStatus(false);
    setCompletedTrue(false);
    setCompletedFalse(true);
  };

  const handleSelectChangeCompletedStatus = (event) => {
    setSelectedOptionForCompleted(event.target.value);

    switch (event.target.value) {
      case 'allCompletedStatus':
        handleSelectChangeForAllCompletedStatus();
        break;
      case 'completedTrue':
        handleSelectChangeForCompletedTrue();
        break;
      case 'completedFalse':
        handleSelectChangeForCompletedFalse();
        break;
      default:
        break;
    }
  };

  const clearCompletedStatusFilters = () => {
    setSelectedOptionForCompleted('allCompletedStatus');
    setAllCompletedStatus(true);
    setCompletedTrue(false);
    setCompletedFalse(false);
  };

  //columns needed in the table
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      width: 50,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "ID",
      width: 50,
    },
    {
      field: "augmont_user.user_name",
      headerName: "User Name",
      valueGetter: (params) => params.row.augmont_user.user_name ? params.row.augmont_user.user_name : "N/A",
    },
    {
      field: "augmont_user.phone_number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.augmont_user.phone_number ? params.row.augmont_user.phone_number : "N/A",

    },
    {
      field: "augmont_user.email",
      headerName: "Email",
      valueGetter: (params) => params.row.augmont_user.email ? params.row.augmont_user.email : "N/A",

    },
    {
      field: "upi",
      headerName: "UPI",
      valueGetter: (params) => params.row.upi_detail ? params.row.upi_detail.upi : "N/A",
    },

    {
      field: "scheme",
      headerName: "Scheme",
    },
    {
      field: "plan_type",
      headerName: "Plan Type",
    },
    {
      field: "withdrawal_in",
      headerName: "Withdrawal In",
    },
    {
      field: "amount",
      headerName: "Amount",
    },
    {
      field: "metal_quantity",
      headerName: "Metal Quantity",
    },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
    },
    {
      field: "withdrawal_status",
      headerName: "Withdrawal Status",
    },
    {
      field: "is_processed",
      headerName: "Withdrawal Processed",
      valueGetter: (params) => params.row.is_processed ? "Yes" : "No",
    },
    {
      field: "is_completed",
      headerName: "Withdrawal Completed",
      valueGetter: (params) => params.row.is_completed ? "Yes" : "No",
    },
    {
      field: "created_at",
      headerName: "Withdrawal Created At",
      renderCell: (params) => {
        const date = new Date(params.row.created_at);
        return date.toLocaleDateString();
      },
    },
    {
      field: "Live Status",
      headerName: "Live Withdrawal Status",
      renderCell: ({ ...params }) => (
        <RouterLink
          style={{ textDecoration: "none" }}
          to={`/reraiseWithdrawalRequest/${params.row.id}/${params.row.augmont_user?.phone_number}/${params.row.transaction_id}/${params.row.augmont_user?.user_name ? params.row.augmont_user?.user_name : "No Name"
            }`}
        >
          <Button variant="outlined" color="success">
            Live Withdrawal Status
          </Button>
        </RouterLink>
      ),
      minWidth: 200,
    },
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title="Withdrawal List"
            subtitle={`Total Number of withdrawals: ${data.count}`}
          />
        </Box>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search number / name / email / unique_id / account_number / ifsc / account_name"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        {/* DataTable */}

        <Box m="30px 0 0 0" height="75vh">
          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>


            <Box>
              <Select
                name="withdrawlInStatus"
                value={selectedOptionForWithdrawalIn}
                onChange={handleSelectChangeWithdrawalInStatus}
                label="Transaction Type"
              >
                <MenuItem value="allWithdrawalInStatus">Transaction Type</MenuItem>
                <MenuItem value="withdrawalInCash">Amount</MenuItem>
                <MenuItem value="withdrawalInGold">Quantity</MenuItem>
              </Select>

              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={"2px"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearWithdrawalInStatusFilters}>X</Button>
              </Box>

            </Box>

            <Box>
              <Select
                value={selectedOptionForStatus}
                onChange={handleSelectChangeStatus}
              >
                <MenuItem value="allStatus">Withdrawal Status</MenuItem>
                <MenuItem value="statusPending">Pending</MenuItem>
                <MenuItem value="statusCompleted">Completed</MenuItem>
                <MenuItem value="statusRejected">Rejected</MenuItem>
              </Select>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={"2px"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearStatusFilters}>X</Button>
              </Box>
            </Box>

            <Box>


              <Select
                value={selectedOptionForProcessed}
                onChange={handleSelectChangeProcessedStatus}
              >
                <MenuItem value="allProcessedStatus">Processed Status</MenuItem>
                <MenuItem value="processedTrue">Processed</MenuItem>
                <MenuItem value="processedFalse">Not Processed</MenuItem>
              </Select>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={"2px"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearProcessedStatusFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                labelId="completedStatus-label"
                name="completedStatus"
                value={selectedOptionForCompleted}
                onChange={handleSelectChangeCompletedStatus}
                label="Completed Status"
              >
                <MenuItem value="allCompletedStatus">Withdrawal Complete Status</MenuItem>
                <MenuItem value="completedTrue">Completed</MenuItem>
                <MenuItem value="completedFalse">Not Completed</MenuItem>
              </Select>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={"2px"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearCompletedStatusFilters}>X</Button>
              </Box>
            </Box>


            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>

          </Box>


          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                E-Gold Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"

              >
                Withdrawal List
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                background: `#fff !important`,
                margin: "5px"
              },
              marginTop: "5px",
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={egoldPlanList}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== 'Sl_No' && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseModal} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Pagination Buttons */}
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EgoldWithdrawalList;