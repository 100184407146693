import { Box, Typography, Button, DialogTitle, DialogContent, DialogActions, Dialog } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";

const FlexiActiveSavingPlan = () => {

  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const access_token = getAccessToken();
  //to show the fecthed state of the API
  const { userId, phoneNumber, name } = useParams();
  //   const { userName } = useParams();
  const [userSavingsPlan, setuserSavingsPlan] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize] = useState(25); // You can set your desired page size
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Prepping to fetch the API

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk3ODczMjc2LCJpYXQiOjE2OTc3ODY4NzYsImp0aSI6IjYyM2VhNjBkMDAxZDQ4MzM4OWUwNmNmMWJiNzhhYzQ3IiwidXNlcl9pZCI6Mjc4Mn0.FXJ0BKLYGoihROXUBGtuHHD2eb6GRVOfNWAD4pIUdV8";

  useEffect(() => {
    // Fetch user transaction details based on the userId and set them in the state
    // You can implement the transaction data fetching logic here.
    fetchUserSavingsPlanFromAPI(userId, page, pageSize);
  }, [userId, page, pageSize]);

  //Fetching the Saving Plans List of a user using investor-id
  const fetchUserSavingsPlanFromAPI = async (userId, pageNumber, pageSize) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/saving-plan-list?is_active=True&&has_amount=True&investor_id=${userId}&page=${pageNumber}&page_size=${pageSize}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl,
        {
          method: "GET",
          headers,
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setuserSavingsPlan(data.results); //only the list of saving plans
      // console.log(data); //all the data from the api
      setTotalRows(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
      // console.log(name)
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };

  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get('page');
      setPage(parseInt(pageNumber));
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: '5px', borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button style={{ padding: '5px', borderRadius: "5px" }} key={1} onClick={() => handlePageClick(1)}>
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: '5px', borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: '5px', borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button style={{ padding: '5px', borderRadius: "5px" }} key="next" onClick={handleNextPage} disabled={!nextPage}>
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
    pageNumbers.push(i);
  }

  //   const displayUsername = userName || 'Unknown User';

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "plan_id",
      headerName: "Plan ID",
      minWidth: 150,

    },
    {
      field: "plan_name",
      headerName: "Plan Name",
      cellClassName: "name-column--cell",
      minWidth: 120,

    },
    {
      field: "type_of_investment",
      headerName: "Investment Type",

    },
    {
      field: "saving_type",
      headerName: "Saving Type",

    },
    {
      field: "plan_type",
      headerName: "Plan Type",
    },
    {
      field: "duration_of_saving_in_months",
      headerName: "Duration",
    },
    {
      field: "amount",
      headerName: "Amount",
    },
    {
      field: "available_amount",
      headerName: "Available Amount",
    },
    {
      field: "earned_interest",
      headerName: "Interest Earned",
    },
    {
      field: "created_at",
      headerName: "Created Date",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },

    },
    // {
    //   field: "is_active",
    //   headerName: "Active",

    // },
    {
      field: "is_closed",
      headerName: "Plan Closed",

    },
    // {
    //   field: "is_draft",
    //   headerName: "Draft",

    // },
    {
      field: "Transactions",
      headerName: "View Transactions",
      renderCell: ({ ...params }) => (
        // <RouterLink style={{textDecoration:"none"}} to={`/userSavingPlans/${params.row.id}/${params.row.phone_number}`}>
        <RouterLink to={`/userTransactions/${params.row.plan_id}/${userId}/${phoneNumber}/${name}`} >

          <Button variant="outlined" color="success">View Transactions</Button>
        </RouterLink>
      ),
      width: 200,

    },
  ];

  if (userSavingsPlan.length === 0) {
    return <div>
      <Header title={`User ID: ${userId}`} subtitle={`Phone Number: ${phoneNumber}`} />
      No Plans Created.
    </div>;
  } else
    return (
      <>
        <Box m="20px">
          <h2>Monthly Plans</h2>
          <Box>
            <Header title={`User ID: ${userId}`} subtitle={`Phone Number: ${phoneNumber}`} />
            <h3>Name: {name}</h3>
          </Box>
          {/* <Box>
          <RouterLink to="/fixedactiveplans">
          <Button sx={{margin: '5px', padding:'5px'}} variant="outlined" color="success">
            FIXED
          </Button>
          </RouterLink >
          <RouterLink to="/flexiactiveplans">
          <Button sx={{margin: '5px', padding:'5px'}} variant="outlined" color="success">
            FLEXI
          </Button>
          </RouterLink>
        </Box> */}
          <Box m="40px 0 0 0" height="65vh">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                  Dashboard
                </Link>
                <Link underline="hover" color="inherit" component={RouterLink} to="/totalSipUsers">
                  Sip User List
                </Link>
                <Link underline="hover" color="inherit" component={RouterLink} to="#">
                  {name}
                </Link>
                {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
              </Breadcrumbs>
            </div>
            <Box height="75vh" width="100%"
              sx={
                {
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none"
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    backgroundColor: colors.blueAccent[700],
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                }
              }
            >
              <DataGrid
                sx={{
                  "& .MuiDataGrid-cellContent": {
                    whiteSpace: 'normal', overflow: 'visible', wordWrap: 'break-word'
                  },
                }}
                rowHeight={75}
                rows={userSavingsPlan}
                columns={columns}
                loading={loading}
                pagination
                page={page}
                getRowId={(row) => row.plan_id}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                rowCount={totalRows}
                hideFooterPagination={true}
                onRowClick={handleRowClick}
              />
            </Box>
            <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
              <DialogTitle>User Details</DialogTitle>
              <DialogContent>
                {selectedRow && (
                  <div>
                    <div>Plan ID: {selectedRow.plan_id}</div>
                    <div>Plan Name: {selectedRow.plan_name}</div>
                    <div>Investment Type: {selectedRow.type_of_investment}</div>
                    <div>Saving Type: {selectedRow.saving_type}</div>
                    <div>Plan Type: {selectedRow.plan_type}</div>
                    <div>Duration: {selectedRow.duration_of_saving_in_months}</div>
                    <div>Amount: {selectedRow.amount}</div>
                    <div>Available Amount: {selectedRow.available_amount}</div>
                    <div>Interest Earned: {selectedRow.earned_interest}</div>
                    <div>Created Date: {selectedRow.created_at}</div>
                    <div>Plan Closed: {selectedRow.is_closed}</div>
                    {/* Add other fields from the selectedRow */}
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Box
              display={"flex"}
              m={"10px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {generatePageButtons()}
            </Box>
          </Box>
        </Box>
      </>
    );
};

export default FlexiActiveSavingPlan;
