
import { Box, Typography, IconButton, Button, Modal } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport style={{ background: "#fff !important", margin: "5px" }} />
    </GridToolbarContainer>
  );
}
//   const today = dayjs();

const EgoldWithdrawalLiveStatus = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const { userId, phoneNumber, transactionId, name } = useParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [egoldPlanList, setEgoldPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchUPIJuspayDetailsList(userId, page, pageSize, searchInput);
  };

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDialogClick = (e) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleReRaiseWithdrawalFormclose = () => {
    setIsDialogOpen(false);
    setUpi('')
    setSelectedOption('')
    setResponse([]);
  }

  //calling the fetched API
  useEffect(() => {
    // console.log(transactionId);
    fetchUPIJuspayDetailsList(transactionId, page, pageSize, searchInput, startDate, endDate);
  }, [transactionId, page, pageSize, searchInput, startDate, endDate]);

  //Fetching the API
  const fetchUPIJuspayDetailsList = async (transactionId, pageNumber, pageSize, searchQuery, startDate, endDate) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data based on the search input

    let apiUrl = `${base_url}/api/v1/ccapi/withdrawal/status/${transactionId}?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      const resultsArray = Array.isArray(data) ? data : [data];
      setEgoldPlanList(resultsArray);
      // console.log(resultsArray);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchUPIJuspayDetailsList(transactionId, page, pageSize, searchInput, startDate, endDate);
  };


  const [upi, setUpi] = useState('');
  const [phone, setPhone] = useState('')
  const [phoneValidated, setPhoneValidated] = useState(true);
  const [upiValidated, setUpiValidated] = useState(true);
  const [upiType, setUpiType] = useState('');
  const [response, setResponse] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const accessToken = getAccessToken();

  const onPhoneChange = (event) => {
    // setPhone(event.target.value);
    if (/^[0-9]{0,9}$/.test(event.target.value) || event.target.value === '') {
      setPhone(event.target.value);
      validatePhone(event.target.value);
    }
  };

  const onUpiTypeChange = (event) => {
    setUpiType(event.target.value);
  }

  const onUpiChange = (event) => {
    if (/^[a-zA-Z0-9._@-]+$/.test(event.target.value) || event.target.value === '') {
      setUpi(event.target.value);
      validateUpi(event.target.value);
    }

  };
  // debugger;
  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{1,9}$/;
    if (phoneRegex.test(phone)) {
      setPhoneValidated(false);
    } else {
      setPhoneValidated(true);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value); // Update the state with the selected radio button value
  };

  const validateUpi = (upi) => {
    const upiRegex = /^[a-zA-Z0-9@.\-_]{2,49}[a-zA-Z._]{0,49}$/;
    if (upiRegex.test(upi)) {
      setUpiValidated(false);
    } else {
      setUpiValidated(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${base_url}/api/v1/ccapi/reraise/withdrawal-request/v2`, {
      method: 'POST',
      body: JSON.stringify({
        withdrawal_id: userId,
        upi: upi,
        upi_type: selectedOption

      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }

    })
      .then((res) => res.json())
      .then((data) => {
        const response = [data];
        // console.log(phone, upi)
        setResponse(response)

      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  useEffect(() => {
    validatePhone(phone);
    validateUpi(upi)

  }, [phoneValidated, selectedOption, upi, userId]);

  //columns needed in the table
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      maxWidth: 50,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "transaction_id",
      headerName: "Transaction Id",
      width: 260,
    },
    {
      field: "withdrawal_status",
      headerName: "Withdrawal Status"
    },
    {
      field: "reRaiseRequest",
      headerName: "Re-Raise Withdrawal",
      width: 260,
      renderCell: () => {
        return (
          <Button onClick={handleDialogClick} variant="contained" color="success">Raise Withdrawal Request</Button>
        )
      }
    }


  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title="Live Withdrawal Status"
            subtitle={`NAME : ${name} `}
          />
          <p>Phone: {phoneNumber}</p>
          <p>User Id: {userId}</p>
        </Box>
        {/* SEARCH BAR */}
        {/* <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search user_number / user_name / email_id / unique_id / transaction_id / merchant_txn_id"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box> */}
        {/* DataTable */}

        <Box m="30px 0 0 0" height="75vh">
          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>


            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size&nbsp;</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>

          </Box>


          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                E-Gold Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/upiDetails"

              >
                E-Gold UPI Details
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"

              >
                UPI Juspay Response
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="40vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                background: `#fff !important`,
                margin: "5px"
              },
              marginTop: "5px",
            }}
          >
            <DataGrid
              rowHeight={60}
              getRowId={(row) => row.transaction_id}
              rows={egoldPlanList}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== 'Sl_No' && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseModal} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>


          {/* Pagination Buttons */}
          {/* <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box> */}
        </Box>
        <Box>

          <Dialog open={isDialogOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>Re-Raise Withdrawal Request</DialogTitle>
            <DialogContent>
              <div>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <div style={{
                    maxWidth: "450px",
                    padding: "10px",
                    boxShadow: "rgba(0, 0, 0, 1) 0px 25px 20px -20px"
                  }}>
                    <form onSubmit={handleSubmit}>
                      <h1 style={{
                        textAlign: "center"
                      }}>Enter UPI Details</h1>
                      <div style={{
                        margin: "10px 0px"
                      }}>
                        <label style={{
                          color: "#fff"
                        }} > Withdrawal Id </label>
                        <Box
                          display="flex"
                          // backgroundColor={colors.primary[400]}
                          backgroundColor="#fff"
                          borderRadius="3px"
                          width="400px"
                          py="10px"
                        >

                          <InputBase sx={{ ml: 2, flex: 1, color: "black !important", fontSize: "16px" }} type="text" readOnly value={userId} className="" onChange={onPhoneChange} />
                        </Box>
                      </div>
                      <div style={{
                        margin: "10px 0px"
                      }} >
                        <label style={{
                          color: "#fff"
                        }} > Add UPI </label>
                        <Box
                          display="flex"
                          // backgroundColor={colors.primary[400]}
                          backgroundColor="#fff"
                          borderRadius="3px"
                          width="400px"
                          py="10px"
                        >
                          <InputBase sx={{ ml: 2, flex: 1, color: "black", fontSize: "16px" }} type="text" value={upi} className="form-control" onChange={onUpiChange} />
                        </Box>
                      </div>
                      <div style={{
                        margin: "10px 0px"
                      }}>
                        <label style={{
                          color: "#fff"
                        }} > UPI Type </label>
                        <Box
                          display="flex"
                          // backgroundColor={colors.primary[400]}
                          // backgroundColor="#fff"
                          borderRadius="3px"
                          width="400px"
                          py="10px"
                        >

                          <InputBase type="radio" checked={selectedOption === 'UPI_ID'} id="upi_type1" name="upi_type" value="UPI_ID" onChange={handleRadioChange} />
                          <label >UPI ID</label>


                          <InputBase style={{
                            marginLeft: "10px"
                          }} type="radio" checked={selectedOption === 'UPI_OBJ'} id="upi_type2" name="upi_type" value="UPI_OBJ" onChange={handleRadioChange} />
                          <label >UPI OBJ</label>

                        </Box>
                      </div>
                      <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        <Button disabled={upiValidated || !selectedOption} variant="contained" color="success" style={{
                          padding: "10px",
                          fontWeight: "700"
                        }} type="submit">Submit</Button>
                      </div>

                    </form>
                    <div style={{
                      marginTop: "20px"
                    }}>
                      <table>
                        <thead>
                          <tr>
                            <th>Key</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {response.map((result, index) => (
                            Object.keys(result).map((key) => (
                              <tr key={key}>
                                <th>{key}</th>
                                <td>
                                  {typeof result[key] === 'object' && result[key] !== null
                                    ? JSON.stringify(result[key], null, 2) // Convert nested objects to a readable format
                                    : result[key] !== null
                                      ? result[key].toString() // Convert value to string if it's not null
                                      : 'N/A'}
                                </td>
                              </tr>
                            ))
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleReRaiseWithdrawalFormclose} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};

export default EgoldWithdrawalLiveStatus;


