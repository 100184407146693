import { Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";

const PointsEarningHistory = () => {
  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { slug, participantId } = useParams();
  const [pointEarningHistory, setpointEarningHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);

  //Prepping to fetch the API

  const base_url = `https://proapi.getplus.in`;
  const access_token = getAccessToken();

  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchPointEarningHistory(slug, participantId, page);
  }, [slug, participantId, page]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchPointEarningHistory = async (participantId, pageNumber) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/contest/${slug}/contest-leaderboard?${participantId}&page=${pageNumber}`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setpointEarningHistory(data.results); //only the list of saving plans
      //   console.log(data); //all the data from the api
      setTotalRows(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      setPage(parseInt(pageNumber));
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
    pageNumbers.push(i);
  }

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "Participant ID",
      minWidth: 100,

    },
    {
      field: "name",
      headerName: "Participant Name",
      cellClassName: "name-column--cell",
      minWidth: 250,

    },
    {
      field: "ranking",
      headerName: "Ranking",
      minWidth: 50,
    },
    // {
    //   field: "plan_type",
    //   headerName: "Plan Type",
    //   minWidth: 50,
    // },
    {
      field: "points_earned",
      headerName: "Points Earned",
      minWidth: 100,
    },
    {
      field: "investor_id",
      headerName: "User ID",
      minWidth: 150,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
    },
    // {
    //   field: "point_earning_history",
    //   headerName: "Points History",

    //   renderCell: ({ ...params }) => (
    //     // <Link style={{textDecoration:"none"}} to={`/userSavingPlans/${params.row.id}/${params.row.phone_number}`}>
    //     <Link to={`/pointsEarningHistory/${params.row.id}`}>
    //       <Button variant="outlined" color="success">
    //         View History
    //       </Button>
    //     </Link>
    //   ),
    //   minWidth: 200,
    // },
  ];


  return (
    <>
      <Box m="20px">
        <Header
          // title={`Contest Name: ${slug}`}
          subtitle={``}
        />
        <Box m="40px 0 0 0" height="65vh">
          <Box height="75vh" width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none"
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal"
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important"
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important"
                },
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  overflow: "visible",
                  wordWrap: "break-word",
                },
              }}
              rowHeight={75}
              rows={pointEarningHistory}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              //   getRowId={(row) => row.plan_id}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
            />
          </Box>
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <button onClick={handlePreviousPage} disabled={!previousPage}>
              Previous Page
            </button>
            {pageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageClick(pageNumber)}
                className={`page-button ${pageNumber === page ? "active" : ""
                  }`}
              >
                {pageNumber}
              </button>
            ))}
            <button onClick={handleNextPage} disabled={!nextPage}>
              Next Page
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );


};

export default PointsEarningHistory;

