//WE CAN USE MUI DRAWER IF PRO SIDEBAR DOESNT GETS DEPRICIATED
import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Form, Link } from "react-router-dom";
import { tokens } from "../../theme";
import Dashboard from "../dashboard/Dashboard";
import 'react-pro-sidebar/dist/css/styles.css';

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined"; //users
import SipIcon from '@mui/icons-material/Sip'; //Sip
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'; //Sip
import UpcomingOutlinedIcon from '@mui/icons-material/UpcomingOutlined'; //Upcoming
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import { useLocation } from "react-router-dom";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
// import { useLocation } from 'react-router-dom';

// ...



const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <MenuItem active={selected === title} style={{ color: colors.grey[100] }} onClick={() => setSelected(title)} icon={icon}>
            <Typography>
                {title}
            </Typography>
            <Link to={to} />
        </MenuItem>
    )

}

const Sidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [selected, setSelected] = useState('Home');
    const location = useLocation();
    const currentPage = location.pathname;
    // const location = useLocation();
    // const isMenuItemActive = (path) => location.pathname === path;

    return (
        <Box
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important"
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important"
                },
                "& .pro-inner-item:hover": {
                    color: "#868dfb !important"
                },
                "& .pro-menu-item.active": {
                    color: "#6870fa !important"
                }
            }}
        >
            <div className="sidebar">
                <ProSidebar collapsed={isCollapsed}>
                    <Menu iconShape="square">
                        <MenuItem
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                            style={{
                                margin: "10px 0 20px 0",
                                color: colors.grey[100],
                            }}
                        >
                            {!isCollapsed && (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="15px"
                                >
                                    <Typography variant="h4" color={colors.grey[100]}>
                                        Customer Care
                                    </Typography>
                                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                        <MenuOutlinedIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </MenuItem>
                        {/* PLUS LOGO */}

                        {!isCollapsed && (
                            <Box mb="25px">
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <img
                                        alt='profile-user'
                                        width="100px"
                                        height="100px"
                                        src={`../../assets/plusLogo.jfif`}
                                        style={{ cursor: "pointer", borderRadius: "50%" }}
                                    />
                                </Box>

                                <Box textAlign="center">
                                    <Typography variant='h3' color={colors.grey[100]} fontWeight="bold" sx={{ m: "10px 0 0 0" }}>
                                        PLUS
                                    </Typography>
                                    {/* <Typography variant='h5' color={colors.greenAccent[500]}>
                            VP Fancy Admin
                        </Typography> */}
                                </Box>
                            </Box>
                        )}

                        {/* Menu Items */}
                        <Box paddingLeft={isCollapsed ? undefined : '10%'}>
                            {currentPage === '/dashboard' ? (
                                <Box>
                                    <Item
                                        title={"Home"}
                                        to={"/home"}
                                        icon={<HomeOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}

                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        P2P
                                    </Typography>
                                    <Item
                                        title={"Dashboard"}
                                        to={"/dashboard"}
                                        icon={<PeopleOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}

                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        Users
                                    </Typography>
                                    <Item
                                        title={"Total Users"}
                                        to={"/users"}
                                        icon={<GroupAddOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        SIPs & Deposits
                                    </Typography>
                                    <Item
                                        title={"Total SIP"}
                                        to={"/totalSipUsers"}
                                        icon={<SipIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title={"Upcoming Deposits"}
                                        to={"/upcomingdeposits"}

                                        icon={<PaidOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Item
                                        title={"Missed Deposits"}
                                        to={"/misseddeposits"}
                                        icon={<PaymentsOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        Orders
                                    </Typography>
                                    <Item
                                        title={"Gold Coin Orders"}
                                        to={"/goldcoinorders"}
                                        icon={<ListAltIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        KYC
                                    </Typography>
                                    <Item
                                        title={"KYC Status"}
                                        to={"/kycDetails"}
                                        icon={<CreditScoreOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        Agents
                                    </Typography>
                                    <Item
                                        title={"Agents"}
                                        to={"/agents"}
                                        icon={<HandshakeOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        Contests
                                    </Typography>
                                    <Item
                                        title={"Contests"}
                                        to={"/contestList"}
                                        icon={<EmojiEventsOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                    <Typography
                                        variant='h6'
                                        color={colors.grey[300]}
                                        sx={{ m: "15px 0 5px 20px" }}>
                                        Withdrawals
                                    </Typography>
                                    <Item
                                        title={"withdrawals"}
                                        to={"/withdrawal"}
                                        icon={<AccountBalanceWalletOutlinedIcon />}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </Box>)
                                : currentPage === '/home' ? (
                                    <Box>
                                        <Item
                                            title={"Home"}
                                            to={"/home"}
                                            icon={<HomeOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}


                                        />
                                        <Item
                                            title={"Dashboard"}
                                            to={"/dashboard"}
                                            icon={<PeopleOutlineIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title={"Gold Dashboard"}
                                            to={"/goldDashboard"}
                                            icon={<DiamondOutlinedIcon />}
                                            selected={selected}
                                            setSelected={setSelected}

                                        />
                                    </Box>
                                )
                                    : (
                                        <Box>
                                            <Item
                                                title={"Home"}
                                                to={"/home"}
                                                icon={<HomeOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Typography
                                                variant='h6'
                                                color={colors.grey[300]}
                                                sx={{ m: "15px 0 5px 20px" }}>
                                                E-GOLD
                                            </Typography>
                                            <Item
                                                title={"Gold Dashboard"}
                                                to={"/goldDashboard"}
                                                icon={<DiamondOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"Total Users"}
                                                to={"/totalegoldusers"}
                                                icon={<GroupAddOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"Saving Plans"}
                                                to={"/egoldplanlist"}
                                                icon={<FormatListBulletedOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"UPI Details"}
                                                to={"/egoldbankdetails"}
                                                icon={<AccountBalanceOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"Withdrawal List"}
                                                to={"/egoldwithdrawal"}
                                                icon={<AccountBalanceWalletOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"Orders"}
                                                to={"/augmontproductorderlist"}
                                                icon={<ListAltIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"Aug Transactions"}
                                                to={"/augmonttransactionlist"}
                                                icon={<ReceiptLongOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"User Transactions"}
                                                to={"/egoldusertransactionlist"}
                                                icon={<AutoStoriesOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                            <Item
                                                title={"Active Users"}
                                                to={"/goldAUMActivePlanUsers"}
                                                icon={<FunctionsOutlinedIcon />}
                                                selected={selected}
                                                setSelected={setSelected}
                                            />
                                        </Box>)}


                        </Box>
                    </Menu>

                </ProSidebar>
            </div>
        </Box>
    )
}

export default Sidebar;


