import { Box, Typography, IconButton, Button, Modal } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport style={{ background: "#fff !important", margin: "5px" }} />
    </GridToolbarContainer>
  );
}
//   const today = dayjs();

const ContestParticipants = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const { userId, name } = useParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [egoldPlanList, setEgoldPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dynamicHistoryPointColumns, setDynamicHistoryPointColumns] = useState([]);

  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchUPIJuspayDetailsList(userId, page, pageSize, searchInput);
  };

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //calling the fetched API
  useEffect(() => {
    fetchUPIJuspayDetailsList(userId, page, pageSize, searchInput, startDate, endDate);
  }, [userId, page, pageSize, searchInput, startDate, endDate]);

  //Fetching the API
  const fetchUPIJuspayDetailsList = async (userId, pageNumber, pageSize, searchQuery, startDate, endDate) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data based on the search input

    let apiUrl = `${base_url}/api/v1/ccapi/egold/v2/contest/${userId}/participants?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // Accumulate all unique keys from point_earning_history
      const allKeys = new Set();

      data.results.forEach((item) => {
        Object.keys(item.point_earning_history).forEach((key) => {
          allKeys.add(key);  // Add each key to the Set to ensure uniqueness
        });
      });

      // Convert the Set to an array
      const uniqueKeys = Array.from(allKeys);

      // Generate dynamic columns
      const dynamicColumns = uniqueKeys.map((key) => ({
        field: `point_earning_history_${key}`,
        headerName: key.replace(/_/g, ' ').toUpperCase(),
        renderCell: (params) => {
          const historyItem = params.row?.point_earning_history?.[key];
          return (
            <div>
              {historyItem ? (historyItem.is_completed ? 'Yes' : 'No') : 'N/A'}
              <div>{historyItem ? historyItem.points_earned : 'N/A'}</div>
            </div>
          );
        },
      }));

      // Set the dynamic columns state
      setDynamicHistoryPointColumns(dynamicColumns);

      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      setEgoldPlanList(data.results);

      // // Assuming the first item has the complete structure for dynamic keys
      // const dynamicKeys = Object.keys(data?.point_earning_history || {});

      // // Generate dynamic columns
      // const dynamicColumns = dynamicKeys.map(key => ({
      //   field: `point_earning_history_${key}`,
      //   headerName: `${key.replace(/_/g, ' ')}`,
      //   renderCell: (params) => {
      //     const historyItem = params.row?.point_earning_history?.[key];
      //     return (
      //       <div>
      //         {historyItem ? (historyItem.is_completed ? 'Yes' : 'No') : 'N/A'}
      //         <div>{historyItem ? historyItem.points_earned : 'N/A'}</div>
      //       </div>
      //     );
      //   },
      // }));


      // console.log(data.results.point_earning_history)

      // const resultsArray = Array.isArray(data) ? data : [data];
      // setEgoldPlanList(resultsArray);
      // console.log(resultsArray);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // const dynamicColumns = Object.keys(dynamicHistoryPointColumns).map((key) => ({
  //   field: `point_earning_history_${key}`,
  //   headerName: `${key.replace(/_/g, ' ')} (${key})`,
  //   renderCell: (params) => {
  //     const historyItem = params.row?.point_earning_history?.[key];
  //     return (
  //       <div>
  //         {historyItem ? (historyItem.is_completed ? 'Yes' : 'No') : 'N/A'}
  //         <div>{historyItem ? historyItem.points_earned : 'N/A'}</div>
  //       </div>
  //     );
  //   },
  // }));

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchUPIJuspayDetailsList(userId, page, pageSize, searchInput, startDate, endDate);
  };

  //columns needed in the table
  const basecolumns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      maxWidth: 50,
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "id",
    },
    {
      field: "name",
      headerName: "Name"
    },
    {
      field: "phone_number",
      headerName: "Phone Number"
    },
    {
      field: "email",
      headerName: "Email"
    },
    {
      field: "ranking",
      headerName: "Ranking",
    },
    {
      field: "points_earned",
      headerName: "Points Earned",
      width: 100,
      valueGetter: (params) => params.row?.points_earned ?? "N/A",
    },
    {
      field: "created_at",
      headerName: "Created",
      renderCell: (params) => {
        const date = new Date(params.row.created_at);
        return date.toLocaleDateString();
      },
    },
    // {
    //   field: "point_earning_history_referal",
    //   headerName: `Referal Completed (${name})`,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row?.point_earning_history?.referral ? (params.row?.point_earning_history?.referral?.is_completed ? "Yes" : "No") : "N/A"}
    //         <div>
    //           {params.row?.point_earning_history?.referral ? (params.row?.point_earning_history?.referral?.points_earned ?? "N/A") : "N/A"}
    //         </div>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   field: "point_earning_history_saving_plan",
    //   headerName: `Saving Plan (${name})`,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row?.point_earning_history?.saving_plan ? (params.row?.point_earning_history?.saving_plan?.is_completed ? "Yes" : "No") : "N/A"}
    //         <div>
    //           {params.row?.point_earning_history?.saving_plan ? (params.row?.point_earning_history?.saving_plan?.points_earned ?? "N/A") : "N/A"}
    //         </div>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   field: "point_earning_history_verified_kyc",
    //   headerName: `Verified Kyc (${name})`,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row?.point_earning_history?.verified_kyc ? (params.row?.point_earning_history?.verified_kyc?.is_completed ? "Yes" : "No") : "N/A"}
    //         <div>
    //           {params.row?.point_earning_history?.verified_kyc ? (params.row?.point_earning_history?.verified_kyc?.points_earned ?? "N/A") : "N/A"}
    //         </div>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   field: "point_earning_history_instagram_follow",
    //   headerName: `Instagram Follow (${name})`,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row?.point_earning_history?.instagram_follow ? (params.row?.point_earning_history?.instagram_follow?.is_completed ? "Yes" : "No") : "N/A"}
    //         <div>
    //           {params.row?.point_earning_history?.instagram_follow ? (params.row?.point_earning_history?.instagram_follow?.points_earned ?? "N/A") : "N/A"}
    //         </div>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   field: "point_earning_history_app_review",
    //   headerName: `App Review (${name})`,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row?.point_earning_history?.app_review ? (params.row?.point_earning_history?.app_review?.is_completed ? "Yes" : "No") : "N/A"}
    //         <div>
    //           {params.row?.point_earning_history?.app_review ? (params.row?.point_earning_history?.app_review?.points_earned ?? "N/A") : "N/A"}
    //         </div>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   field: "point_earning_history_egold_plan",
    //   headerName: `Egold Plan (${name})`,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row?.point_earning_history?.egold_plan ? (params.row?.point_earning_history?.egold_plan?.is_completed ? "Yes" : "No") : "N/A"}
    //         <div>
    //           {params.row?.point_earning_history?.egold_plan ? (params.row?.point_earning_history?.egold_plan?.points_earned ?? "N/A") : "N/A"}
    //         </div>
    //       </div>
    //     )
    //   }
    // },
    // {
    //   field: "point_earning_history_sip_plan",
    //   headerName: `SIP Plan (${name})`,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row?.point_earning_history?.sip_plan ? (params.row?.point_earning_history?.sip_plan?.is_completed ? "Yes" : "No") : "N/A"}
    //         <div>
    //           {params.row?.point_earning_history?.sip_plan ? (params.row?.point_earning_history?.sip_plan?.points_earned ?? "N/A") : "N/A"}
    //         </div>
    //       </div>
    //     )
    //   }
    // },




    //     "referral": {
    //       "is_completed": false,
    //       "points_earned": 0
    //   },
    //   "app_review": {
    //       "is_completed": true,
    //       "points_earned": 50
    //   },
    //   "egold_plan": {
    //       "is_completed": true,
    //       "points_earned": 10000
    //   },
    //   "saving_plan": {
    //       "is_completed": false,
    //       "points_earned": 0
    //   },
    //   "verified_kyc": {
    //       "is_completed": true,
    //       "points_earned": 150
    //   }
    // }

    // {
    //   field: "api_response.client_id",
    //   headerName: "Client Id",
    //   width: 200,
    //   valueGetter: (params) => params.row?.api_response?.data?.client_id ?? "N/A",
    // },
    // {
    //   field: "Full Name",
    //   headerName: "Full Name",
    //   maxWidth: 100,
    //   valueGetter: (params) => params.row.api_response?.data?.full_name ?? "N/A"
    // },
    // {
    //   field: "ifsc_details",
    //   headerName: "IFSC Details",
    //   maxWidth: 150,
    //   valueGetter: (params) => params.row.api_response?.data?.ifsc_details ?? "N/A"
    // },
    // {
    //   field: "account_exists",
    //   headerName: "Account Exists",
    //   maxWidth: 100,
    //   valueGetter: (params) => params.row.api_response?.data?.account_exists ? "Yes" : "No"
    // },
    // {
    //   field: "status_code",
    //   headerName: "Status Code",
    //   maxWidth: 100,
    //   valueGetter: (params) => params.row.api_response?.status_code ?? "N/A"
    // },

  ];

  const columns = [
    ...basecolumns,
    ...dynamicHistoryPointColumns
  ]

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title={`${name} Contest Participants`}
            subtitle={`Total Count: ${totalRows} `}
          />

        </Box>
        {/* SEARCH BAR */}
        {/* <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search user_number / user_name / email_id / unique_id / transaction_id / merchant_txn_id"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box> */}
        {/* DataTable */}

        <Box m="30px 0 0 0" height="75vh">
          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>


            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size&nbsp;</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>

          </Box>


          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                E-Gold Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/contests"

              >
                Contest List
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"

              >
                {name} contest participants
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                background: `#fff !important`,
                margin: "5px"
              },
              marginTop: "5px",
            }}
          >
            <DataGrid
              rowHeight={60}
              getRowId={(row) => row.id}
              rows={egoldPlanList}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== 'Sl_No' && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseModal} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Pagination Buttons */}
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContestParticipants;


