import * as React from "react";
import { useState, useEffect } from "react";
import {Box} from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {Link as RouterLink } from "react-router-dom";
import Header from "../../components/Header";
// Use These if needed in future
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { getAccessToken } from "../../utils/authUtils";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import authApi from "../../api";

const Home = () =>{

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [p2pDashboard, setP2pDashboard] = useState([]);
    const [augmontGoldDashboard, setAugmontGoldDashboard] = useState([]);

    return (
        <>
         <Box margin="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="Home" subtitle="" />
        </Box>

        <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" component={RouterLink} to={"#"}>
          Home
        </Link>
      </Breadcrumbs>
    </div>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/dashboard"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {/* Total count of Users Displayed here */}
                    <h2 style={{ textAlign: "center" }}>P2P Data</h2>
                    <h3 style={{ textAlign: "center" }}></h3>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Button variant="outlined" color="warning">View P2P Data</Button>
                    </div>
                  </div>
                  
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/goldDashboard"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>Gold (Augmont) Data</h2>
                    <h3 style={{ textAlign: "center" }}></h3>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Button variant="outlined" color="warning">View Gold Data</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
        </>
    );
}

export  default Home;