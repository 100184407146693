import { Box, Typography, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const UserWithdrawalDetails = () => {

  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { userId, phoneNumber, name } = useParams();
  const [userWithdrawalDetails, setuserWithdrawalDetails] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Prepping to fetch the API

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchUserSavingsPlan(userId, page, searchInput);
  };

  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchUserSavingsPlan(userId);
  }, [userId]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchUserSavingsPlan = async (userId) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/withdrawal/${userId}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const dataArray = Array.isArray(data) ? data : [data];
      setuserWithdrawalDetails(dataArray); //only the list of saving plans
      //   console.log(dataArray); //all the data from the api
      //   setTotalRows(data.count);
      //   setNextPage(data.next);
      //   setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      setPage(parseInt(pageNumber));
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
    pageNumbers.push(i);
  }

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "ID",
      minWidth: 20,
    },
    // {
    //   field: "investor_id",
    //   headerName: "User ID",
    //   cellClassName: "name-column--cell",
    //   minWidth: 250,
    // },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      minWidth: 100,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 150,
    },
    {
      field: "saving_plan_name",
      headerName: "Plan Name",
      minWidth: 150,
    },
    {
      field: "tx_id",
      headerName: "Tx ID",
      minWidth: 250,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 150,
    },
    {
      field: "utr",
      headerName: "UTR",
      minWidth: 100,
    },
    {
      field: "is_withdrawal_processed",
      headerName: "Withdrawal Processed",
      minWidth: 100,
    },
    {
      field: "withdrawal_processed_date",
      headerName: "Withdrawal Processed Date",
      renderCell: (params) => {
        const timestamp = params.row.withdrawal_processed_date;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 100,
    },
    {
      field: "is_withdrawal_done",
      headerName: "Withdrawal Completed",
      minWidth: 100,
    },
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title={`User Name: ${name}`}
            subtitle={`Phone Number: ${phoneNumber}`}
          />
        </Box>
        {/* SEARCH BAR */}
        {/* <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box> */}
        {/* DataTable */}

        <Box m="40px 0 0 0" height="75vh">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/dashboard"
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                User List
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={userWithdrawalDetails}
              columns={columns}
              loading={loading}
              //   pagination
              //   page={page}
              //   onPageChange={handlePageChange}
              //   pageSize={pageSize}
              //   rowCount={totalRows}
              hideFooterPagination={true}
              onCellClick={handleRowClick}

            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <div>
                  {columns.map(column => (
                    <div key={column.field}>
                      {column.headerName}: {selectedRow[column.field]}
                    </div>
                  ))}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Pagination Buttons */}
          {/* <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box> */}
        </Box>
      </Box>
    </>
  )
};

export default UserWithdrawalDetails;
