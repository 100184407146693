import { Box, Typography, IconButton, Button, Modal } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../../api";
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport style={{ background: "#fff !important", margin: "5px" }} />
    </GridToolbarContainer>
  );
}
//   const today = dayjs();

const EgoldAugmontProductOrder = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [egoldPlanList, setEgoldPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  // paid filter

  const [allPaidStatus, setAllPaidStatus] = useState(true);
  const [paidTrue, setPaidTrue] = useState(false);
  const [paidFalse, setPaidFalse] = useState(false);
  const [selectedOptionForPaid, setSelectedOptionForPaid] = useState('allPaidStatus');

  // devliery filter

  const [allDeliveredStatus, setAllDeliveredStatus] = useState(true);
  const [deliveredTrue, setDeliveredTrue] = useState(false);
  const [deliveredFalse, setDeliveredFalse] = useState(false);
  const [selectedOptionForDelivered, setSelectedOptionForDelivered] = useState('allDeliveredStatus');

  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchAugmontOrderListData(page, pageSize, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchAugmontOrderListData(page, pageSize, searchInput);
    }
  }

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //calling the fetched API
  useEffect(() => {
    fetchAugmontOrderListData(page, pageSize, searchInput, startDate, endDate, allPaidStatus, paidTrue, paidFalse, allDeliveredStatus, deliveredTrue, deliveredFalse);
  }, [page, pageSize, searchInput, startDate, endDate, allPaidStatus, paidTrue, paidFalse, allDeliveredStatus, deliveredTrue, deliveredFalse]);

  //Fetching the API
  const fetchAugmontOrderListData = async (pageNumber, pageSize, searchQuery, startDate, endDate, allPaidStatus, paidTrue, paidFalse, allDeliveredStatus, deliveredTrue, deliveredFalse) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data based on the search input

    let apiUrl = `${base_url}/api/v1/ccapi/egold/augmont-product-orders?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }

    if (allPaidStatus) {
    }
    else if (paidTrue) {
      apiUrl += '&is_paid=True';
    } else if (paidFalse) {
      apiUrl += '&is_paid=False';
    }

    if (allDeliveredStatus) {
    }
    else if (deliveredTrue) {
      apiUrl += '&is_delivered=True';
    } else if (deliveredFalse) {
      apiUrl += '&is_delivered=False';
    }


    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      //this stores all the data of the users we need and displays it in the table
      setEgoldPlanList(data.results);
      //   console.log(data);
      //   console.log(data.results);


    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchAugmontOrderListData(page, pageSize, searchInput, startDate, endDate, allPaidStatus, paidTrue, paidFalse, allDeliveredStatus, deliveredTrue, deliveredFalse);
  };

  // filter for is_paid true, false

  const handleSelectChangeForAllPaidStatus = () => {
    setAllPaidStatus(true);
    setPaidTrue(false);
    setPaidFalse(false);
  };

  const handleSelectChangeForPaidTrue = () => {
    setAllPaidStatus(false);
    setPaidTrue(true);
    setPaidFalse(false);
  };

  const handleSelectChangeForPaidFalse = () => {
    setAllPaidStatus(false);
    setPaidTrue(false);
    setPaidFalse(true);
  };

  const handleSelectChangePaidStatus = (event) => {
    setSelectedOptionForPaid(event.target.value);

    switch (event.target.value) {
      case 'allPaidStatus':
        handleSelectChangeForAllPaidStatus();
        break;
      case 'paidTrue':
        handleSelectChangeForPaidTrue();
        break;
      case 'paidFalse':
        handleSelectChangeForPaidFalse();
        break;
      default:
        break;
    }
  };

  const clearPaidStatusFilters = () => {
    setSelectedOptionForPaid('allPaidStatus');
    setAllPaidStatus(true);
    setPaidTrue(false);
    setPaidFalse(false);
  };
  //   delivery filter

  const handleSelectChangeForAllDeliveredStatus = () => {
    setAllDeliveredStatus(true);
    setDeliveredTrue(false);
    setDeliveredFalse(false);
  };

  const handleSelectChangeForDeliveredTrue = () => {
    setAllDeliveredStatus(false);
    setDeliveredTrue(true);
    setDeliveredFalse(false);
  };

  const handleSelectChangeForDeliveredFalse = () => {
    setAllDeliveredStatus(false);
    setDeliveredTrue(false);
    setDeliveredFalse(true);
  };

  const handleSelectChangeDeliveredStatus = (event) => {
    setSelectedOptionForDelivered(event.target.value);

    switch (event.target.value) {
      case 'allDeliveredStatus':
        handleSelectChangeForAllDeliveredStatus();
        break;
      case 'deliveredTrue':
        handleSelectChangeForDeliveredTrue();
        break;
      case 'deliveredFalse':
        handleSelectChangeForDeliveredFalse();
        break;
      default:
        break;
    }
  };

  const clearDeliveredStatusFilters = () => {
    setSelectedOptionForDelivered('allDeliveredStatus');
    setAllDeliveredStatus(true);
    setDeliveredTrue(false);
    setDeliveredFalse(false);
  };
  //columns needed in the table
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "augmont_user.user_name",
      headerName: "User Name",
      valueGetter: (params) => params.row.augmont_user.user_name,
    },
    {
      field: "augmont_user.phone_number",
      headerName: "Phone Number",
      valueGetter: (params) => params.row.augmont_user.phone_number,
    },
    {
      field: "augmont_user.email",
      headerName: "Email",
      valueGetter: (params) => params.row.augmont_user.email,
    },
    {
      field: "address.full_name",
      headerName: "Full Name",
      valueGetter: (params) => params.row.address.full_name,
    },
    {
      field: "address.email",
      headerName: "Address Email",
      valueGetter: (params) => params.row.address.email,
    },
    {
      field: "address.mobile_number",
      headerName: "Mobile Number",
      valueGetter: (params) => params.row.address.mobile_number,
    },
    {
      field: "address.address",
      headerName: "Address",
      valueGetter: (params) => params.row.address.address,
    },
    {
      field: "address.city",
      headerName: "City",
      valueGetter: (params) => params.row.address.city,
    },
    {
      field: "address.state",
      headerName: "State",
      valueGetter: (params) => params.row.address.state,
    },
    {
      field: "address.pincode",
      headerName: "Pincode",
      valueGetter: (params) => params.row.address.pincode,
    },
    {
      field: "created_at",
      headerName: "Created At",
      renderCell: (params) => {
        const date = new Date(params.row.created_at);
        return date.toLocaleDateString();
      },
    },
    {
      field: "product_name",
      headerName: "Product Name",
    },
    {
      field: "product_sku",
      headerName: "Product SKU",
    },
    {
      field: "product_purity",
      headerName: "Product Purity",
    },
    {
      field: "product_weight",
      headerName: "Product Weight",
    },
    {
      field: "purchased_gold_quantity",
      headerName: "Purchased Gold Quantity",
    },
    {
      field: "amount_paid",
      headerName: "Amount Paid",
    },
    {
      field: "delivery_making_charges",
      headerName: "Delivery Making Charges",
    },
    {
      field: "order_status",
      headerName: "Order Status",
    },
    {
      field: "lock_price",
      headerName: "Lock Price",
    },
    {
      field: "merchant_transaction_id",
      headerName: "Merchant Transaction ID",
    },
    {
      field: "is_paid",
      headerName: "Is Paid",
      valueGetter: (params) => params.row.is_paid ? "Yes" : "No",
    },
    {
      field: "is_delivered",
      headerName: "Is Delivered",
      valueGetter: (params) => params.row.is_delivered ? "Yes" : "No",
    },
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title="Augmont Product Order List"
            subtitle={`Total Number of orders: ${data.count}`}
          />
        </Box>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search mobile_number / user_name / user_email / unique_id / merchant_transaction_id / order_status"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        {/* DataTable */}

        <Box m="30px 0 0 0" height="75vh">
          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>



            <Box>
              <Select
                value={selectedOptionForPaid}
                onChange={handleSelectChangePaidStatus}
              >
                <MenuItem value="allPaidStatus">Paid Status</MenuItem>
                <MenuItem value="paidTrue">Paid</MenuItem>
                <MenuItem value="paidFalse">Not Paid</MenuItem>
              </Select>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={"2px"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearPaidStatusFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selectedOptionForDelivered}
                onChange={handleSelectChangeDeliveredStatus}
              >
                <MenuItem value="allDeliveredStatus">Delivery Status</MenuItem>
                <MenuItem value="deliveredTrue">Delivered</MenuItem>
                <MenuItem value="deliveredFalse">Not Delivered</MenuItem>
              </Select>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} m={"2px"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearDeliveredStatusFilters}>X</Button>
              </Box>
            </Box>

            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>

          </Box>


          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                E-Gold Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"

              >
                Augmont Product Order List
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                background: `#fff !important`,
                margin: "5px"
              },
              marginTop: "5px",
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={egoldPlanList}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== 'Sl_No' && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseModal} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Pagination Buttons */}
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EgoldAugmontProductOrder;