import { Box, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const TotalSipUsers = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25); // You can set your desired page size
  const [totalRows, setTotalRows] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [sipUsersList, setSipUsersList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchSipUserList(page, pageSize, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchSipUserList(page, pageSize, searchInput);
    }
  }

  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };

  useEffect(() => {
    fetchSipUserList(page, pageSize, searchInput, startDate, endDate);
  }, [page, pageSize, searchInput, startDate, endDate]);

  const fetchSipUserList = async (pageNumber, pageSize, searchQuery, startDate, endDate) => {
    setLoading(true);
    let apiUrl = `${base_url}/api/v1/ccapi/investor-invesment-list?&is_invested=True&is_active_investor=True&page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;
    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }
    // is_active_investor=False
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/')
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setData(data);
      setTotalRows(data.count);
      setNextPage(data.next);
      // console.log(data.previous)
      setPreviousPage(data.previous);
      setSipUsersList(data.results);
      setFilteredData(data.results);
      // console.log(data.results);
    } catch (error) {
      console.log("Error Fetching Data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };


  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    // setActivePage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
    // setActivePage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchSipUserList(page, pageSize, searchInput, startDate, endDate);
  };
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "User ID",

    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,

    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      minWidth: 100,

    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,

    },
    {
      field: "is_kyc_verified",
      headerName: "Kyc Status",
      minWidth: 100,
    },
    {
      field: "total_amount_available",
      headerName: "Savings",
      minWidth: 100,

    },
    {
      field: "total_interest_earned",
      headerName: "Earned Interest",
      minWidth: 100,
    },
    {
      field: "saving_plan_count",
      headerName: "Plan Count",
      minWidth: 100,
    },
    {
      field: "View Savings Plans",
      headerName: "Saving Plans",
      renderCell: ({ ...params }) => (
        <RouterLink
          style={{ textDecoration: "none" }}
          to={`/activeSavingPlan/${params.row.id}/${params.row.phone_number}/${params.row.name ? params.row.name : "No Name"}`}
        >
          {/* {console.log(params.row.name)} */}
          <Button variant="outlined" color="success">
            View Invested Plans
          </Button>
        </RouterLink>
      ),
      minWidth: 200,
    },
  ];
  return (
    <>
      <Box m="20px">
        <Box>
          <Header
            title="SIP Investor List"
            subtitle={`Total Number of Investors: ${data.count}`}
          />
        </Box>
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Box>
          <RouterLink style={{ textDecoration: "none" }} to={`/totalSipUsers`}>
            <Button sx={{ margin: "5px" }} variant="outlined" color="success">
              All SIP Investors
            </Button>
          </RouterLink>
          <RouterLink style={{ textDecoration: "none" }} to={`/onetimesipusers`}>
            <Button sx={{ margin: "5px" }} variant="outlined" color="success">
              One Time SIP Investors
            </Button>
          </RouterLink >
          <RouterLink style={{ textDecoration: "none" }} to={`/monthlysipusers`}>
            <Button sx={{ margin: "5px" }} variant="outlined" color="success">
              Monthly SIP Investors
            </Button>
          </RouterLink>
        </Box>


        <Box m="40px 0 0 0"  >
          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>
            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>
          </Box>

          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="#">
                Sip User List
              </Link>
              {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
            </Breadcrumbs>
          </div>
          <Box height="75vh" width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none"
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rowHeight={65}
              rows={filteredData}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}

            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <div>
                  <div>User ID: {selectedRow.id}</div>
                  <div>Name: {selectedRow.name}</div>
                  <div>Email ID: {selectedRow.email}</div>
                  <div>Phone Number: {selectedRow.phone_number}</div>
                  <div>KYC Verified: {selectedRow.is_kyc_verified}</div>
                  <div>Savings: {selectedRow.total_amount_available}</div>
                  <div>Earned Interest: {selectedRow.total_interest_earned}</div>
                  <div>Plan Count: {selectedRow.saving_plan_count}</div>
                  <div>Created Date: {selectedRow.created_at}</div>
                  {/* Add other fields from the selectedRow */}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}


          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TotalSipUsers;