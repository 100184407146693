import { Box, Typography, IconButton, Button, Modal } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../../api";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        style={{ background: "#fff !important", margin: "5px" }}
      />
    </GridToolbarContainer>
  );
}

const EgoldUserTransactions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [egoldUserTransactions, setegoldUserTransactions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // filter for payment type
  const [selectedOptionForPayment, setSelectedOptionForPayment] =
    useState("All Payment Type");
  const [isEGold, setIsEGold] = useState(false);
  const [isGoldCoin, setIsGoldCoin] = useState(false);
  const [isSavingsPlan, setIsSavingsPlan] = useState(false);
  const [isAllPaymentType, setIsAllPaymentType] = useState(true);

  // filter for transaction type

  const [selectedOptionForTransaction, setSelectedOptionForTransaction] =
    useState("ALL");
  const [isPayment, setIsPayment] = useState(false);
  const [isBuy, setIsBuy] = useState(false);
  const [isSell, setIsSell] = useState(false);
  const [isLease, setIsLease] = useState(false);
  const [isLeaseBroken, setIsLeaseBroken] = useState(false);
  const [isCashWithdrawal, setIsCashWithdrawal] = useState(false);
  const [isGoldCoinWithdrawal, setIsGoldCoinWithdrawal] = useState(false);
  const [isInterest, setIsInterest] = useState(false);
  const [isPlusBenefit, setIsPlusBenefit] = useState(false);
  const [isJewellerRedemption, setIsJewellerRedemption] = useState(false);
  const [isAll, setIsAll] = useState(true);

  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchAugmontOrderListData(page, pageSize, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchAugmontOrderListData(page, pageSize, searchInput);
    }
  }

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //calling the fetched API
  useEffect(() => {
    fetchAugmontOrderListData(
      page,
      pageSize,
      searchInput,
      startDate,
      endDate,
      isEGold,
      isGoldCoin,
      isSavingsPlan,
      isAllPaymentType,
      isPayment,
      isBuy,
      isSell,
      isLease,
      isLeaseBroken,
      isCashWithdrawal,
      isGoldCoinWithdrawal,
      isInterest,
      isPlusBenefit,
      isJewellerRedemption,
      isAll
    );
  }, [
    page,
    pageSize,
    searchInput,
    startDate,
    endDate,
    isEGold,
    isGoldCoin,
    isSavingsPlan,
    isAllPaymentType,
    isPayment,
    isBuy,
    isSell,
    isLease,
    isLeaseBroken,
    isCashWithdrawal,
    isGoldCoinWithdrawal,
    isInterest,
    isPlusBenefit,
    isJewellerRedemption,
    isAll,
  ]);

  //Fetching the API
  const fetchAugmontOrderListData = async (
    pageNumber,
    pageSize,
    searchQuery,
    startDate,
    endDate,
    isEGold,
    isGoldCoin,
    isSavingsPlan,
    isAllPaymentType,
    isPayment,
    isBuy,
    isSell,
    isLease,
    isLeaseBroken,
    isCashWithdrawal,
    isGoldCoinWithdrawal,
    isInterest,
    isPlusBenefit,
    isJewellerRedemption,
    isAll
  ) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data based on the search input

    let apiUrl = `${base_url}/api/v1/ccapi/egold/user-transaction?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format(
        "YYYY-MM-DD"
      )}&end_date=${endDate.format("YYYY-MM-DD")}`;
    }

    if (isAllPaymentType) {
    } else if (isEGold) {
      apiUrl += "&payment_for=EGOLD";
    } else if (isGoldCoin) {
      apiUrl += "&payment_for=GOLD COIN";
    } else if (isSavingsPlan) {
      apiUrl += "&payment_for=SAVINGS PLAN";
    }

    if (isAll) {
    } else if (isPayment) {
      apiUrl += "&transaction_type=PAYMENT";
    } else if (isBuy) {
      apiUrl += "&transaction_type=BUY";
    } else if (isSell) {
      apiUrl += "&transaction_type=SELL";
    } else if (isLease) {
      apiUrl += "&transaction_type=LEASE";
    } else if (isLeaseBroken) {
      apiUrl += "&transaction_type=LEASE BROKEN";
    } else if (isCashWithdrawal) {
      apiUrl += "&transaction_type=CASH WITHDRAWAL";
    } else if (isGoldCoinWithdrawal) {
      apiUrl += "&transaction_type=GOLD COIN WITHDRAWAL";
    } else if (isInterest) {
      apiUrl += "&transaction_type=INTEREST";
    } else if (isPlusBenefit) {
      apiUrl += "&transaction_type=PLUS BENEFIT";
    } else if (isJewellerRedemption) {
      apiUrl += "&transaction_type=JEWELLER REDEMPTION";
    }

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate("/");
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      //this stores all the data of the users we need and displays it in the table
      setegoldUserTransactions(data.results);
      //   console.log(data);
      //   console.log(data.results);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchAugmontOrderListData(
      page,
      pageSize,
      searchInput,
      startDate,
      endDate,
      isEGold,
      isGoldCoin,
      isSavingsPlan,
      isAllPaymentType,
      isPayment,
      isBuy,
      isSell,
      isLease,
      isLeaseBroken,
      isCashWithdrawal,
      isGoldCoinWithdrawal,
      isInterest,
      isPlusBenefit,
      isJewellerRedemption,
      isAll
    );
  };

  // filter for transaction type

  //   payment_for filter

  // Handlers
  const handleSelectChangeForEGold = () => {
    setIsEGold(true);
    setIsGoldCoin(false);
    setIsSavingsPlan(false);
    setIsAllPaymentType(false);
  };

  const handleSelectChangeForGoldCoin = () => {
    setIsEGold(false);
    setIsGoldCoin(true);
    setIsSavingsPlan(false);
    setIsAllPaymentType(false);
  };

  const handleSelectChangeForSavingsPlan = () => {
    setIsEGold(false);
    setIsGoldCoin(false);
    setIsSavingsPlan(true);
    setIsAllPaymentType(false);
  };

  const handleSelectChangeForAllPaymentType = () => {
    setIsEGold(false);
    setIsGoldCoin(false);
    setIsSavingsPlan(false);
    setIsAllPaymentType(true);
  };

  const handleSelectChangePaymentType = (event) => {
    setSelectedOptionForPayment(event.target.value);

    switch (event.target.value) {
      case "All Payment Type":
        handleSelectChangeForAllPaymentType();
        break;
      case "EGOLD":
        handleSelectChangeForEGold();
        break;
      case "GOLD COIN":
        handleSelectChangeForGoldCoin();
        break;
      case "SAVINGS PLAN":
        handleSelectChangeForSavingsPlan();
        break;
      default:
        break;
    }
  };

  const clearPaymentTypeFilters = () => {
    setSelectedOptionForPayment("All Payment Type");
    setIsEGold(false);
    setIsGoldCoin(false);
    setIsSavingsPlan(false);
    setIsAllPaymentType(true);
  };

  // Handlers
  const handleSelectChangeForPayment = () => {
    setIsPayment(true);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForAll = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(true);
  };

  const handleSelectChangeForBuy = () => {
    setIsPayment(false);
    setIsBuy(true);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForSell = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(true);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForLease = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(true);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForLeaseBroken = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(true);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForCashWithdrawal = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(true);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForGoldCoinWithdrawal = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(true);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForInterest = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(true);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForPlusBenefit = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);

    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(true);

    setIsJewellerRedemption(false);
    setIsAll(false);
  };

  const handleSelectChangeForJewellerRedemption = () => {
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);

    setIsPlusBenefit(false);
    setIsJewellerRedemption(true);
    setIsAll(false);
  };

  const handleSelectChangeTransactionType = (event) => {
    setSelectedOptionForTransaction(event.target.value);

    switch (event.target.value) {
      case "ALL":
        handleSelectChangeForAll();
        break;
      case "PAYMENT":
        handleSelectChangeForPayment();
        break;
      case "BUY":
        handleSelectChangeForBuy();
        break;
      case "SELL":
        handleSelectChangeForSell();
        break;
      case "LEASE":
        handleSelectChangeForLease();
        break;
      case "LEASE_BROKEN":
        handleSelectChangeForLeaseBroken();
        break;
      case "CASH_WITHDRAWAL":
        handleSelectChangeForCashWithdrawal();
        break;
      case "GOLD_COIN_WITHDRAWAL":
        handleSelectChangeForGoldCoinWithdrawal();
        break;
      case "INTEREST":
        handleSelectChangeForInterest();
        break;
      case "PLUS_BENEFIT":
        handleSelectChangeForPlusBenefit();
        break;
      case "JEWELLER_REDEMPTION":
        handleSelectChangeForJewellerRedemption();
        break;
      default:
        break;
    }
  };

  const clearTransactionTypeFilters = () => {
    setSelectedOptionForTransaction("ALL");
    setIsPayment(false);
    setIsBuy(false);
    setIsSell(false);
    setIsLease(false);
    setIsLeaseBroken(false);
    setIsCashWithdrawal(false);
    setIsGoldCoinWithdrawal(false);
    setIsInterest(false);
    setIsPlusBenefit(false);
    setIsJewellerRedemption(false);
    setIsAll(true);
  };

  //columns needed in the table
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: "id",
      headerName: "ID",
    },
    {
      field: "payment_for",
      headerName: "Payment For",
      valueGetter: (params) => params.row.payment_for || "N/A",
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      valueGetter: (params) => params.row.transaction_type || "N/A",
    },
    {
      field: "e_gold_payment",
      headerName: "E Gold Payment",
      renderCell: (params) => {
        const eGoldPayment = params.row.e_gold_payment;
        if (eGoldPayment === null) {
          return <div>No E Gold Payment</div>;
        }

        return (
          <div>
            <div>{`ID: ${eGoldPayment.id}`}</div>
            <div>{`Date of Payment: ${eGoldPayment.date_of_payment || "N/A"
              }`}</div>
            <div>{`Metal Quantity: ${eGoldPayment.metal_quantity}`}</div>
            <div>{`Amount: ${eGoldPayment.amount}`}</div>
            <div>{`Order ID: ${eGoldPayment.order_id}`}</div>
            <div>{`Payment ID: ${eGoldPayment.payment_id || "N/A"}`}</div>
            <div>{`Payment For: ${eGoldPayment.payment_for}`}</div>
            <div>{`Subscription Type: ${eGoldPayment.subscription_type}`}</div>
            <div>{`Lock Price: ${eGoldPayment.lock_price}`}</div>
            <div>{`Scheme: ${eGoldPayment.scheme}`}</div>
          </div>
        );
      },
    },
    {
      field: "coin_order",
      headerName: "Coin Order",
      renderCell: (params) => {
        const coinOrder = params.row.coin_order;
        if (coinOrder === null) {
          return <div>No Coin Order</div>;
        }

        return (
          <div>
            <div>{`ID: ${coinOrder.id}`}</div>
            <div>{`Cart ID: ${coinOrder.cart_id}`}</div>
            <div>{`Order ID: ${coinOrder.order_id}`}</div>
            <div>{`Amount: ${coinOrder.amount}`}</div>
            <div>{`Product Name: ${coinOrder.product_name}`}</div>
            <div>{`Purity: ${coinOrder.purity}`}</div>
            <div>{`Coin Weight: ${coinOrder.coin_weight}`}</div>
            <div>{`Jeweller Store ID: ${coinOrder.jeweller_store_id}`}</div>
            <div>{`Jeweller Store Name: ${coinOrder.jeweller_store_name}`}</div>
            <div>{`Jeweller Address: ${coinOrder.jeweller_address}`}</div>
            <div>{`Jeweller Logo: ${coinOrder.jeweller_logo}`}</div>
            <div>{`Pickup Date: ${coinOrder.pickup_date}`}</div>
            <div>{`Ready To Pickup Date: ${coinOrder.ready_to_pickup_date}`}</div>
            <div>{`Is Ready To Pickup: ${coinOrder.is_ready_to_pickup}`}</div>
            <div>{`Is Picked Up: ${coinOrder.is_picked_up}`}</div>
            <div>{`Order Status: ${coinOrder.order_status}`}</div>
            <div>{`Is Paid: ${coinOrder.is_paid}`}</div>
            <div>{`Created At: ${coinOrder.created_at}`}</div>
          </div>
        );
      },
    },
    {
      field: "augmont_product_order",
      headerName: "Augmont Product Order",
      renderCell: (params) => {
        const augmontProductOrder = params.row.augmont_product_order;
        if (augmontProductOrder === null) {
          return <div>No Augmont Product Order</div>;
        }

        return (
          <div>
            <div>{`ID: ${augmontProductOrder.id}`}</div>
            <div>{`User Name: ${augmontProductOrder.user_name}`}</div>
            <div>{`User Mobile: ${augmontProductOrder.user_mobile}`}</div>
            <div>{`User Address: ${augmontProductOrder.user_address}`}</div>
            <div>{`User City: ${augmontProductOrder.user_city}`}</div>
            <div>{`User State: ${augmontProductOrder.user_state}`}</div>
            <div>{`User Pincode: ${augmontProductOrder.user_pincode}`}</div>
            <div>{`Created At: ${augmontProductOrder.created_at}`}</div>
            <div>{`Product Name: ${augmontProductOrder.product_name}`}</div>
            <div>{`Product SKU: ${augmontProductOrder.product_sku}`}</div>
            <div>{`Product Purity: ${augmontProductOrder.product_purity}`}</div>
            <div>{`Product Weight: ${augmontProductOrder.product_weight}`}</div>
            <div>{`Purchased Gold Quantity: ${augmontProductOrder.purchased_gold_quantity}`}</div>
            <div>{`Amount Paid: ${augmontProductOrder.amount_paid}`}</div>
            <div>{`Delivery Making Charges: ${augmontProductOrder.delivery_making_charges}`}</div>
            <div>{`Order Status: ${augmontProductOrder.order_status}`}</div>
            <div>{`Lock Price: ${augmontProductOrder.lock_price}`}</div>
            <div>{`Merchant Transaction ID: ${augmontProductOrder.merchant_transaction_id}`}</div>
            <div>{`Is Paid: ${augmontProductOrder.is_paid}`}</div>
            <div>{`Is Delivered: ${augmontProductOrder.is_delivered}`}</div>
            <div>{`Scheme: ${augmontProductOrder.scheme}`}</div>
          </div>
        );
      },
    },
    {
      field: "cash_withdrawal",
      headerName: "Cash Withdrawal",
      renderCell: (params) => {
        const cashWithdrawal = params.row.cash_withdrawal;
        if (cashWithdrawal === null) {
          return <div>No Cash Withdrawal</div>;
        }

        return (
          <div>
            <div>{`ID: ${cashWithdrawal.id}`}</div>
            <div>{`Block ID: ${cashWithdrawal.block_id}`}</div>
            <div>{`Lock Price: ${cashWithdrawal.lock_price}`}</div>
            <div>{`Metal Quantity: ${cashWithdrawal.metal_quantity}`}</div>
            <div>{`Merchant Transaction ID: ${cashWithdrawal.merchant_transaction_id}`}</div>
            <div>{`Is Completed: ${cashWithdrawal.is_completed}`}</div>
            <div>{`Is Processed: ${cashWithdrawal.is_processed}`}</div>
            <div>{`Withdrawal Status: ${cashWithdrawal.withdrawal_status}`}</div>
            <div>{`Scheme: ${cashWithdrawal.scheme}`}</div>
            <div>{`Plan Type: ${cashWithdrawal.plan_type}`}</div>
            <div>{`Created At: ${cashWithdrawal.created_at}`}</div>
          </div>
        );
      },
    },
    {
      field: "jeweller_redemption",
      headerName: "Jeweller Redemption",
      renderCell: (params) => {
        const jewellerRedemption = params.row.jeweller_redemption;
        if (jewellerRedemption === null) {
          return <div>No Jeweller Redemption</div>;
        }

        const store = jewellerRedemption.jeweller_store;
        return (
          <div>
            <div>{`ID: ${jewellerRedemption.id}`}</div>
            <div>{`E Gold Plan ID: ${jewellerRedemption.e_gold_plan_id}`}</div>
            <div>{`Scheme: ${jewellerRedemption.scheme}`}</div>
            <div>{`Store ID: ${store.id}`}</div>
            <div>{`Store Name: ${store.store_name}`}</div>
            <div>{`Logo: ${store.logo}`}</div>
            <div>{`Store Latitude: ${store.store_latitude}`}</div>
            <div>{`Store Longitude: ${store.store_longitude}`}</div>
            <div>{`Address: ${store.address}`}</div>
            <div>{`Location: ${store.location}`}</div>
          </div>
        );
      },
    },
    {
      field: "saving_plan_payment",
      headerName: "Saving Plan Payment",
      valueGetter: (params) => params.row.saving_plan_payment || "N/A",
    },
    {
      field: "saving_plan_withdrawal",
      headerName: "Saving Plan Withdrawal",
      valueGetter: (params) => params.row.saving_plan_withdrawal || "N/A",
    },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      valueGetter: (params) => params.row.transaction_date || "N/A",
    },
    {
      field: "invoice",
      headerName: "Invoice",
      valueGetter: (params) => params.row.invoice || "N/A",
    },
    {
      field: "is_transaction_successful",
      headerName: "Is Transaction Successful",
      valueGetter: (params) =>
        params.row.is_transaction_successful ? "Yes" : "No",
    },
    {
      field: "transaction_status",
      headerName: "Transaction Status",
      valueGetter: (params) => params.row.transaction_status || "N/A",
    },
    {
      field: "augmont_transactions",
      headerName: "Augmont Transactions",
      renderCell: (params) => {
        const augmontTransactions = params.row.augmont_transactions;
        if (augmontTransactions === null || augmontTransactions.length === 0) {
          return <div>No Augmont Transactions</div>;
        }

        return (
          <div>
            {augmontTransactions.map((transaction, index) => (
              <div key={index}>
                <div>{`ID: ${transaction.id}`}</div>
                <div>{`Metal Type: ${transaction.metal_type}`}</div>
                <div>{`Amount: ${transaction.amount}`}</div>
                <div>{`Metal Quantity: ${transaction.metal_quantity}`}</div>
                <div>{`Transaction Type: ${transaction.transaction_type}`}</div>
                <div>{`Created At: ${transaction.created_at}`}</div>
                <div>{`CGST: ${transaction.cgst || "N/A"}`}</div>
                <div>{`Amount Without Tax: ${transaction.amount_without_tax || "N/A"
                  }`}</div>
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title="User Transaction List"
            subtitle={`Total Number of Transactions: ${data.count}`}
          />
        </Box>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search user_name / mobile_number"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        {/* DataTable */}

        <Box m="30px 0 0 0" height="75vh">
          <p>Filter by Date</p>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button
                size="small"
                sx={{ margin: "3px" }}
                variant="contained"
                color="success"
                onClick={handleApplyFilter}
              >
                Apply
              </Button>
            </Box>

            <Box>
              <Select
                value={selectedOptionForTransaction}
                onChange={handleSelectChangeTransactionType}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                <MenuItem value="PAYMENT">PAYMENT</MenuItem>
                <MenuItem value="BUY">BUY</MenuItem>
                <MenuItem value="SELL">SELL</MenuItem>
                <MenuItem value="LEASE">LEASE</MenuItem>
                <MenuItem value="LEASE_BROKEN">LEASE BROKEN</MenuItem>
                <MenuItem value="CASH_WITHDRAWAL">CASH WITHDRAWAL</MenuItem>
                <MenuItem value="GOLD_COIN_WITHDRAWAL">
                  GOLD COIN WITHDRAWAL
                </MenuItem>
                <MenuItem value="INTEREST">INTEREST</MenuItem>
                <MenuItem value="PLUS_BENEFIT">PLUS BENEFIT</MenuItem>
                <MenuItem value="JEWELLER_REDEMPTION">
                  JEWELLER REDEMPTION
                </MenuItem>
              </Select>

              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                m={"2px"}
              >
                <Button
                  size="small"
                  sx={{ padding: "0" }}
                  variant="contained"
                  color="success"
                  onClick={clearTransactionTypeFilters}
                >
                  X
                </Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selectedOptionForPayment}
                onChange={handleSelectChangePaymentType}
              >
                <MenuItem value="All Payment Type">Payment For</MenuItem>
                <MenuItem value="EGOLD">EGOLD</MenuItem>
                <MenuItem value="GOLD COIN">GOLD COIN</MenuItem>
                <MenuItem value="SAVINGS PLAN">SAVINGS PLAN</MenuItem>
              </Select>

              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                m={"2px"}
              >
                <Button
                  size="small"
                  sx={{ padding: "0" }}
                  variant="contained"
                  color="success"
                  onClick={clearPaymentTypeFilters}
                >
                  X
                </Button>
              </Box>
            </Box>

            <Box
              marginLeft={"20px"}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <p>Page Size</p>
              <Select value={pageSize} onChange={handlePageSizeChange}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>
          </Box>

          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                Augmont Transaction List
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                background: `#fff !important`,
                margin: "5px",
              },
              marginTop: "5px",
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={egoldUserTransactions}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== "Sl_No" && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleCloseModal}
                color="warning"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Pagination Buttons */}
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EgoldUserTransactions;
