import * as React from 'react';
import { colorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
// import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import TotalUsers from './pages/TotalUsers/TotalUsers';
import Sidebar from './pages/global/Sidebar';
import Topbar from './pages/global/Topbar';
import Footer from './pages/global/Footer';
import { styled } from '@mui/material';
import UserSavingPlans from './pages/TotalUsers/UserSavingPlans';
import UserTransactions from './pages/TotalUsers/UserTransactions';
import TotalSipUsers from './pages/totalSip/TotalSipUsers';
import GoldCoinOrders from './pages/goldCoinOrders/GoldCoinOrders';
import GoldCoinJewellerStoreDetails from './pages/goldCoinOrders/GoldCoinJewellerStoreDetails';
import ActiveSavingPlanList from './pages/totalSip/ActiveSavingPlanList';
// import { setAccessToken } from './tokenService';
import KycUserList from './pages/kycStatus/KycUserList';
import KycUserLogs from './pages/kycStatus/KycUserLogs';
import KycUserPanLogs from './pages/kycStatus/KycUserPanLogs';
import Contests from './pages/contests/Contests';
import DiwaliContestParticipantsList from './pages/contests/DiwaliContestParticipantsList';
import PointsEarningHistory from './pages/contests/PointsEarningHistory';
import Login from './components/Login';
import { UserAuthProvider } from './context/AuthProvider';
import { useLocation } from 'react-router-dom';
import CustomPaginationGrid from './pages/global/Footer';
import Withdrawal from './pages/withdrawal/Withdrawal';
import UserWithdrawalDetails from './pages/withdrawal/UserWithdrawalDetails';
import UpcomingDeposits from './pages/upcomingDeposits/UpcomingDeposits';
import UpcomingDepositsPlanDetails from './pages/upcomingDeposits/UpcomingDepositsPlanDetails';
import VerifiedUsers from './pages/TotalUsers/VerifiedUsers';
import NotVerifiedUsers from './pages/TotalUsers/NotVerifiedUsers';
import FlexiActiveSavingPlan from './pages/totalSip/FlexiActiveSavingPlan';
import FixedActiveSavingPlan from './pages/totalSip/FixedActiveSavingPlan';
import SipMonthlyPlanInvestors from './pages/totalSip/SipMonthlyPlanInvestors';
import SipOneTimePlanInvestors from './pages/totalSip/SipOneTimePlanInvestors';
import Home from './pages/home/Home';
import GoldDashboard from './pages/goldDhasboard/GoldDashboard';

import TotalEGoldUsers from './pages/eGold/totalEgoldUsers/TotalEGoldUsers';
import EgoldUserSavings from './pages/eGold/totalEgoldUsers/EgoldUserSavings';
// please not there are two files one is EgoldUserTransactions and the other is EgoldUserTransaction
import EgoldUserTransaction from './pages/eGold/totalEgoldUsers/EgoldUserTransaction';
import EgoldPaymentTransactionsInUserTransactions from './pages/eGold/totalEgoldUsers/EgoldPaymentTransactionsInUserTransaction';
import EgoldAugmontTransactionsInUserTransactions from './pages/eGold/totalEgoldUsers/EgoldAugmontTransactionInUserTransaction';
import EgoldPlanList from './pages/eGold/egoldPlanList/EgoldPlanList';
import EgoldBankDetailsList from './pages/eGold/egoldBankDetails/EgoldBankDetailsList';
import EgoldWithdrawalList from './pages/eGold/egoldWithdrawal/EgoldWithdrawalList';
import EgoldAugmontProductOrder from './pages/eGold/egoldAugmontProductOrder/EgoldAugmontProductOrderList';
import AugmontTransactionList from './pages/eGold/augmontTransactions/AugmontTransactionsList';
//please not there are two files one is EgoldUserTransactions and the other is EgoldUserTransaction 
import EgoldUserTransactions from './pages/eGold/userTransactions/EgoldUserTransactions';
import EgoldActivePlanUsers from './pages/eGold/egoldAUMCardAndPlanList/EgoldActivePlanUsers';
import EgoldActivePlanList from './pages/eGold/egoldAUMCardAndPlanList/EgoldActivePlanList';
import UPIDetails from './pages/eGold/egoldUPIDetails/UPIDetails';
import UPIJuspayDetails from './pages/eGold/egoldUPIDetails/UPIJuspayDetails';
import Rewards from './pages/eGold/rewards/Rewards';
import ReferAndEarn from './pages/eGold/referAndEarn/ReferAndEarn';
import ContestList from './pages/eGold/contests/ContestList';
import ContestParticipants from './pages/eGold/contests/ContestParticipants';
import EgoldUserDetails from './pages/eGold/totalEgoldUsers/EgoldUserDetails';
import Support from './pages/eGold/support/Support';
import SupportCards from './pages/eGold/support/SupportOptions';
import KYCPan from './pages/eGold/support/KycPan';
import EgoldWithdrawalLiveStatus from './pages/eGold/egoldWithdrawal/EgoldWithdrawalLiveStatus';

function App() {
  const location = useLocation();
  const shouldRenderSidebarAndTopbar = location.pathname !== '/';
  const RequireAuth = ({ type, children }) => {
    const isLoggedIn = !!localStorage.getItem(type);
    return isLoggedIn ? children : <Navigate to="/" replace />;
  };

  const [theme, colorMode] = useMode();



  // Set the access token
  // setAccessToken(token);
  return (
    <colorModeContext.Provider value={colorMode}>

      <ThemeProvider theme={theme}>

        <CssBaseline />
        <div className="app">

          <UserAuthProvider>
            <Routes>
              <Route path="/" element={<div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%' // This sets the container to take the full height of the viewport
              }}>
                <Login />
              </div>
              } />
            </Routes>
          </UserAuthProvider>

          {shouldRenderSidebarAndTopbar && (
            <>
              <Sidebar className="position" />
              <main className='content' style={{ width: "100%" }}>
                <Topbar />
                <UserAuthProvider>
                  <Routes>
                    <Route path="/home" element={<RequireAuth type="auth"><Home /></RequireAuth>} />
                    <Route path="/goldDashboard" element={<RequireAuth type="auth"><GoldDashboard /></RequireAuth>} />
                    <Route path="/dashboard" element={<RequireAuth type="auth"> <Dashboard /></RequireAuth>} />
                    <Route path="/users" element={<RequireAuth type="auth"><TotalUsers /></RequireAuth>} />
                    <Route path='/userSavingPlans/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><UserSavingPlans /></RequireAuth>} />
                    <Route path='/userTransactions/:planId/:userId/:phoneNumber/:name' element={<RequireAuth type="auth"><UserTransactions /></RequireAuth>} />
                    <Route path="/totalSipUsers" element={<RequireAuth type="auth"><TotalSipUsers /></RequireAuth>} />
                    <Route path="/goldcoinorders" element={<RequireAuth type="auth"><GoldCoinOrders /></RequireAuth>} />
                    <Route path='/goldCoinJewellerStoreDetails/:userId/:cartId' element={<RequireAuth type="auth"><GoldCoinJewellerStoreDetails /></RequireAuth>} />
                    <Route path='/activeSavingPlan/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><ActiveSavingPlanList /></RequireAuth>} />
                    <Route path='/kycDetails' element={<RequireAuth type="auth"><KycUserList /></RequireAuth>} />
                    <Route path='/userKycAadharDetails/:userId/:phoneNumber' element={<RequireAuth type="auth"><KycUserLogs /></RequireAuth>} />
                    <Route path='/userKycPanDetails/:userId/:phoneNumber' element={<RequireAuth type="auth"><KycUserPanLogs /></RequireAuth>} />
                    <Route path='/contestList' element={<RequireAuth type="auth"><Contests /></RequireAuth>} />
                    <Route path='/contestParticipantsList/:slug' element={<RequireAuth type="auth"><DiwaliContestParticipantsList /></RequireAuth>} />
                    <Route path='/pointsEarningHistory/:participantId/:slug' element={<RequireAuth type="auth"><PointsEarningHistory /></RequireAuth>} />
                    <Route path='/withdrawal' element={<RequireAuth type="auth"><Withdrawal /></RequireAuth>} />
                    <Route path='/userWithdrawalDetails/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><UserWithdrawalDetails /></RequireAuth>} />
                    <Route path='/upcomingdeposits' element={<RequireAuth type="auth"><UpcomingDeposits /></RequireAuth>} />
                    <Route path='/upcomingdepositsplandetails/:planId' element={<RequireAuth type="auth"><UpcomingDepositsPlanDetails /></RequireAuth>} />
                    <Route path='/verifiedusers' element={<RequireAuth type="auth"><VerifiedUsers /></RequireAuth>} />
                    <Route path='/notverifiedusers' element={<RequireAuth type="auth"><NotVerifiedUsers /></RequireAuth>} />
                    <Route path='/fixedactiveplans/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><FixedActiveSavingPlan /></RequireAuth>} />
                    <Route path='/flexiactiveplans/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><FlexiActiveSavingPlan /></RequireAuth>} />
                    <Route path='/monthlysipusers' element={<RequireAuth type="auth"><SipMonthlyPlanInvestors /></RequireAuth>} />
                    <Route path='/onetimesipusers' element={<RequireAuth type="auth"><SipOneTimePlanInvestors /></RequireAuth>} />

                    {/* E-Gold */}

                    <Route path="/totalegoldusers" element={<RequireAuth type="auth"><TotalEGoldUsers /></RequireAuth>} />
                    <Route path="/egoldusersavings/:userId/:phoneNumber/:name?" element={<RequireAuth type="auth"><EgoldUserSavings /></RequireAuth>} />
                    {/* please not there are two files one is EgoldUserTransactions and the other is EgoldUserTransaction */}
                    <Route path="/egoldusersavingtransaction/:planId/:userId/:phoneNumber/:name?" element={<RequireAuth type="auth"><EgoldUserTransaction /></RequireAuth>} />
                    <Route path="/egoldpaymentusertransactions/:planId/:userId/:phoneNumber/:name?" element={<RequireAuth type="auth"><EgoldPaymentTransactionsInUserTransactions /></RequireAuth>} />
                    <Route path="/egoldaugmontusertransactions/:planId/:userId/:phoneNumber/:name?" element={<RequireAuth type="auth"><EgoldAugmontTransactionsInUserTransactions /></RequireAuth>} />
                    <Route path='/egoldplanlist' element={<RequireAuth type="auth"><EgoldPlanList /></RequireAuth>} />
                    <Route path='/egoldbankdetails' element={<RequireAuth type="auth"><EgoldBankDetailsList /></RequireAuth>} />
                    <Route path='/egoldwithdrawal' element={<RequireAuth type="auth"><EgoldWithdrawalList /></RequireAuth>} />
                    <Route path='/augmontproductorderlist' element={<RequireAuth type="auth"><EgoldAugmontProductOrder /></RequireAuth>} />
                    <Route path='/augmonttransactionlist' element={<RequireAuth type="auth"><AugmontTransactionList /></RequireAuth>} />

                    {/* please not there are two files one is EgoldUserTransactions and the other is EgoldUserTransaction */}
                    <Route path='/egoldusertransactionlist' element={<RequireAuth type="auth"><EgoldUserTransactions /></RequireAuth>} />
                    <Route path='/goldAUMActivePlanUsers' element={<RequireAuth type="auth"><EgoldActivePlanUsers /></RequireAuth>} />
                    <Route path="/egoldactivePlanlist/:userId/:phoneNumber/:name?" element={<RequireAuth type="auth"><EgoldActivePlanList /></RequireAuth>} />
                    <Route path='/upiDetails' element={<RequireAuth type="auth"><UPIDetails /></RequireAuth>} />
                    <Route path='/upiJuspayDetails/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><UPIJuspayDetails /></RequireAuth>} />
                    <Route path='/rewardsList' element={<RequireAuth type="auth"><Rewards /></RequireAuth>} />
                    <Route path='/refer_and_earn_list' element={<RequireAuth type="auth"><ReferAndEarn /></RequireAuth>} />
                    <Route path='/contests' element={<RequireAuth type="auth"><ContestList /></RequireAuth>} />
                    <Route path='/contest_participants/:userId/:name' element={<RequireAuth type="auth"><ContestParticipants /></RequireAuth>} />
                    <Route path='/egoldUserDetails/:userId/:phoneNumber/:name?' element={<RequireAuth type="auth"><EgoldUserDetails /></RequireAuth>} />
                    <Route path='/supportForms' element={<RequireAuth type="auth"><Support /></RequireAuth>} />
                    <Route path='/supportCards' element={<RequireAuth type="auth"><SupportCards /></RequireAuth>} />
                    <Route path='/KycPanVerification' element={<RequireAuth type="auth"><KYCPan /></RequireAuth>} />
                    <Route path='/reraiseWithdrawalRequest/:userId/:phoneNumber/:transactionId/:name' element={<RequireAuth type="auth"><EgoldWithdrawalLiveStatus /></RequireAuth>} />

                  </Routes>
                </UserAuthProvider>
              </main>
            </>
          )}



          {/* <main className='content'>
    <Topbar />
      <UserAuthProvider>
      <Routes>
            <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<RequireAuth type="auth"> <Dashboard /></RequireAuth>}/>
        <Route path="/users" element={<RequireAuth type="auth"><TotalUsers /></RequireAuth>} />
        <Route path='/userSavingPlans/:userId/:phoneNumber/:name?' element={<UserSavingPlans />} />
        <Route path='/userTransactions/:planId/:userId/:phoneNumber/:name' element={<UserTransactions />} />
        <Route path="/totalSipUsers" element={<TotalSipUsers />} />
        <Route path="/goldcoinorders" element={<GoldCoinOrders />} />
        <Route path='/goldCoinJewellerStoreDetails/:userId/:cartId' element={<GoldCoinJewellerStoreDetails />} />
        <Route path='/activeSavingPlan/:userId/:phoneNumber/:name?' element={<ActiveSavingPlanList />} />
        <Route path='/kycDetails' element={<KycUserList />} />
        <Route path='/userKycDetails/:userId' element={<KycUserLogs />} />
        <Route path='/userKycPanDetails/:userId' element={<KycUserPanLogs />} />
        <Route path='/contestList' element={<Contests />} />
        <Route path='/contestParticipantsList/:slug' element={<DiwaliContestParticipantsList />} />
        <Route path='/pointsEarningHistory/:participantId/:slug' element={<PointsEarningHistory />} />
       
      </Routes>
      </UserAuthProvider>
     
    </main> */}

        </div>
      </ThemeProvider>
    </colorModeContext.Provider>
  );
}

export default App;
