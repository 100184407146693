import { Box, Typography, Button, IconButton } from "@mui/material";
import {
  DataGrid, GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
// import { getAccessToken } from "../../tokenService";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { getAccessToken } from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer >
      {/* <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector /> */}
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const DiwaliContestParticipantsList = () => {

  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { slug } = useParams();
  const [participantList, setParticipantList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchDiwaliContestParticipants(slug, page, pageSize, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchDiwaliContestParticipants(slug, page, pageSize, searchInput);
    }
  }

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  //Prepping to fetch the API

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchDiwaliContestParticipants(slug, page, pageSize, searchInput);
  }, [slug, page, pageSize, searchInput]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchDiwaliContestParticipants = async (slug, pageNumber, pageSize, searchQuery) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/contest/${slug}/contest-leaderboard?page=${pageNumber}&page_size=${pageSize}&q=${searchQuery}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/')
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      // console.log(data.results); //all the data from the api

      if (Array.isArray(data.results) && data.results.length > 0) {
        // Use the keys of the first data entry as columns
        const firstEntry = data.results[0];
        const columnNames = Object.keys(firstEntry);

        // Exclude properties that you don't want as columns, e.g., 'point_earning_history'
        const filteredColumns = columnNames.filter(
          (columnName) => columnName !== "point_earning_history"
        );

        setColumns(filteredColumns);
        setParticipantList(data.results);
      }

      setTotalRows(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching Contest Participants:", error);
    } finally {
      setLoading(false);
    }
  };

  const dynamicColumns = columns.map((columnName) => ({
    field: columnName,
    headerName: columnName,
    flex: 1,
    valueGetter: (params) => {
      // If the column is inside point_earning_history, access the nested property
      if (params.row.point_earning_history && params.row.point_earning_history[columnName]) {
        const value = params.row.point_earning_history[columnName];

        // If the value is an object, assume it has 'is_completed' and 'points_earned' properties
        if (typeof value === 'object' && value !== null) {
          return `${value.is_completed ? 'Completed' : 'Not Completed'} - ${value.points_earned}`;
        }

        return value.toString();
      }

      return params.row[columnName] && params.row[columnName].toString();
    },
  }));

  // Add nested columns based on the keys inside point_earning_history
  if (participantList.length > 0) {
    const firstEntry = participantList[0];
    if (firstEntry.point_earning_history) {
      const pointEarningHistoryKeys = Object.keys(firstEntry.point_earning_history);

      pointEarningHistoryKeys.forEach((key) => {
        dynamicColumns.push({
          field: `point_earning_history_${key}`,
          headerName: `Point Earning History ${key}`,
          flex: 1,
          valueGetter: (params) => {
            const value = params.row.point_earning_history[key];

            // If the value is an object, assume it has 'is_completed' and 'points_earned' properties
            if (typeof value === 'object' && value !== null) {
              return `${value.is_completed ? 'Completed' : 'Not Completed'} - ${value.points_earned}`;
            }

            return value.toString();
          },
        });
      });
    }
  }


  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      setPage(parseInt(pageNumber));
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };


  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
    pageNumbers.push(i);
  }

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: '5px', borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button style={{ padding: '5px', borderRadius: "5px" }} key={1} onClick={() => handlePageClick(1)}>
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: '5px', borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: '5px', borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button style={{ padding: '5px', borderRadius: "5px" }} key="next" onClick={handleNextPage} disabled={!nextPage}>
        Next Page
      </button>
    );

    return buttons;
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };


  // const columns = [
  //   {
  //     field: "Sl_No",
  //     headerName: "Sl No",
  //     renderCell: (params) => params.api.getAllRowIds().indexOf(params.id)+1

  //   },
  //   {
  //     field: "id",
  //     headerName: "Participant ID",
  //     minWidth: 100,

  //   },
  //   {
  //     field: "name",
  //     headerName: "Participant Name",
  //     cellClassName: "name-column--cell",
  //     minWidth: 250,

  //   },
  //   {
  //       field: "phone_number",
  //       headerName: "Phone Number",
  //       minWidth: 150,
  //     },
  //     {
  //       field: "email",
  //       headerName: "Email",
  //       minWidth: 150,
  //     },
  //     {
  //     field: "investor_id",
  //     headerName: "User ID",
  //     minWidth: 150,
  //   },
  //   {
  //     field: "ranking",
  //     headerName: "Ranking",
  //     minWidth: 50,
  //   },
  //   {
  //     field: "points_earned",
  //     headerName: "Total Points Earned",
  //     minWidth: 100,
  //   },
  //   {
  //     field: "point_earning_history_referral_complete",
  //     headerName: "Referral Completed",
  //     // valueGetter: (params) => params.row.point_earning_history.referral.is_completed,
  //     valueGetter: (params) => {
  //       const pointHistory = params.row.point_earning_history;

  //       if (pointHistory) {
  //         // Point earning history is not null
  //         const referralPoints = pointHistory.referral.is_completed;
  //         return referralPoints;
  //       } else {
  //         // Point earning history is null
  //         return 'Point earning history is not available.';
  //       }
  //     },
  //     minWidth: 100,
  //   },
  //   {
  //       field: "point_earning_history_referral_points_earned",
  //       headerName: "Points Earned (Referral)",
  //       // valueGetter: (params) => params.row.point_earning_history.referral.points_earned,
  //       valueGetter: (params) => {
  //         const pointHistory = params.row.point_earning_history;

  //         if (pointHistory) {
  //           // Point earning history is not null
  //           const referralPoints = pointHistory.referral.points_earned;
  //           return referralPoints;
  //         } else {
  //           // Point earning history is null
  //           return 'Point earning history is not available.';
  //         }
  //       },
  //       minWidth: 100,
  //     },
  //     {
  //       field: "point_earning_history_saving_plan",
  //       headerName: "Saving Plan Completed",
  //       // valueGetter: (params) => params.row.point_earning_history.saving_plan.is_completed,

  //       valueGetter: (params) => {
  //         const pointHistory = params.row.point_earning_history;

  //         if (pointHistory) {
  //           // Point earning history is not null
  //           const savingPlanCompleted = pointHistory.saving_plan.is_completed;
  //           return savingPlanCompleted;
  //         } else {
  //           // Point earning history is null
  //           return 'Point earning history is not available.';
  //         }
  //       },
  //       minWidth: 100,
  //     },
  //     {
  //       field: "point_earning_history_saving_points_earned",
  //       headerName: "Points Earned (Saving Plan)",
  //       // valueGetter: (params) => params.row.point_earning_history.saving_plan.points_earned,
  //       valueGetter: (params) => {
  //         const pointHistory = params.row.point_earning_history;

  //         if (pointHistory) {
  //           // Point earning history is not null
  //           const savingPlanPoints = pointHistory.saving_plan.points_earned;
  //           return savingPlanPoints;
  //         } else {
  //           // Point earning history is null
  //           return 'Point earning history is not available.';
  //         }
  //       },
  //       minWidth: 100,
  //     },
  //     {
  //       field: "point_earning_history_kyc_complete",
  //       headerName: "KYC Verified",
  //       // valueGetter: (params) => params.row.point_earning_history.verified_kyc.is_completed,
  //       valueGetter: (params) => {
  //         const pointHistory = params.row.point_earning_history;

  //         if (pointHistory) {
  //           // Point earning history is not null
  //           const kycVerified = pointHistory.verified_kyc.is_completed;
  //           return kycVerified;
  //         } else {
  //           // Point earning history is null
  //           return 'Point earning history is not available.';
  //         }
  //       },
  //       minWidth: 100,
  //     },
  //     {
  //         field: "point_earning_history_kyc_points_earned",
  //         headerName: "Points Earned (KYC)",
  //         // valueGetter: (params) => params.row.point_earning_history.verified_kyc.points_earned,
  //         valueGetter: (params) => {
  //           const pointHistory = params.row.point_earning_history;

  //           if (pointHistory) {
  //             // Point earning history is not null
  //             const kycVerifiedPoints = pointHistory.verified_kyc.points_earned;
  //             return kycVerifiedPoints;
  //           } else {
  //             // Point earning history is null
  //             return 'Point earning history is not available.';
  //           }
  //         },
  //         minWidth: 100,
  //       },
  //       {
  //         field: "point_earning_history_instagram",
  //         headerName: "Follow On Instagram",
  //         // valueGetter: (params) => params.row.point_earning_history.instagram_follow.is_completed,
  //         valueGetter: (params) => {
  //           const pointHistory = params.row.point_earning_history;

  //           if (pointHistory) {
  //             // Point earning history is not null
  //             const  followingInstagram = pointHistory.instagram_follow.is_completed;
  //             return followingInstagram ;
  //           } else {
  //             // Point earning history is null
  //             return 'Point earning history is not available.';
  //           }
  //         },
  //         minWidth: 100,
  //       },
  //       {
  //         field: "point_earning_history_insta_points_earned",
  //         headerName: "Points Earned (Instagram)",
  //         // valueGetter: (params) => params.row.point_earning_history.instagram_follow.points_earned,
  //         valueGetter: (params) => {
  //           const pointHistory = params.row.point_earning_history;

  //           if (pointHistory) {
  //             // Point earning history is not null
  //             const  followingInstagramPoints = pointHistory.instagram_follow.points_earned;
  //             return followingInstagramPoints ;
  //           } else {
  //             // Point earning history is null
  //             return 'Point earning history is not available.';
  //           }
  //         },
  //         minWidth: 100,
  //       },
  // ];


  return (
    <>
      <Box m="20px">
        <Header
          title={`Contest Name: ${slug}`}
          subtitle={``}
        />
        <Box m="40px 0 0 0" height="65vh">
          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            margin="5px"
          >
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchInputChange}
              onKeyDown={handleKeyDown}
            />
            <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </Box>

          <div style={{ marginTop: "20px" }} role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/contestList">
                Contest List
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="#">
                Contest Name: {slug}
              </Link>
              {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
            </Breadcrumbs>
          </div>
          <Box height="75vh" width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none"
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                color: "#fff !important"
              }
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal"
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important"
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important"
                },
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  overflow: "visible",
                  wordWrap: "break-word",
                },
              }}
              rowHeight={75}
              rows={participantList}
              columns={dynamicColumns}
              loading={loading}
              pagination
              page={page}
              //   getRowId={(row) => row.plan_id}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              slots={{ toolbar: CustomToolbar }}
              hideFooterPagination={true}
              onCellClick={handleRowClick}

            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <div>
                  {columns.map(column => (
                    <div key={column.field}>
                      {column.headerName}: {selectedRow[column.field]}
                    </div>
                  ))}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );


};

export default DiwaliContestParticipantsList;

//misc code

// {
// field:"access",
// headerName:"Access",
//
// renderCell: ({row: {access}}) => {
//     return (
//         <Box
//         width="60%"
//         m="0 auto"
//         p="5px"
//         display="flex"
//         justifyContent="center"
//         backgroundColor = {
//             access === "admin"
//             ? colors.greenAccent[600]
//             :colors.greenAccent[700]

//         }
//         borderRadius="4px"
//         >
//             {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
//             {access === "manager" && <SecurityOutlinedIcon />}
//             {access === "user" && <LockOpenOutlinedIcon />}
//             <Typography color={colors.grey[100]} sx={{marginLeft:"5px"}}>
//                 {access}
//             </Typography>
//         </Box>
//     )
// }

// },