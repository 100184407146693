import { useState, useEffect } from "react";
import { getAccessToken } from "../../../utils/authUtils";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { TextField } from "@mui/material";
import { InputBase } from "@mui/material";
import { InputLabel } from '@mui/material';
import { Box, Button } from "@mui/material";

const KYCPan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dob, setdob] = useState('');
  const [pan, setpan] = useState('')
  const [phone, setPhone] = useState('')
  const [panValidated, setpanValidated] = useState(true);
  const [phoneValidated, setPhoneValidated] = useState(true);
  const [response, setResponse] = useState([]);
  const accessToken = getAccessToken();
  const base_url = "https://proapi.getplus.in";

  const onpanChange = (event) => {
    // setpan(event.target.value);
    if (/^[A-Z 0-9]{0,10}$/.test(event.target.value) || event.target.value === '') {
      setpan(event.target.value);
      validatepan(event.target.value);
    }
  };

  const onPhoneChange = (event) => {
    // setPhone(event.target.value);
    if (/^[6-9][0-9]{0,9}$/.test(event.target.value) || event.target.value === '') {
      setPhone(event.target.value);
      validatePhone(event.target.value);
    }
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[6-9][0-9]{9}$/;
    if (phoneRegex.test(phone)) {
      setPhoneValidated(false);
    } else {
      setPhoneValidated(true);
    }
  };

  const ondobChange = (event) => {
    setdob(event.target.value);

  };

  const validatepan = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (panRegex.test(pan)) {
      setpanValidated(false);
    } else {
      setpanValidated(true);
    }
  };

  // const validatedob = (dob) => {
  //   const dobRegex = /^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/;
  //   if (dobRegex.test(dob)) {
  //     setdobValidated(false);
  //   } else {
  //     setdobValidated(true);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${base_url}/api/v1/ccapi/verify-pan`, {
      method: 'POST',
      body: JSON.stringify({
        phone_number: phone,
        pan_number: pan,
        dob: dob
      }),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }

    })
      .then((res) => res.json())
      .then((data) => {
        const response = [data];
        // console.log(pan, dob)
        setResponse(response)
        setpan('')
        setdob('')
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  useEffect(() => {
    validatepan(pan);
  }, [panValidated])
  return (
    <>
      <div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div style={{
            maxWidth: "450px",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 1) 0px 25px 20px -20px"
          }}>
            <form onSubmit={handleSubmit}>
              <h1 style={{
                textAlign: "center"
              }}>Enter PAN Details</h1>
              <div style={{
                margin: "10px 0px"
              }}>
                <label style={{
                  color: "#fff"
                }} > Phone Number </label>
                <Box
                  display="flex"
                  // backgroundColor={colors.primary[400]}
                  backgroundColor="#fff"
                  borderRadius="3px"
                  width="400px"
                  py="10px"
                >

                  <InputBase sx={{ ml: 2, flex: 1, color: "black", fontSize: "16px" }} type="text" value={phone} className="" onChange={onPhoneChange} />
                </Box>
              </div>
              <div style={{
                margin: "10px 0px"
              }}>
                <label style={{
                  color: "#fff"
                }} > PAN Number </label>
                <Box
                  display="flex"
                  // backgroundColor={colors.primary[400]}
                  backgroundColor="#fff"
                  borderRadius="3px"
                  width="400px"
                  py="10px"
                >

                  <InputBase sx={{ ml: 2, flex: 1, color: "black", fontSize: "16px" }} type="text" value={pan} className="" onInput={onpanChange} />
                </Box>
              </div>
              <div style={{
                margin: "10px 0px"
              }} >
                <label style={{
                  color: "#fff"
                }} >Date Of Birth</label>
                <Box
                  display="flex"
                  // backgroundColor={colors.primary[400]}
                  backgroundColor="#fff"
                  borderRadius="3px"
                  width="400px"
                  py="10px"
                >
                  <InputBase sx={{ ml: 2, flex: 1, color: "black", fontSize: "16px" }} type="date" value={dob} className="form-control" onChange={ondobChange} />
                </Box>
              </div>
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
                <Button variant="contained" disabled={panValidated || phoneValidated} color="success" style={{
                  padding: "10px",
                  fontWeight: "700"
                }} type="submit">Submit</Button>
              </div>

            </form>
            <div style={{
              marginTop: "20px"
            }}>
              <table>
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {response.map((result, index) => (
                    Object.keys(result).map((key) => (
                      <tr key={key}>
                        <th>{key}</th>
                        <td>
                          {typeof result[key] === 'object' && result[key] !== null
                            ? JSON.stringify(result[key], null, 2) // Convert nested objects to a readable format
                            : result[key] !== null
                              ? result[key].toString() // Convert value to string if it's not null
                              : 'N/A'}
                        </td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KYCPan;