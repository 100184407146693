import {Box, Button, IconButton, Typography, styled, useTheme} from "@mui/material";
import { useContext } from "react";
import {ColorModeContext, colorModeContext, tokens} from "../../theme";
import {InputBase} from "@mui/material";
// or import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
// import styled from "@emotion/styled";


// const styledBox = styled(Box)``; //can use this this to curate all the styles here then doing it inline
const clearAccessToken = () => {
    localStorage.removeItem("auth");
  };

  const handleLogout = () => {
    // Clear the access token from localStorage
    clearAccessToken();
    window.location.href = '/';
  
    // Any other logout-related actions, such as redirecting the user to the login page
  };

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(colorModeContext);
    return(
        // The Box component serves as a wrapper component for most of the CSS utility needs.
        // borderBottom="2px solid"
        <Box display="flex" justifyContent="space-between" p={2} >
            {/* SEARCH BAR */}
              {/* < Box display="flex"
              backgroundColor={colors.primary[400]}
              borderRadius="3px"

              >
                <InputBase sx={{ml:2, flex: 1}} placeholder="Search" />
                <IconButton type="button" sx={{p: 1}}>
                <SearchIcon />
                </IconButton>
              </Box> */}
            
            <Box display="flex" ml="2" flex="2" p="1" >
                <Typography 
                color={colors.greenAccent[200]}
                variant="h3"
                >
                    Customer Care Support Dashboard
                </Typography>
            </Box>

            {/* ICONS */}

            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                    <DarkModeOutlinedIcon/>
                    ) 
                    : (
                    <LightModeOutlinedIcon />
                    )}
                  
                </IconButton>
                {/* <IconButton>
                <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton> */}
                <IconButton>
                    <PersonOutlinedIcon />
                </IconButton>
                <Button variant="contained" color="warning" onClick={handleLogout}>Logout</Button>
            </Box>
        </Box>
    )
}

export default Topbar;