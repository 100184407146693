import { Box, Typography, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../../utils/authUtils";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const EgoldUserSavings = () => {
  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { userId, phoneNumber, name } = useParams();
  const [userSavingsPlan, setuserSavingsPlan] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Prepping to fetch the API

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activePlans, setactivePlans] = useState(false);
  const [notActivePlans, setnotActivePlans] = useState(false);
  const [allPlans, setallPlans] = useState(true);
  const [selectedOption, setSelectedOption] = useState('allPlans');
  const [selectedOptionForTransaction, setselectedOptionForTransaction] = useState('allTransaction');
  const [quantity, setQuantity] = useState(false);
  const [amount, setAmount] = useState(false);
  const [allTransaction, setAllTransactions] = useState(true);
  // filter for plan type ONE TIME/ SUBSCRIPTION
  const [selecteOptionForPlanType, setselecteOptionForPlanType] = useState('allPlanTypes');
  const [oneTime, setOneTime] = useState(false);
  const [sip, setSip] = useState(false);
  const [allPlanTypes, setAllPlanTypess] = useState(true);
  const [selecteOptionForAutopayStatus, setselecteOptionForAutopayStatus] = useState('allAutopay');
  const [allAutopayStatus, setAllAutopayStatus] = useState(true);
  const [activeAutopay, setActiveAutopay] = useState(false);
  const [pausedAutopay, setPausedAutopay] = useState(false);
  const [cancelledAutopay, setCancelledAutopay] = useState(false);
  const [planCreationDate, setPlanCreationDate] = useState(null);

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchUserSavingsPlan(userId, page, pageSize, searchInput);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchUserSavingsPlan(userId, page, pageSize, searchInput);
    }
  }

  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchUserSavingsPlan(userId, page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate);
  }, [userId, page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchUserSavingsPlan = async (userId, pageNumber, pageSize, searchQuery, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate) => {
    setLoading(true);
    let apiUrl = `${base_url}/api/v1/ccapi/egold/v2/egold-plan?user_id=${userId}&page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }
    // if (planCreationDate) {
    //     apiUrl += `&plan_creation_date=${planCreationDate.format('YYYY-MM-DD')}`;
    //   }

    if (allPlans) {
      // If allPlans is true, don't add the is_active parameter to the apiUrl
    } else if (activePlans) {
      apiUrl += '&is_active=True';
    } else if (notActivePlans) {
      apiUrl += '&is_active=False';
    }

    if (allTransaction) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (quantity) {
      apiUrl += '&transaction_in=QUANTITY';
    } else if (amount) {
      apiUrl += '&transaction_in=AMOUNT';
    }

    if (allPlanTypes) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (oneTime) {
      apiUrl += '&plan_type=ONE TIME';
    } else if (sip) {
      apiUrl += '&plan_type=SUBSCRIPTION';
    }

    if (allAutopayStatus) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (activeAutopay) {
      apiUrl += '&autopay_status=ACTIVE';
    } else if (pausedAutopay) {
      apiUrl += '&autopay_status=PAUSE';
    } else if (cancelledAutopay) {
      apiUrl += '&autopay_status=CANCELLED';
    }

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setuserSavingsPlan(data.results); //only the list of saving plans
      // console.log(data); //all the data from the api
      setTotalRows(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      setPage(parseInt(pageNumber));
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };


  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchUserSavingsPlan(userId, page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate);
  };



  //   filter for plan status
  const handleCheckboxChangeForAllUser = () => {
    setallPlans(true);
    setactivePlans(false);
    setnotActivePlans(false);
  };

  const handleCheckboxChangeForactivePlansUser = () => {


    setnotActivePlans(false);
    setallPlans(false);
    setactivePlans(true);
  };

  const handleCheckboxChangeFornotActivePlansUser = () => {
    setactivePlans(false);
    setallPlans(false);
    setnotActivePlans(true);
  };

  //   filter for transaction in
  const handleSelectChangeForAllTransaction = () => {
    setAllTransactions(true);
    setQuantity(false);
    setQuantity(false);
  };

  const handleSelectChangeForQuantity = () => {
    setAllTransactions(false)
    setQuantity(true);
    setAmount(false);
  };

  const handleSelectChangeForAmount = () => {
    setQuantity(false);
    setAllTransactions(false);
    setAmount(true);
  };

  //   filter for plan type
  const handleSelectChangeForAllPlanTypes = () => {
    setAllPlanTypess(true);
    setOneTime(false);
    setSip(false);
  };

  const handleSelectChangeForOneTime = () => {
    setAllPlanTypess(false)
    setOneTime(true);
    setSip(false);
  };

  const handleSelectChangeForSip = () => {
    setOneTime(false);
    setAllPlanTypess(false);
    setSip(true);
  };

  //   filter for auto pay status
  const handleSelectChangeForAllAutopay = () => {
    setAllAutopayStatus(true);
    setActiveAutopay(false);
    setPausedAutopay(false);
    setCancelledAutopay(false);
  };

  const handleSelectChangeForActiveAutopay = () => {
    setActiveAutopay(true);
    setPausedAutopay(false);
    setCancelledAutopay(false);
    setAllAutopayStatus(false);
  };

  const handleSelectChangeForPausedAutopay = () => {
    setActiveAutopay(false);
    setPausedAutopay(true);
    setCancelledAutopay(false);
    setAllAutopayStatus(false);
  };

  const handleSelectChangeForCancelledAutopay = () => {
    setActiveAutopay(false);
    setPausedAutopay(false);
    setCancelledAutopay(true);
    setAllAutopayStatus(false);
  };

  //   filter for plan status
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);

    switch (event.target.value) {
      case 'allPlans':
        handleCheckboxChangeForAllUser();
        break;
      case 'activePlans':
        handleCheckboxChangeForactivePlansUser();
        break;
      case 'inactivePlans':
        handleCheckboxChangeFornotActivePlansUser();
        break;
      default:
        break;
    }
  };

  //   filter for transaction in
  const handleSelectChangeTransactions = (event) => {
    setselectedOptionForTransaction(event.target.value);

    switch (event.target.value) {
      case 'allTransaction':
        handleSelectChangeForAllTransaction();
        break;
      case 'quantity':
        handleSelectChangeForQuantity();
        break;
      case 'amount':
        handleSelectChangeForAmount();
        break;
      default:
        break;
    }
  };

  //   filter for plan type
  const handleSelectChangeForPlanType = (event) => {
    setselecteOptionForPlanType(event.target.value);

    switch (event.target.value) {
      case 'allPlanTypes':
        handleSelectChangeForAllPlanTypes();
        break;
      case 'oneTime':
        handleSelectChangeForOneTime();
        break;
      case 'sip':
        handleSelectChangeForSip();
        break;
      default:
        break;
    }
  };

  //   filter for auto pay status

  const handleSelectChangeForAutopayStatus = (event) => {
    setselecteOptionForAutopayStatus(event.target.value);

    switch (event.target.value) {
      case 'allAutopay':
        handleSelectChangeForAllAutopay();
        break;
      case 'activeAutopay':
        handleSelectChangeForActiveAutopay();
        break;
      case 'pausedAutopay':
        handleSelectChangeForPausedAutopay();
        break;
      case 'cancelledAutopay':
        handleSelectChangeForCancelledAutopay();
        break;
      default:
        break;
    }
  };

  //   plan creation date filter

  const handlePlanCreationDate = (date) => {
    setPlanCreationDate(date);
  };

  const clearFilters = () => {
    setSelectedOption('allPlans');
    setactivePlans(false);
    setnotActivePlans(false);
    setallPlans(true);
  };

  const clearTransactionFilters = () => {
    setselectedOptionForTransaction('allTransaction');
    setQuantity(false);
    setAmount(false);
    setAllTransactions(true);
  };

  const clearPlanTypeFilters = () => {
    setselecteOptionForPlanType('allPlanTypes');
    setOneTime(false);
    setSip(false);
    setAllPlanTypess(true);
  };

  const clearAutopayFilters = () => {
    setselecteOptionForAutopayStatus('allAutopay');
    setActiveAutopay(false);
    setPausedAutopay(false);
    setCancelledAutopay(false);
    setAllAutopayStatus(true);
  };

  const getRowId = (row) => row.id;
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "Plan ID",
      minWidth: 100,
    },
    {
      field: "free_to_withdraw_quantity",
      headerName: "Free To Withdraw Quantity"
    },
    {
      field: "plan_type",
      headerName: "Plan Type",
      minWidth: 50,
    },
    {
      field: "lease_status",
      headerName: "Lease Status",
      valueGetter: (params) => params.row.lease_status ? "Yes" : "No",
      minWidth: 50,

    },
    {
      field: "leased_quantity",
      headerName: "Lease Quantity",
      valueGetter: (params) => params.row.leased_quantity ?? "N/A",
      minWidth: 50,
    },
    {
      field: "subscription_type",
      headerName: "Subscription Type",
      minWidth: 50,
    },
    {
      field: "platform",
      headerName: "Platform",
      minWidth: 50,
    },
    {
      field: "Quantity Details",
      headerName: "Quantity Details",
      renderCell: (params) => {
        return (
          <>
            <p>Total Combined Benefit: {params.row.total_combined_benefit}</p>
            <p>Total Egold: {params.row.total_egold}</p>
            <p>Total Leased Gold: {params.row.total_leased_gold}</p>
            <p>Total Withdrawn: {params.row.total_withdrawn}</p>
          </>
        )
      },
      minWidth: 50,
    },
    {
      field: "plan_amount",
      headerName: "Plan Amount",
      minWidth: 80,
    },
    {
      field: "purchased_quantity",
      headerName: "Purchased Quantity",
      minWidth: 100,
      renderCell:(params)=>{
        const benefitQuantity = params.row.purchased_quantity

        return(
          <div>{benefitQuantity ? benefitQuantity : "N/A"}</div>
        )
      }
    },
    {
      field: "plus_benefit",
      headerName: "Plus Benefit",
      minWidth: 100,
      renderCell:(params)=>{
        const benefitQuantity = params.row.plus_benefit

        return(
          <div>{benefitQuantity ? benefitQuantity : "N/A"}</div>
        )
      }
    },
    // {
    //   field: "metal_quantity",
    //   headerName: "Metal Quantity",
    //   minWidth: 100,
    // },
    {
      field: "total_quantity",
      headerName: "Total Quantity",
      minWidth: 100,
      renderCell:(params)=>{
        const benefitQuantity = params.row.total_quantity

        return(
          <div>{benefitQuantity ? benefitQuantity : "N/A"}</div>
        )
      }
    },
    {
      field: "lock_in_benefit",
      headerName: "Lock In Benefit",
      minWidth: 100,
    },
    {
      field: "total_withdrawn",
      headerName: "Total Withdrawn",
      minWidth: 100,
    },
    // {
    //   field: "transaction_in",
    //   headerName: "Transaction In",
    //   minWidth: 100,
    // },
    {
      field: "activation_date",
      headerName: "Activation Date",
      renderCell: (params) => {
        const timestamp = params.row.activation_date;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 100,
    },

    {
      field: "is_active",
      headerName: "Plan Active",
      valueGetter: (params) => params.row.is_active ? "Yes" : "No",
      minWidth: 50,
    },
    {
      field: "is_closed",
      headerName: "Plan Closed",
      valueGetter: (params) => params.row.is_closed ? "Yes" : "No",
      minWidth: 50,
    },
    {
      field: "autopay_status",
      headerName: "AutoPay Status",
      renderCell:(params)=>{
        return <div>
          {params.row.autopay_statu ? params.row.autopay_statu : "N/A"}
        </div>
      }
    },
    {
      field: "week_day",
      headerName: "Week Day",
      renderCell:(params)=>{
        return <div>
          {params.row.week_day ? params.row.week_day : "N/A"}
        </div>
      },
      minWidth: 100,
    },
    {
      field: "month_date",
      headerName: "Month Date",
      renderCell:(params)=>{
        return <div>
          {params.row.month_date ? params.row.month_date : "N/A"}
        </div>
      },
      minWidth: 100,
    },
    {
      field: "scheme_benefit",
      headerName: "Scheme Benefit",
      renderCell:(params)=>{
        return <div>
          {params.row.scheme_benefit ? params.row.scheme_benefit : "N/A"}
        </div>
      },
      minWidth: 100,
    },
    {
      field: "Transactions",
      headerName: "View Transactions",

      renderCell: ({ ...params }) => (
        // <RouterLink style={{textDecoration:"none"}} to={`/userSavingPlans/${params.row.id}/${params.row.phone_number}`}>
        <RouterLink
          to={`/egoldusersavingtransaction/${params.row.id}/${userId}/${phoneNumber}/${name}`}
        >
          <Button variant="outlined" color="success">
            View Transactions
          </Button>
        </RouterLink>
      ),
      minWidth: 200,
    },
  ];

  return (
    <>
      <Box m="20px">
        <Header
          title={`User ID: ${userId}`}
          subtitle={`Phone Number: ${phoneNumber}`}
        />
        <h3>Name: {name}</h3>
        <h4>Phone: {phoneNumber}</h4>

        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search using plan name or ID"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        <Box m="40px 0 0 0" height="65vh">

          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>

            <Box>
              <Select
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <MenuItem value="allPlans">Plan Status</MenuItem>
                <MenuItem value="activePlans">Active Plans</MenuItem>
                <MenuItem value="inactivePlans">Inactive Plans</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selectedOptionForTransaction}
                onChange={handleSelectChangeTransactions}
              >
                <MenuItem value="allTransaction">Transaction Types</MenuItem>
                <MenuItem value="quantity">Quantity</MenuItem>
                <MenuItem value="amount">Amount</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearTransactionFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selecteOptionForPlanType}
                onChange={handleSelectChangeForPlanType}
              >
                <MenuItem value="allPlanTypes">Plan Types</MenuItem>
                <MenuItem value="oneTime">ONE TIME</MenuItem>
                <MenuItem value="sip">SIP</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearPlanTypeFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selecteOptionForAutopayStatus}
                onChange={handleSelectChangeForAutopayStatus}
              >
                <MenuItem value="allAutopay">Autopay Status</MenuItem>
                <MenuItem value="activeAutopay">Active Autopay</MenuItem>
                <MenuItem value="pausedAutopay">Paused Autopay</MenuItem>
                <MenuItem value="cancelledAutopay">Cancelled Autopay</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearAutopayFilters}>X</Button>
              </Box>
            </Box>
            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>

          </Box>

          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                E-Gold Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/totalegoldusers"
              >
                E-Gold User List
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"
              >
                {name}
              </Link>
              {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important",
                },
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: "normal",
                  overflow: "visible",
                  wordWrap: "break-word",
                },
              }}
              rowHeight={75}
              rows={userSavingsPlan}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              getRowId={getRowId}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onCellClick={handleRowClick}

            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>Plan Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== 'Sl_No' && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseModal} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}

            {/* <button onClick={handlePreviousPage} disabled={!previousPage}>
                Previous Page
              </button>
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageClick(pageNumber)}
                  className={`page-button ${
                    pageNumber === page ? "active" : ""
                  }`}
                >
                  {pageNumber}
                </button>
              ))}
              <button onClick={handleNextPage} disabled={!nextPage}>
                Next Page
              </button> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EgoldUserSavings;