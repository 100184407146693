import { Box, Typography, IconButton, Button, Modal } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import authApi from "../../../api";
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport style={{ background: "#fff !important", margin: "5px" }} />
    </GridToolbarContainer>
  );
}
//   const today = dayjs();

const EgoldPlanList = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [egoldPlanList, setEgoldPlanList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activePlans, setactivePlans] = useState(false);
  const [notActivePlans, setnotActivePlans] = useState(false);
  const [allPlans, setallPlans] = useState(true);
  const [selectedOption, setSelectedOption] = useState('allPlans');
  const [selectedOptionForTransaction, setselectedOptionForTransaction] = useState('allTransaction');
  const [quantity, setQuantity] = useState(false);
  const [amount, setAmount] = useState(false);
  const [allTransaction, setAllTransactions] = useState(true);
  // filter for plan type ONE TIME/ SUBSCRIPTION
  const [selecteOptionForPlanType, setselecteOptionForPlanType] = useState('allPlanTypes');
  const [oneTime, setOneTime] = useState(false);
  const [sip, setSip] = useState(false);
  const [allPlanTypes, setAllPlanTypess] = useState(true);
  const [selecteOptionForAutopayStatus, setselecteOptionForAutopayStatus] = useState('allAutopay');
  const [allAutopayStatus, setAllAutopayStatus] = useState(true);
  const [activeAutopay, setActiveAutopay] = useState(false);
  const [pausedAutopay, setPausedAutopay] = useState(false);
  const [cancelledAutopay, setCancelledAutopay] = useState(false);
  const [planCreationDate, setPlanCreationDate] = useState(null);

  // breadcrumbs
  const handleBack = () => {
    navigate(-1);
  }


  //gets called on the search icon/button
  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchPlanListData(page, pageSize, searchInput);
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchPlanListData(page, pageSize, searchInput);
    }
  }

  //based on the input values as and when it changes you fetch the user data
  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };

  const handlePageSizeChange = (event) => {
    setPage(1); // Reset page to 1 when changing page size
    setPageSize(parseInt(event.target.value));
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  //calling the fetched API
  useEffect(() => {
    fetchPlanListData(page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate);
  }, [page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate]);

  //Fetching the API
  const fetchPlanListData = async (pageNumber, pageSize, searchQuery, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate) => {
    //pageNumber is our custom pagination we are using from server side, try and implement it in the default datagrid
    setLoading(true);

    //searchQuery helps us filter the user data based on the search input

    let apiUrl = `${base_url}/api/v1/ccapi/egold/v2/egold-plan?page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;

    if (startDate && endDate) {
      apiUrl += `&start_date=${startDate.format('YYYY-MM-DD')}&end_date=${endDate.format('YYYY-MM-DD')}`;
    }
    // if (planCreationDate) {
    //     apiUrl += `&plan_creation_date=${planCreationDate.format('YYYY-MM-DD')}`;
    //   }

    if (allPlans) {
      // If allPlans is true, don't add the is_active parameter to the apiUrl
    } else if (activePlans) {
      apiUrl += '&is_active=True';
    } else if (notActivePlans) {
      apiUrl += '&is_active=False';
    }

    if (allTransaction) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (quantity) {
      apiUrl += '&transaction_in=QUANTITY';
    } else if (amount) {
      apiUrl += '&transaction_in=AMOUNT';
    }

    if (allPlanTypes) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (oneTime) {
      apiUrl += '&plan_type=ONE TIME';
    } else if (sip) {
      apiUrl += '&plan_type=SUBSCRIPTION';
    }

    if (allAutopayStatus) {
      // If allTransaction is true, don't add the transaction_in parameter to the apiUrl
    } else if (activeAutopay) {
      apiUrl += '&autopay_status=ACTIVE';
    } else if (pausedAutopay) {
      apiUrl += '&autopay_status=PAUSE';
    } else if (cancelledAutopay) {
      apiUrl += '&autopay_status=CANCELLED';
    }

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //this is data state to fetch the total count of users
      setData(data);

      //this helps in the row count for both cutom pagination and datagrid row count
      setTotalRows(data.count);

      //this is to navigate to the next page using the custom navigation button
      setNextPage(data.next);

      //this is to navigate to the previous page using the custom navigation button
      setPreviousPage(data.previous);

      //this stores all the data of the users we need and displays it in the table
      setEgoldPlanList(data.results);
      //   console.log(data);
      //   console.log(data.results);


    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //next page navigation in pagination
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      // console.log(new URL(nextPage).searchParams.get("query"))
      if (pageNumber !== null) {
        setPage(parseInt(pageNumber));
      }
    }
  };

  //previous page navigation in pagination
  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyFilter = () => {
    // Call the API with the specified date range
    fetchPlanListData(page, pageSize, searchInput, startDate, endDate, activePlans, notActivePlans, allPlans, allTransaction, quantity, amount, allPlanTypes, oneTime, sip, activeAutopay, pausedAutopay, cancelledAutopay, allAutopayStatus, planCreationDate);
  };


  //   filter for plan status
  const handleCheckboxChangeForAllUser = () => {
    setallPlans(true);
    setactivePlans(false);
    setnotActivePlans(false);
  };

  const handleCheckboxChangeForactivePlansUser = () => {


    setnotActivePlans(false);
    setallPlans(false);
    setactivePlans(true);
  };

  const handleCheckboxChangeFornotActivePlansUser = () => {
    setactivePlans(false);
    setallPlans(false);
    setnotActivePlans(true);
  };

  //   filter for transaction in
  const handleSelectChangeForAllTransaction = () => {
    setAllTransactions(true);
    setQuantity(false);
    setQuantity(false);
  };

  const handleSelectChangeForQuantity = () => {
    setAllTransactions(false)
    setQuantity(true);
    setAmount(false);
  };

  const handleSelectChangeForAmount = () => {
    setQuantity(false);
    setAllTransactions(false);
    setAmount(true);
  };

  //   filter for plan type
  const handleSelectChangeForAllPlanTypes = () => {
    setAllPlanTypess(true);
    setOneTime(false);
    setSip(false);
  };

  const handleSelectChangeForOneTime = () => {
    setAllPlanTypess(false)
    setOneTime(true);
    setSip(false);
  };

  const handleSelectChangeForSip = () => {
    setOneTime(false);
    setAllPlanTypess(false);
    setSip(true);
  };

  //   filter for auto pay status
  const handleSelectChangeForAllAutopay = () => {
    setAllAutopayStatus(true);
    setActiveAutopay(false);
    setPausedAutopay(false);
    setCancelledAutopay(false);
  };

  const handleSelectChangeForActiveAutopay = () => {
    setActiveAutopay(true);
    setPausedAutopay(false);
    setCancelledAutopay(false);
    setAllAutopayStatus(false);
  };

  const handleSelectChangeForPausedAutopay = () => {
    setActiveAutopay(false);
    setPausedAutopay(true);
    setCancelledAutopay(false);
    setAllAutopayStatus(false);
  };

  const handleSelectChangeForCancelledAutopay = () => {
    setActiveAutopay(false);
    setPausedAutopay(false);
    setCancelledAutopay(true);
    setAllAutopayStatus(false);
  };

  //   filter for plan status
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);

    switch (event.target.value) {
      case 'allPlans':
        handleCheckboxChangeForAllUser();
        break;
      case 'activePlans':
        handleCheckboxChangeForactivePlansUser();
        break;
      case 'inactivePlans':
        handleCheckboxChangeFornotActivePlansUser();
        break;
      default:
        break;
    }
  };

  //   filter for transaction in
  const handleSelectChangeTransactions = (event) => {
    setselectedOptionForTransaction(event.target.value);

    switch (event.target.value) {
      case 'allTransaction':
        handleSelectChangeForAllTransaction();
        break;
      case 'quantity':
        handleSelectChangeForQuantity();
        break;
      case 'amount':
        handleSelectChangeForAmount();
        break;
      default:
        break;
    }
  };

  //   filter for plan type
  const handleSelectChangeForPlanType = (event) => {
    setselecteOptionForPlanType(event.target.value);

    switch (event.target.value) {
      case 'allPlanTypes':
        handleSelectChangeForAllPlanTypes();
        break;
      case 'oneTime':
        handleSelectChangeForOneTime();
        break;
      case 'sip':
        handleSelectChangeForSip();
        break;
      default:
        break;
    }
  };

  //   filter for auto pay status

  const handleSelectChangeForAutopayStatus = (event) => {
    setselecteOptionForAutopayStatus(event.target.value);

    switch (event.target.value) {
      case 'allAutopay':
        handleSelectChangeForAllAutopay();
        break;
      case 'activeAutopay':
        handleSelectChangeForActiveAutopay();
        break;
      case 'pausedAutopay':
        handleSelectChangeForPausedAutopay();
        break;
      case 'cancelledAutopay':
        handleSelectChangeForCancelledAutopay();
        break;
      default:
        break;
    }
  };

  //   plan creation date filter

  const handlePlanCreationDate = (date) => {
    setPlanCreationDate(date);
  };

  const clearFilters = () => {
    setSelectedOption('allPlans');
    setactivePlans(false);
    setnotActivePlans(false);
    setallPlans(true);
  };

  const clearTransactionFilters = () => {
    setselectedOptionForTransaction('allTransaction');
    setQuantity(false);
    setAmount(false);
    setAllTransactions(true);
  };

  const clearPlanTypeFilters = () => {
    setselecteOptionForPlanType('allPlanTypes');
    setOneTime(false);
    setSip(false);
    setAllPlanTypess(true);
  };

  const clearAutopayFilters = () => {
    setselecteOptionForAutopayStatus('allAutopay');
    setActiveAutopay(false);
    setPausedAutopay(false);
    setCancelledAutopay(false);
    setAllAutopayStatus(true);
  };
  //columns needed in the table
  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "Plan ID",
    },
    {
      field: "metal_type",
      headerName: "Metal Type"
    },
    // {
    //   field: "metal_quantity",
    //   headerName: "Metal Quantity",
    //   minWidth: 200,
    //   cellClassName: "name-column--cell",
    // },
    {
      field: "plan_type",
      headerName: "Plan Type",
      minWidth: 200,
    },
    {
      field: "subscription_type",
      headerName: "Subscription Type",
      minWidth: 80,
    },
    {
      field: "transaction_in",
      headerName: "Transaction In"
    },
    {
      field: "plan_amount",
      headerName: "Plan Amount"
    },
    {
      field: "activation_date",
      headerName: "Activation Date",
      renderCell: (params) => {
        const date = new Date(params.row.activation_date);
        if (date === null) {
          return <div>No date</div>; // Handle null date
        }

        return (
          <div>
            <div>Date: {date.toLocaleDateString()}</div>
            <div>Time: {date.toLocaleTimeString()}</div>
          </div>
        );
      },
    },
    {
      field: "is_active",
      headerName: "Plan Active",
      valueGetter: (params) => params.row.is_active ? "Yes" : "No",

    },
    {
      field: "is_closed",
      headerName: "Plan Closed",
      valueGetter: (params) => params.row.is_closed ? "Yes" : "No",
    },
    {
      field: "autopay_status",
      headerName: "AutoPay Status",
    },

    {
      field: "plan_for",
      headerName: "Plan For",
    },
    {
      field: "interest_rate",
      headerName: "Interest Rate",
    },
    {
      field: "week_day",
      headerName: "Week Day",
    },
    {
      field: "month_date",
      headerName: "Month Date",
    },

    {
      field: "purchased_quantity",
      headerName: "Purchased Quantity",
    },
    {
      field: "leased_quantity",
      headerName: "Leased Quantity",
    },
    {
      field: 'scheme',
      headerName: 'Scheme',
      width: 200,
      renderCell: (params) => {
        const schemee = params.row.scheme;
        if (schemee === null) {
          return <div>No Scheme</div>
        }

        return (
          <div>
            <div>{`ID: ${params.row.scheme.id}`}</div>
            <div>{`Name: ${params.row.scheme.name}`}</div>
            <div>{`ARP: ${params.row.scheme.arp}`}</div>
            <div>{`Benefit: ${params.row.scheme.benefit}`}</div>
          </div>
        );
      },
    },
    {
      field: 'plus_benefit',
      headerName: 'Plus Benefit',
      width: 150,
    },
    {
      field: 'scheme_benefit',
      headerName: 'Scheme Benefit',
      width: 150,
    },
    {
      field: 'lease_status',
      headerName: 'Lease Status',
      width: 150,
    },
    {
      field: 'Total Gold Quantity',
      headerName: 'Total Quantity',
      width: 150,
    },
    {
      field: 'Lock In Benefit',
      headerName: 'Lock-in Benefit',
      width: 150,
    },
    {
      field: 'Total Purchased Gold',
      headerName: 'Total Purchased Gold',
      width: 200,
    },
    {
      field: 'Total Withdrawn',
      headerName: 'Total Withdrawn',
      width: 150,
    }
  ];

  return (
    <>
      <Box m="20px">
        {/* Header */}
        <Box>
          <Header
            title="PLANS"
            subtitle={`Total Number of Plans: ${data.count}`}
          />
        </Box>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search name, phone number or email"
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={handleKeyDown}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        {/* DataTable */}

        <Box m="30px 0 0 0" height="75vh">
          <p>Filter by Date</p>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "5px" }}>

            <Box style={{ display: "flex", alignItems: "center" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                  sx={{ margin: "3px" }}
                  disableFuture
                  slotProps={{ field: { clearable: true } }}
                />
              </LocalizationProvider>
              <Button size="small" sx={{ margin: "3px" }} variant="contained" color="success" onClick={handleApplyFilter}>
                Apply
              </Button>
            </Box>

            <Box>
              <Select
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <MenuItem value="allPlans">Plan Status</MenuItem>
                <MenuItem value="activePlans">Active Plans</MenuItem>
                <MenuItem value="inactivePlans">Inactive Plans</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selectedOptionForTransaction}
                onChange={handleSelectChangeTransactions}
              >
                <MenuItem value="allTransaction">Transaction Types</MenuItem>
                <MenuItem value="quantity">Quantity</MenuItem>
                <MenuItem value="amount">Amount</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearTransactionFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selecteOptionForPlanType}
                onChange={handleSelectChangeForPlanType}
              >
                <MenuItem value="allPlanTypes">Plan Types</MenuItem>
                <MenuItem value="oneTime">ONE TIME</MenuItem>
                <MenuItem value="sip">SIP</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearPlanTypeFilters}>X</Button>
              </Box>
            </Box>

            <Box>
              <Select
                value={selecteOptionForAutopayStatus}
                onChange={handleSelectChangeForAutopayStatus}
              >
                <MenuItem value="allAutopay">Autopay Status</MenuItem>
                <MenuItem value="activeAutopay">Active Autopay</MenuItem>
                <MenuItem value="pausedAutopay">Paused Autopay</MenuItem>
                <MenuItem value="cancelledAutopay">Cancelled Autopay</MenuItem>
              </Select>
              <Box display={"flex"} m={"2px"} justifyContent={"center"} alignContent={"center"}>
                <Button size="small" sx={{ padding: "0" }} variant="contained" color="success" onClick={clearAutopayFilters}>X</Button>
              </Box>
            </Box>
            <Box marginLeft={"20px"} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <p>Page Size</p>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={totalRows}>All</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Box>

          </Box>


          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/home"
              >
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="/goldDashboard"
              >
                E-Gold Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to="#"

              >
                E-Gold Plan List
              </Link>
            </Breadcrumbs>
          </div>
          <Box
            height="75vh"
            width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .css-1a2dy5t-MuiButtonBase-root-MuiButton-root": {
                background: `#fff !important`,
                margin: "5px"
              },
              marginTop: "5px",
            }}
          >
            <DataGrid
              rowHeight={60}
              rows={egoldPlanList}
              columns={columns}
              loading={loading}
              pagination
              page={page}
              onPageChange={handlePageChange}
              pageSize={pageSize}
              rowCount={totalRows}
              hideFooterPagination={true}
              onRowClick={handleRowClick}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
          <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              {selectedRow && (
                <Box>
                  <table>
                    <tbody>
                      {columns.map((column) => (
                        <tr key={column.field}>
                          {column.field !== 'Sl_No' && ( // Exclude column 1
                            <>
                              <th>{column.headerName}</th>
                              <td>
                                {column.renderCell
                                  ? column.renderCell({ row: selectedRow })
                                  : column.valueGetter
                                    ? column.valueGetter({ row: selectedRow })
                                    : selectedRow[column.field]}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCloseModal} color="warning">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Pagination Buttons */}
          <Box
            display={"flex"}
            m={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {generatePageButtons()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EgoldPlanList;