import { Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useDemoData } from "@mui/x-data-grid-generator";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../../utils/authUtils";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

const EgoldAugmontTransactionsInUserTransactions = () => {
  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //state in which we can see transactions done based on the plan id/ plan
  const { planId, userId, name, phoneNumber } = useParams();
  const [savingPlanTransactions, setSavingPlanTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [savingPlanName, setSavingPlanName] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const base_url = "https://proapi.getplus.in";
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  useEffect(() => {
    fetchSavingPlanTransactions(planId, userId, page, pageSize);
  }, [planId, userId, page, pageSize]);

  const fetchSavingPlanTransactions = async (planId, userId, pageNumber, pageSize) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/egold/v2/augmont-transaction?plan_id=${planId}&page=${pageNumber}&page_size=${pageSize}`;

    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/')
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setSavingPlanTransactions(data.results);
      setTotalRows(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
      // console.log(data);
      const result = data.results;
      //   setSavingPlanTransactionsId(data.results.saving_plan_id);
      result.forEach((item) => {
        // console.log(item.saving_plan_name);
        setSavingPlanName(item.saving_plan_name);
      });
    } catch (error) {
      console.error("Error fetching user transactions", error);
    } finally {
      setLoading(false);
    }
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      setPage(parseInt(pageNumber));
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
    pageNumbers.push(i);
  }

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: 'augmont_transactions',
      headerName: 'Transaction ID',
      valueGetter: (params) => {
        const transactions = params.row.augmont_transactions.map(transaction => (
          `${transaction.id}`
        ));
        return transactions.join('\n');
      }
    },
    {
      field: 'metal_type',
      headerName: 'Metal Type',
      valueGetter: (params) => {
        const transactions = params.row.augmont_transactions.map(transaction => (
          ` ${transaction.metal_type}`
        ));
        return transactions.join('\n');
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => {
        const transactions = params.row.augmont_transactions.map(transaction => (
          `${transaction.amount}`
        ));
        return transactions.join('\n');
      }
    },
    {
      field: 'metal_quantity',
      headerName: 'Metal Quantity',
      valueGetter: (params) => {
        const transactions = params.row.augmont_transactions.map(transaction => (
          `${transaction.metal_quantity}`
        ));
        return transactions.join('\n');
      }
    },
    {
      field: 'transaction_type',
      headerName: 'Transaction Type',
      valueGetter: (params) => {
        const transactions = params.row.augmont_transactions.map(transaction => (
          `${transaction.transaction_type}`
        ));
        return transactions.join('\n');
      }
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      renderCell: (params) => {
        const transactions = params.row.augmont_transactions.map(transaction => {
          const dateObject = new Date(transaction.created_at);
          const date = dateObject.toISOString().split("T")[0];
          const time = dateObject.toTimeString().split(" ")[0];
          return (
            <div key={transaction.created_at}>
              <div>Date: {date}</div>
              <div>Time: {time}</div>
            </div>
          );
        });
        return transactions;
      }
    },
  ];

  if (savingPlanTransactions.length === 0) {
    return (
      <Box m="20px">
        <Header
          title={`User ID: ${userId}`}
          subtitle={`Phone Number: ${phoneNumber}`}
        />
        <h3>Name: {name}</h3>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="/dashboard"
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="/users"
            >
              User List
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to={`/userSavingPlans/${userId}/${phoneNumber}/${name}`}
            >
              {name}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="#"
            >
              {savingPlanName}
            </Link>
            {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
          </Breadcrumbs>
        </div>
        No Transactions Done Yet.
      </Box>
    );
  } else {
    return (
      <>
        <Box m="20px">
          <Header
            title={`User ID: ${userId}`}
            subtitle={`Saving Plan ID: ${planId}`}
          />
          <h3>Name: {name}</h3>
          <h4>Phone Number: {phoneNumber}</h4>
          <Box m="40px 0 0 0">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to="/dashboard"
                >
                  Dashboard
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to="/users"
                >
                  User List
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to={`/userSavingPlans/${userId}/${phoneNumber}/${name}`}
                >
                  {name}
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to="#"
                >
                  {savingPlanName}
                </Link>
                {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
              </Breadcrumbs>
            </div>

            <Box
              height="75vh"
              width="100%"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                    height: "unset !important",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    // Forced to use important since overriding inline styles
                    maxHeight: "168px !important",
                  },
                  "& .MuiDataGrid-cellContent": {
                    whiteSpace: "normal",
                    overflow: "visible",
                    wordWrap: "break-word",
                  },
                }}
                rowHeight={75}
                rows={savingPlanTransactions}
                columns={columns}
                loading={loading}
                page={page}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                rowCount={totalRows}
                hideFooterPagination={true}
                onCellClick={handleRowClick}

              />
            </Box>
            <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
              <DialogTitle>Transaction Details</DialogTitle>
              <DialogContent>
                {selectedRow && (
                  <Box>
                    <table>
                      <tbody>
                        {columns.map((column) => (
                          <tr key={column.field}>
                            {column.field !== 'Sl_No' && ( // Exclude column 1
                              <>
                                <th>{column.headerName}</th>
                                <td>
                                  {column.renderCell
                                    ? column.renderCell({ row: selectedRow })
                                    : column.valueGetter
                                      ? column.valueGetter({ row: selectedRow })
                                      : selectedRow[column.field]}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={handleCloseModal} color="warning">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Box
              display={"flex"}
              m={"10px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {generatePageButtons()}
            </Box>
          </Box>
        </Box>
      </>
    );
  }
};

export default EgoldAugmontTransactionsInUserTransactions;