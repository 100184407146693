import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";
import Header from "../../components/Header";
// Use These if needed in future
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { getAccessToken } from "../../utils/authUtils";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import UserTransactions from "../TotalUsers/UserTransactions";
import authApi from "../../api";

const Dashboard = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Base API URL
  const base_url = "https://proapi.getplus.in";
  // Need to make the access token Dynamic so we dont have to manually change it everytime
  // const access_token = getAccessToken();

  // State to set the data fetched from the API on UI
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalSip, setTotalSip] = useState([]);
  const [totaGoldCoinOrders, setTotalGoldCoinOrders] = useState([]);
  const [totalKycCompleted, setTotalKycCompleted] = useState([]);
  const [totalContests, setTotalContests] = useState([]);
  const [totalWithdrawals, setTotalWithdrawals] = useState([]);
  const [totalUpcomingDeposits, setTotalUpcomingDeposits] = useState([]);

  const navigate = useNavigate();
  // Callback will be called on every re-render. The useEffect() hook is used to run a side-effect, every time the component’s state changes. It takes a callback function as its first argument. This callback function will get called when the state of the component is changed.



  //This is to fetch the total number of the user count
  useEffect(() => {
    fetchTotalUserCount();
    fetchTotalSips();
    fetchTotalGoldCoinOrders();
    fetchTotalKycCompleted();
    fetchTotalContests();
    fetchTotalUpcomingDeposits();
  }, []);
  //This is to fetch the total number of the user count
  // dynamic api call function 


  const fetchTotalUserCount = async () => {
    try {
      const data = await authApi.totalUserCount();
      setTotalUsers(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  };
  //This is to fetch the total SIP Money
  const fetchTotalSips = async () => {
    try {
      const data = await authApi.totalSips();
      setTotalSip(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };
  const fetchTotalGoldCoinOrders = async () => {
    try {
      const data = await authApi.totalGoldCoinOrders();
      setTotalGoldCoinOrders(data.total);
    } catch (error) {
      // console.log("Error fetching data:", error);
      navigate('/');
    }
  };
  const fetchTotalKycCompleted = async () => {
    try {
      const data = await authApi.totalKycCompleted();
      setTotalKycCompleted(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalContests = async () => {

    try {
      const data = await authApi.totalContests();
      setTotalContests(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  }
  const fetchTotalUpcomingDeposits = async () => {
    try {
      const data = await authApi.totalUpcomingDeposits();
      setTotalUpcomingDeposits(data.instalments);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchTotalWithdrawals();
  }, []);

  const fetchTotalWithdrawals = async () => {
    const apiUrl = `${base_url}/api/v1/ccapi/withdrawal/total-withdrawal-requests`;
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");

        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setTotalWithdrawals(data.total);

    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  }


  return (
    <>
      <Box margin="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="P2P DASHBOARD" subtitle="Welcome to your P2P Dashboard" />
        </Box>

        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" component={RouterLink} to={"/home"}>
              Home
            </Link>
            <Link underline="hover" color="inherit" component={RouterLink} to={"#"}>
              Dashboard
            </Link>
          </Breadcrumbs>
        </div>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/users"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {/* Total count of Users Displayed here */}
                    <h2 style={{ textAlign: "center" }}>{totalUsers}</h2>
                    <h3 style={{ textAlign: "center" }}>Total Users</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Users</Button>
                    </div>
                  </div>

                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/totalSipUsers"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>INR {totalSip}</h2>
                    <h3 style={{ textAlign: "center" }}>Total&nbsp;SIP</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Active Investors</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/upcomingdeposits"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalUpcomingDeposits}</h2>
                    <h3 style={{ textAlign: "center" }}>
                      Upcoming&nbsp;Deposits
                    </h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Users Upcoming Deposits</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/team"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>5,000</h2>
                    <h3 style={{ textAlign: "center" }}>
                      Missed&nbsp;Deposits
                    </h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Users Missed Deposits</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/goldcoinorders"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>
                      {totaGoldCoinOrders}
                    </h2>
                    <h3 style={{ textAlign: "center" }}>
                      Gold&nbsp;Coin&nbsp;Orders
                    </h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Gold Coin Orders</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/kycDetails"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalKycCompleted}</h2>
                    <h3 style={{ textAlign: "center" }}>KYC&nbsp;Status</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">Kyc Users</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/team"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>100</h2>
                    <h3 style={{ textAlign: "center" }}>Agents</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Agents</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/contestList"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalContests}</h2>
                    <h3 style={{ textAlign: "center" }}>Contests</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Contests</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/withdrawal"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalWithdrawals}</h2>
                    <h3 style={{ textAlign: "center" }}>Total&nbsp;Withdrawals</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Withdrawals</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;


//misc

// const Item = styled(Paper)(({ theme }) => ({
//   //   backgroundColor: theme.palette.mode === 'dark' ? '#888' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));