import { Box, Typography, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const UserSavingPlans = () => {

  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { userId, phoneNumber, name } = useParams();
  const [userSavingsPlan, setuserSavingsPlan] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Prepping to fetch the API

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  const handleSearch = () => {
    // Make an API request to filter data based on the search input
    setPage(1);
    fetchUserSavingsPlan(userId, page, pageSize, searchInput);
  };

  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchInput(e.target.value);
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchUserSavingsPlan(userId, page, pageSize, searchInput);
  }, [userId, page, pageSize, searchInput]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchUserSavingsPlan = async (userId, pageNumber, pageSize, searchQuery) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/saving-plan-list?investor_id=${userId}&page=${pageNumber}&page_size=${pageSize}&query=${searchQuery}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setuserSavingsPlan(data.results); //only the list of saving plans
      // console.log(data); //all the data from the api
      setTotalRows(data.count);
      setNextPage(data.next);
      setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (nextPage) {
      const pageNumber = new URL(nextPage).searchParams.get("page");
      setPage(parseInt(pageNumber));
    }
  };

  const handlePreviousPage = () => {
    if (previousPage) {
      let pageNumber = 1; // Default to page 1 if "previous" URL is missing "page" parameter
      const urlSearchParams = new URLSearchParams(previousPage);

      if (urlSearchParams.has("page")) {
        pageNumber = parseInt(urlSearchParams.get("page"));
      }

      setPage(pageNumber);
    }
  };

  const generatePageButtons = () => {
    const maxButtons = 10; // Maximum number of buttons to display
    const buttons = [];
    const startPage = Math.max(1, page - Math.floor(maxButtons / 2));
    const endPage = Math.min(
      Math.ceil(totalRows / pageSize),
      startPage + maxButtons - 1
    );

    // Generate the "Previous Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="previous"
        onClick={handlePreviousPage}
        disabled={!previousPage}
      >
        Previous Page
      </button>
    );

    // Add the first page button
    if (startPage > 1) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={1}
          onClick={() => handlePageClick(1)}
        >
          1
        </button>
      );
      if (startPage > 2) {
        // Add ellipsis if there are pages between the first page and startPage
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Generate page number buttons
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-button ${i === page ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }

    // Add the last page button
    if (endPage < Math.ceil(totalRows / pageSize)) {
      if (endPage < Math.ceil(totalRows / pageSize) - 1) {
        // Add ellipsis if there are pages between endPage and the last page
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button
          style={{ padding: "5px", borderRadius: "5px" }}
          key={Math.ceil(totalRows / pageSize)}
          onClick={() => handlePageClick(Math.ceil(totalRows / pageSize))}
        >
          {Math.ceil(totalRows / pageSize)}
        </button>
      );
    }

    // Generate the "Next Page" button
    buttons.push(
      <button
        style={{ padding: "5px", borderRadius: "5px" }}
        key="next"
        onClick={handleNextPage}
        disabled={!nextPage}
      >
        Next Page
      </button>
    );

    return buttons;
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / pageSize); i++) {
    pageNumbers.push(i);
  }

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "plan_id",
      headerName: "Plan ID",
      minWidth: 250,
    },
    {
      field: "plan_name",
      headerName: "Plan Name",
      cellClassName: "name-column--cell",
      minWidth: 250,
    },
    {
      field: "saving_type",
      headerName: "Saving Type",
      minWidth: 50,
    },
    // {
    //   field: "plan_type",
    //   headerName: "Plan Type",
    //   minWidth: 50,
    // },
    {
      field: "duration_of_saving_in_months",
      headerName: "Duration",
      minWidth: 100,
    },
    {
      field: "type_of_investment",
      headerName: "Investment Type",
      minWidth: 80,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 150,
    },
    {
      field: "available_amount",
      headerName: "Available Amount",
      valueGetter: (params) => params.row.available_amount ? params.row.available_amount : "0",
      minWidth: 150,
    },
    {
      field: "earned_interest",
      headerName: "Interest Earned",
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 150,
    },
    {
      field: "is_active",
      headerName: "Plan Active",
      valueGetter: (params) => params.row.is_active ? "Yes" : "No",
      minWidth: 100,
    },
    {
      field: "is_closed",
      headerName: "Plan Closed",
      valueGetter: (params) => params.row.is_closed ? "Yes" : "No",
      minWidth: 100,
    },
    {
      field: "is_draft",
      headerName: "Plan Draft",
      valueGetter: (params) => params.row.is_draft ? "Yes" : "No",
      minWidth: 100,
    },
    {
      field: "Transactions",
      headerName: "View Transactions",

      renderCell: ({ ...params }) => (
        // <RouterLink style={{textDecoration:"none"}} to={`/userSavingPlans/${params.row.id}/${params.row.phone_number}`}>
        <RouterLink
          to={`/userTransactions/${params.row.plan_id}/${userId}/${phoneNumber}/${name}`}
        >
          <Button variant="outlined" color="success">
            View Transactions
          </Button>
        </RouterLink>
      ),
      minWidth: 200,
    },
  ];

  if (userSavingsPlan.length === 0) {
    return (
      <Box m="20px">
        <Header
          title={`User ID: ${userId}`}
          subtitle={`Phone Number: ${phoneNumber}`}
        />
        <h3>Name: {name}</h3>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="/dashboard"
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="/users"
            >
              User List
            </Link>
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to="#"
            >
              {name}
            </Link>
            {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
          </Breadcrumbs>
        </div>
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search using plan name or ID"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
        No Plans Created.
      </Box>
    );
  } else
    return (
      <>
        <Box m="20px">
          <Header
            title={`User ID: ${userId}`}
            subtitle={`Phone Number: ${phoneNumber}`}
          />
          <h3>Name: {name}</h3>
          <h4>Phone: {phoneNumber}</h4>

          {/* SEARCH BAR */}
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
          >
            <InputBase
              sx={{ ml: 2, flex: 1 }}
              placeholder="Search using plan name or ID"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <IconButton type="button" sx={{ p: 1 }} onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </Box>
          <Box m="40px 0 0 0" height="65vh">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to="/dashboard"
                >
                  Dashboard
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to="/users"
                >
                  User List
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to="#"
                >
                  {name}
                </Link>
                {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
              </Breadcrumbs>
            </div>
            <Box
              height="75vh"
              width="100%"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                    height: "unset !important",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    // Forced to use important since overriding inline styles
                    maxHeight: "168px !important",
                  },
                  "& .MuiDataGrid-cellContent": {
                    whiteSpace: "normal",
                    overflow: "visible",
                    wordWrap: "break-word",
                  },
                }}
                rowHeight={75}
                rows={userSavingsPlan}
                columns={columns}
                loading={loading}
                pagination
                page={page}
                getRowId={(row) => row.plan_id}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                rowCount={totalRows}
                hideFooterPagination={true}
                onCellClick={handleRowClick}

              />
            </Box>
            <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
              <DialogTitle>Plan Details</DialogTitle>
              <DialogContent>
                {selectedRow && (
                  <Box>
                    <table>
                      <tbody>
                        {columns.map((column) => (
                          <tr key={column.field}>
                            {column.field !== 'Sl_No' && ( // Exclude column 1
                              <>
                                <th>{column.headerName}</th>
                                <td>
                                  {column.renderCell
                                    ? column.renderCell({ row: selectedRow })
                                    : column.valueGetter
                                      ? column.valueGetter({ row: selectedRow })
                                      : selectedRow[column.field]}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={handleCloseModal} color="warning">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Box
              display={"flex"}
              m={"10px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {generatePageButtons()}

              {/* <button onClick={handlePreviousPage} disabled={!previousPage}>
                Previous Page
              </button>
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageClick(pageNumber)}
                  className={`page-button ${
                    pageNumber === page ? "active" : ""
                  }`}
                >
                  {pageNumber}
                </button>
              ))}
              <button onClick={handleNextPage} disabled={!nextPage}>
                Next Page
              </button> */}
            </Box>
          </Box>
        </Box>
      </>
    );
};

export default UserSavingPlans;

//misc code

// {
// field:"access",
// headerName:"Access",
//
// renderCell: ({row: {access}}) => {
//     return (
//         <Box
//         width="60%"
//         m="0 auto"
//         p="5px"
//         display="flex"
//         justifyContent="center"
//         backgroundColor = {
//             access === "admin"
//             ? colors.greenAccent[600]
//             :colors.greenAccent[700]

//         }
//         borderRadius="4px"
//         >
//             {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
//             {access === "manager" && <SecurityOutlinedIcon />}
//             {access === "user" && <LockOpenOutlinedIcon />}
//             <Typography color={colors.grey[100]} sx={{marginLeft:"5px"}}>
//                 {access}
//             </Typography>
//         </Box>
//     )
// }

// },
