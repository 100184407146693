import axios from "axios";

const BASE_URL = "https://proapi.getplus.in";
const BASE_URL_TEST = "https://proapi.getplus.in";

const authToken = async () => {
  const auth = await localStorage.getItem("auth");
  const { token } = !!auth ? JSON.parse(auth) : {};
  return token
}
let totalEgoldUsersData;

const post = async (url, token, data = {}, _config = {}) => {
  const config = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return axios
    .post(url, data, { ...config })
    .then((res) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }) => {
      return response;
    });
};

const put = async (url, token, data = {}, _config = {}) => {
  const config = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return axios
    .put(url, data, { ...config })
    .then((res) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        return response.data;
      }
      return response;
    });
};

const get = async (url, token, _config = {}) => {
  const config = { ..._config };
  if (token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return await axios.get(url, { ...config }).then((res) => res.data);
};



const authApi = {
  logIn: async (params) => {
    try {
      // const res = await post(`${BASE_URL}/api/v1/verification-otp/`, null, params);
      const res = await post(`${BASE_URL}/api/v2/login-register-otp-verification`, null, params);
      if (res?.status === 400) {
        // console.log(res.status)
        return {
          error: true,
          message: [res.data.message],
        };
      } else if (res?.statusCode === 401) {
        return {
          error: true,
          message: ["Incorrect Email Address or password."],
        };
      } else if (res?.statusCode === 403) {
        return {
          error: true,
          message: ["Access Denied! Please Reach Out To Customer Care"],
        };
      }
      return res.data;
    } catch (e) {
      // console.log(e)
      return {
        error: true,
        message: e,
      };
    }
  },

  getOtp: async (params) => {
    // return await post(`${BASE_URL}/api/v1/login/`, null, params)
    return await post(`${BASE_URL}/api/v1/ccapi/agents/login`, null, params)
  },

  getDownloadLogs: async (params) => {
    return await post(`${BASE_URL}/api/v1/ccapi/agents/data-download-logs`, await authToken(), params)
  },


  sendReminder: async (params) => {
    return await post(`${BASE_URL}/api/v1/ccapi/gold-coin/send-cart-pickup-reminder`, await authToken(), params)
  },

  totalUserCount: async () => {
    return await get(`${BASE_URL}/api/v1/ccapi/user-count`, await authToken())
  },
  // check from here.............. 



  totalSips: async () => {
    return await get(`${BASE_URL}/api/v1/ccapi/total-sip-aum`, await authToken())
  },

  totalGoldCoinOrders: async () => {
    return await get(`${BASE_URL}/api/v1/ccapi/gold-coin/cart-count`, await authToken())
  },

  totalKycCompleted: async () => {
    return await get(`${BASE_URL}/api/v1/ccapi/kyc/total-kyc-count`, await authToken())
  },

  totalContests: async () => {
    return await get(`${BASE_URL}/api/v1/ccapi/contest/contest-count`, await authToken())
  },

  totalUpcomingDeposits: async () => {
    return await get(`${BASE_URL}/api/v1/ccapi/upcoming-deposits/total-upcoming-deposit`, await authToken())
  },

  // Egold APIS



  // totalUserCount:  async () =>{
  //   return await get (`${BASE_URL}/api/v1/ccapi/user-count`,await authToken())
  // },

  //   emailOtpVerification: async (params) =>{
  //     return await post (`${BASE_URL}/api/v1/verfiy-email-verification-otp`,await authToken(),params)
  //   },

  //   aadharVerification: async (params) =>{
  //     return await post (`${BASE_URL}/api/v1/verify-aadhar-card`,await authToken(),params)
  //   },

  //   panVerification: async (params) =>{
  //     return await post (`${BASE_URL}/api/v1/upload-pan-card`,await authToken(),params)
  //   },

  //   lbuserverification:  async () =>{
  //     return await post (`${BASE_URL}/api/v1/user-lendbox-register`,await authToken())
  //   },

  //   getUserList:  async () =>{
  //     return await get (`${BASE_URL}/api/v1/ccapi/investor-list?page=${pageNumber}&query=${searchQuery}`,await authToken())
  //   },

  //   payment:  async (params) =>{
  //     return await post (`${BASE_URL}/api/v1/periodic_plan_seamless/`,await authToken(),params)
  //   },



}

export const totalEgoldUserCountApi = async () => {
  // If data is already fetched, return it instead of making a new API call
  if (totalEgoldUsersData) {
    return totalEgoldUsersData;
  }

  // If data is not fetched, make the API call and store the result
  try {
    totalEgoldUsersData = await get(`${BASE_URL}/api/v1/ccapi/egold/v2/augmont-user/count`, await authToken());
    return totalEgoldUsersData;
  } catch (error) {
    console.error("Error fetching total eGold users count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalEgoldPlanCountApi = async () => {
  // If data is already fetched, return it instead of making a new API call

  // If data is not fetched, make the API call and store the result
  try {
    const totalPlanUsersData = await get(`${BASE_URL}/api/v1/ccapi/egold/egold-plan`, await authToken());
    return totalPlanUsersData;
  } catch (error) {
    console.error("Error fetching total eGold plans count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalEgoldBankDetailsCountApi = async () => {
  // If data is already fetched, return it instead of making a new API call

  // If data is not fetched, make the API call and store the result
  try {
    const totalEgoldBankDetailsCountData = await get(`${BASE_URL}/api/v1/ccapi/egold/augmont-bank-detail/count`, await authToken());
    return totalEgoldBankDetailsCountData;
  } catch (error) {
    console.error("Error fetching total eGold bank details count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalEgoldWithdrawalCountApi = async () => {
  // If data is already fetched, return it instead of making a new API call

  // If data is not fetched, make the API call and store the result
  try {
    const totalEgoldWithdrawalCountData = await get(`${BASE_URL}/api/v1/ccapi/egold/v2/egold-cash-withdrawal/count`, await authToken());
    return totalEgoldWithdrawalCountData;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalEgoldAugmontProductOrdersCountApi = async () => {
  // If data is already fetched, return it instead of making a new API call

  // If data is not fetched, make the API call and store the result
  try {
    // /api/v1/ccapi/egold/augmont-product-orders/count
    const totalEgoldAugmontProductCountData = await get(`${BASE_URL}/api/v1/ccapi/egold/augmont-product-orders/count`, await authToken());
    return totalEgoldAugmontProductCountData;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalEgoldAugmontTransactionCountApi = async () => {
  // If data is already fetched, return it instead of making a new API call

  // If data is not fetched, make the API call and store the result
  try {
    // /api/v1/ccapi/egold/augmont-product-orders/count
    const totalEgoldAugmontTransactionCountData = await get(`${BASE_URL}/api/v1/ccapi/egold/augmont-transaction/count`, await authToken());
    return totalEgoldAugmontTransactionCountData;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalEgoldUserTransactionCountApi = async () => {

  try {

    const totalEgoldUserTransactionCountData = await get(`${BASE_URL}/api/v1/ccapi/egold/user-transaction/count`, await authToken());
    return totalEgoldUserTransactionCountData;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalEgoldAUMCountApi = async () => {

  try {

    const totalEgoldAUMCountData = await get(`${BASE_URL}/api/v1/ccapi/egold/egold-plan/sip-aum-total`, await authToken());
    return totalEgoldAUMCountData;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}
// export const totalWithdrawalCountApi = async 

export const totalUPIDetails = async () => {
  try {
    const totalUPIDetailsCount = await get(`${BASE_URL}/api/v1/ccapi/egold/v2/augmont-upi-detail/count`, await authToken());
    return totalUPIDetailsCount;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalRewardCount = async () => {
  try {
    const totalRewardListCount = await get(`${BASE_URL}/api/v1/ccapi/egold/v2/rewards/count`, await authToken());
    return totalRewardListCount;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export const totalReferAndEarnCount = async () => {
  try {
    const totalReferAndEarnListCount = await get(`${BASE_URL}/api/v1/ccapi/egold/v2/refer-and-earn/count`, await authToken());
    return totalReferAndEarnListCount;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}



export const totalContestsCount = async () => {
  try {
    const totalContestsListCount = await get(`${BASE_URL}/api/v1/ccapi/egold/v2/contest/count`, await authToken());
    return totalContestsListCount;
  } catch (error) {
    console.error("Error fetching total eGold withdrawal count:", error);
    throw error; // Rethrow the error to handle it where the function is called
  }
}

export default authApi;


