import { Box, Typography, Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { DataGrid } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { getAccessToken } from "../../tokenService";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { getAccessToken } from "../../utils/authUtils";

const GoldCoinJewellerStoreDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { userId, cartId } = useParams();
  const [jewellerStoreDetails, setJewellerStoreDetails] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token = getAccessToken();
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    fetchJewellerStoreDetails(userId);
  }, [userId]);

  const fetchJewellerStoreDetails = async (userId) => {
    const apiUrl = `${base_url}/api/v1/ccapi/gold-coin/cart-list?investor_id=${userId}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate(`/`);
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl,
        {
          method: "GET",
          headers,
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      //   setGoldCoinOrderedUserList(data);
      // console.log(data);
      // console.log(data.results);
      setJewellerStoreDetails(data.results);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "Store ID",
      valueGetter: (params) => params.row.jeweller_store
        .id,
      minWidth: 150
    },
    {
      field: "store_name",
      headerName: "Store Name",
      valueGetter: (params) => params.row.jeweller_store
        .store_name,
      minWidth: 200
    },
    {
      field: "contact_number",
      headerName: "Contact Number",
      valueGetter: (params) => params.row.jeweller_store
        .contact_number,
      minWidth: 150
    },
    {
      field: "city",
      headerName: "City",
      valueGetter: (params) => params.row.jeweller_store
        .city,
      minWidth: 150
    },
    {
      field: "area",
      headerName: "Area",
      valueGetter: (params) => params.row.jeweller_store
        .area,
      minWidth: 150
    },
    {
      field: "address",
      headerName: "Address",
      valueGetter: (params) => params.row.jeweller_store
        .address,
      minWidth: 200
    },
  ]

  return (
    <>
      <Box m="20px">
        <Header
          title="Jeweller Store"
        />
        <Box m="40px 0 0 0" >


          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="/goldcoinorders">
                Gold Coin Orders
              </Link>
              <Link underline="hover" color="inherit" component={RouterLink} to="#">
                Cart ID: {cartId}
              </Link>
            </Breadcrumbs>
          </div>
          <Box height="75vh" width="100%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none"
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{
                "& .MuiDataGrid-cellContent": {
                  whiteSpace: 'normal', overflow: 'visible', wordWrap: 'break-word'
                },
              }}
              rowHeight={75}
              rows={jewellerStoreDetails}
              columns={columns}
              hideFooterPagination={true}
              onCellClick={handleRowClick}

            />
            <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
              <DialogTitle>User Details</DialogTitle>
              <DialogContent>
                {selectedRow && (
                  <div>
                    {columns.map(column => (
                      <div key={column.field}>
                        {column.headerName}: {column.valueGetter({ row: selectedRow })}
                      </div>
                    ))}
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

          </Box>
        </Box>
      </Box>
    </>
  )
}

export default GoldCoinJewellerStoreDetails;