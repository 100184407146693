import { Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const KycUserLogs = () => {

  //for the dashboard theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { userId, phoneNumber } = useParams();
  const [kycUserAadhaarLogs, setkycUserAadhaarLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //Prepping to fetch the API

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token = getAccessToken();
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchUserKycDetails(userId);
  }, [userId]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchUserKycDetails = async (userId) => {
    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/kyc/investor-kyc-logs?investor_id=${userId}`;
    try {

      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/')
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setkycUserAadhaarLogs(data.aadhar_card); //only the list of saving plans
      // console.log(data.aadhar_card); //all the data from the api
      //   setTotalRows(data.count);
      //   setNextPage(data.next);
      //   setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {
      field: "id",
      headerName: "Aadhar ID",
      minWidth: 150,
    },
    {
      field: "aadhar_number",
      headerName: "Aadhar Number",
      minWidth: 150,
    },
    // {
    //   field: "api_response_parsed",
    //   headerName: "API Response",
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
    //       const apiResponse = params.row.api_response_parsed;

    //       if (Object.keys(apiResponse).length === 0) {
    //         // If the object is empty, display a message for an empty response
    //         return <div>No Data</div>;
    //       } else if (apiResponse.error) {
    //         // If "error" key is present, display the error
    //         return <div>{apiResponse.error}</div>;
    //       } else if (apiResponse.data) {
    //         // If "data" key is present, display the data
    //         return (
    //           <div>
    //             <p>Client ID: {apiResponse.data.client_id}</p>
    //             <p>Age Range: {apiResponse.data.age_range}</p>
    //             {/* Add more properties as needed */}
    //           </div>
    //         );
    //       } else {
    //         // Handle other cases as needed
    //         return <div>Unrecognized API Response</div>;
    //       }
    //     } else {
    //       // Handle cases where api_response_parsed is undefined or not an object
    //       return <div>No API Response</div>;
    //     }
    //   },
    // },
    {
      field: "api_response_parsed_StatusCode",
      headerName: "Status Code",
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const apiResponse = params.row.api_response_parsed;

          if (Object.keys(apiResponse).length === 0) {
            // If the object is empty, display a message for an empty response
            return <div>No Data</div>;
          } else if (apiResponse.error) {
            // If "error" key is present, display the error
            return <div>{apiResponse.error}</div>;
          } else if (apiResponse) {
            // If "data" key is present, display the data
            return (
              <div>
                <p>{apiResponse.status_code}</p>
                {/* <p>Age Range: {apiResponse.data.age_range}</p> */}
                {/* Add more properties as needed */}
              </div>
            );
          } else {
            // Handle other cases as needed
            return <div>Unrecognized API Response</div>;
          }
        } else {
          // Handle cases where api_response_parsed is undefined or not an object
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api_response_parsed_state last digit",
      headerName: "Last Digits",
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const apiResponse = params.row.api_response_parsed;

          if (Object.keys(apiResponse).length === 0) {
            // If the object is empty, display a message for an empty response
            return <div>No Data</div>;
          } else if (apiResponse.error) {
            // If "error" key is present, display the error
            return <div>{apiResponse.error}</div>;
          } else if (apiResponse.data) {
            // If "data" key is present, display the data
            return (
              <div>
                <p>{apiResponse.data.last_digits}</p>
                {/* <p>Age Range: {apiResponse.data.age_range}</p> */}
                {/* Add more properties as needed */}
              </div>
            );
          } else {
            // Handle other cases as needed
            return <div>Unrecognized API Response</div>;
          }
        } else {
          // Handle cases where api_response_parsed is undefined or not an object
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api_response_parsed_state_age_Range",
      headerName: "Age Range",
      minWidth: 50,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const apiResponse = params.row.api_response_parsed;

          if (Object.keys(apiResponse).length === 0) {
            // If the object is empty, display a message for an empty response
            return <div>No Data</div>;
          } else if (apiResponse.error) {
            // If "error" key is present, display the error
            return <div>{apiResponse.error}</div>;
          } else if (apiResponse.data) {
            // If "data" key is present, display the data
            return (
              <div>
                <p>{apiResponse.data.age_range}</p>
                {/* <p>Age Range: {apiResponse.data.age_range}</p> */}
                {/* Add more properties as needed */}
              </div>
            );
          } else {
            // Handle other cases as needed
            return <div>Unrecognized API Response</div>;
          }
        } else {
          // Handle cases where api_response_parsed is undefined or not an object
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api_response_parsed_state_state",
      headerName: "State",
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const apiResponse = params.row.api_response_parsed;

          if (Object.keys(apiResponse).length === 0) {
            // If the object is empty, display a message for an empty response
            return <div>No Data</div>;
          } else if (apiResponse.error) {
            // If "error" key is present, display the error
            return <div>{apiResponse.error}</div>;
          } else if (apiResponse.data) {
            // If "data" key is present, display the data
            return (
              <div>
                <p>{apiResponse.data.state}</p>
                {/* <p>Age Range: {apiResponse.data.age_range}</p> */}
                {/* Add more properties as needed */}
              </div>
            );
          } else {
            // Handle other cases as needed
            return <div>Unrecognized API Response</div>;
          }
        } else {
          // Handle cases where api_response_parsed is undefined or not an object
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api_response_parsed_state_gender",
      headerName: "Gender",
      minWidth: 40,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const apiResponse = params.row.api_response_parsed;

          if (Object.keys(apiResponse).length === 0) {
            // If the object is empty, display a message for an empty response
            return <div>No Data</div>;
          } else if (apiResponse.error) {
            // If "error" key is present, display the error
            return <div>{apiResponse.error}</div>;
          } else if (apiResponse.data) {
            // If "data" key is present, display the data
            return (
              <div>
                <p>{apiResponse.data.gender}</p>
                {/* <p>Age Range: {apiResponse.data.age_range}</p> */}
                {/* Add more properties as needed */}
              </div>
            );
          } else {
            // Handle other cases as needed
            return <div>Unrecognized API Response</div>;
          }
        } else {
          // Handle cases where api_response_parsed is undefined or not an object
          return <div>No API Response</div>;
        }
      },
    },
    // {
    //   field: "api_response_parsed_is_mobile",
    //   headerName: "Mobile Linked",
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
    //       const apiResponse = params.row.api_response_parsed;

    //       if (Object.keys(apiResponse).length === 0) {
    //         // If the object is empty, display a message for an empty response
    //         return <div>No Data</div>;
    //       } else if (apiResponse.error) {
    //         // If "error" key is present, display the error
    //         return <div>{apiResponse.error}</div>;
    //       } else if (apiResponse.data) {
    //         // If "data" key is present, display the data
    //         return (
    //           <div>
    //             <p>{apiResponse.data.is_mobile}</p>
    //             {console.log(apiResponse.data.is_mobile)}
    //           </div>
    //         );
    //       } else {
    //         // Handle other cases as needed
    //         return <div>Unrecognized API Response</div>;
    //       }
    //     } else {
    //       // Handle cases where api_response_parsed is undefined or not an object
    //       return <div>No API Response</div>;
    //     }
    //   },
    // },

    {
      field: "api_response_parsed_state remarks",
      headerName: "Message",
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const apiResponse = params.row.api_response_parsed;

          if (Object.keys(apiResponse).length === 0) {
            // If the object is empty, display a message for an empty response
            return <div>No Data</div>;
          } else if (apiResponse.error) {
            // If "error" key is present, display the error
            return <div>{apiResponse.error}</div>;
          } else if (apiResponse.data) {
            // If "data" key is present, display the data
            return (
              <div>
                <p>{apiResponse.data.remarks}</p>
                {/* <p>Age Range: {apiResponse.data.age_range}</p> */}
                {/* Add more properties as needed */}
              </div>
            );
          } else {
            // Handle other cases as needed
            return <div>Unrecognized API Response</div>;
          }
        } else {
          // Handle cases where api_response_parsed is undefined or not an object
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 150,
    },

  ];

  if (kycUserAadhaarLogs.length === 0) {
    return (
      <Box>
        <Header
          title={`User ID: ${userId}`}
          subtitle={`Phone Number: ${phoneNumber}`}
        />
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
              Dashboard
            </Link>
            <Link underline="hover" color="inherit" component={RouterLink} to="/kycDetails">
              KYC User List
            </Link>
            <Link underline="hover" color="inherit" component={RouterLink} to="#">
              User ID: {userId}
            </Link>
            {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
          </Breadcrumbs>
        </div>
        Not Attempted Aadhaar Verification
      </Box>
    )
  }
  else {
    return (
      <>
        <Box m="20px">
          <Header
            title={`User ID: ${userId}`}
            subtitle={`Phone Number: ${phoneNumber}`}
          />
          <Box m="40px 0 0 0" height="65vh">
            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                  Dashboard
                </Link>
                <Link underline="hover" color="inherit" component={RouterLink} to="/kycDetails">
                  KYC User List
                </Link>
                <Link underline="hover" color="inherit" component={RouterLink} to="#">
                  User ID: {userId}
                </Link>
                {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
              </Breadcrumbs>
            </div>
            <Box height="75vh" width="100%"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none"
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal"
                  },
                  "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                    height: "unset !important"
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    // Forced to use important since overriding inline styles
                    maxHeight: "168px !important"
                  },
                  "& .MuiDataGrid-cellContent": {
                    whiteSpace: "normal",
                    overflow: "visible",
                    wordWrap: "break-word",
                  },
                }}
                rowHeight={75}
                rows={kycUserAadhaarLogs}
                columns={columns}
                loading={loading}
                pagination
                //   page={page}
                getRowId={(row) => row.id}
                hideFooterPagination={true}
                onCellClick={handleRowClick}

              //   onPageChange={handlePageChange}
              //   pageSize={pageSize}
              //   rowCount={totalRows}
              />
            </Box>
            <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
              <DialogTitle>User Details</DialogTitle>
              <DialogContent>
                {selectedRow && (
                  <Box>
                    <table>
                      <tbody>
                        {columns.map((column) => (
                          <tr key={column.field}>
                            {column.field !== 'Sl_No' && ( // Exclude column 1
                              <>
                                <th>{column.headerName}</th>
                                <td>
                                  {column.renderCell
                                    ? column.renderCell({ row: selectedRow })
                                    : column.valueGetter
                                      ? column.valueGetter({ row: selectedRow })
                                      : selectedRow[column.field]}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="warning" onClick={handleCloseModal}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </>
    );
  }
}

export default KycUserLogs;