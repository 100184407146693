import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";
import Header from "../../components/Header";
// Use These if needed in future
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { getAccessToken } from "../../utils/authUtils";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import authApi from "../../api";
import { totalEgoldUserCountApi } from "../../api";
import { totalEgoldPlanCountApi } from "../../api";
import { totalEgoldBankDetailsCountApi } from "../../api";
import { totalEgoldWithdrawalCountApi, totalEgoldAUMCountApi, totalEgoldAugmontProductOrdersCountApi, totalEgoldAugmontTransactionCountApi, totalEgoldUserTransactionCountApi, totalUPIDetails, totalRewardCount, totalReferAndEarnCount, totalContestsCount } from "../../api";


const GoldDashboard = () => {


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // Base API URL
  const base_url = "https://proapi.getplus.in";
  // Need to make the access token Dynamic so we dont have to manually change it everytime
  // const access_token = getAccessToken();

  // State to set the data fetched from the API on UI
  const [totalEgoldUsers, setTotalEgoldUsers] = useState([]);
  const [totalEgoldPlan, setTotalEgoldPlan] = useState([]);
  const [egoldBankDetailsCount, setEgoldBankDetailsCount] = useState([]);
  const [egoldWithdrawalCount, setEgoldWithdrawalCount] = useState([]);
  const [egoldAugmontProductOrderCount, setEgoldAugmontProductOrderCount] = useState([]);
  const [totalSip, setTotalSip] = useState([]);
  const [totaGoldCoinOrders, setTotalGoldCoinOrders] = useState([]);
  const [totalKycCompleted, setTotalKycCompleted] = useState([]);
  const [totalContests, setTotalContests] = useState([]);
  const [totalWithdrawals, setTotalWithdrawals] = useState([]);
  const [totalUpcomingDeposits, setTotalUpcomingDeposits] = useState([]);
  const [totalEgoldAugmontTransaction, setTotalEgoldAugmontTransaction] = useState([]);
  const [totalEgoldUserTransaction, setTotalEgoldUserTransaction] = useState([]);
  const [totalEgoldAUM, setTotalEgoldAUM] = useState([]);
  const [totalUPIDetailsCount, setTotalUPIDetailsCount] = useState([])
  const [totalRewatdListCount, setTotalRewatdListCount] = useState([])
  const [totalReferAndEarnListCount, setTotalReferAndEarnListCount] = useState([])
  const [totalContestsListCount, setTotalContestsListCount] = useState([])
  const navigate = useNavigate();
  // Callback will be called on every re-render. The useEffect() hook is used to run a side-effect, every time the component’s state changes. It takes a callback function as its first argument. This callback function will get called when the state of the component is changed.

  //This is to fetch the total number of the user count
  useEffect(() => {
    fetchTotalEgoldUserCount();
    fetchTotalEgoldPlanCount();
    fetchTotalEgoldBankDetailsCount();
    fetchTotalEgoldWithdrawalCount();
    fetchTotalEgoldAugmontProductOrderCount();
    fetchTotalEgoldAugmontTransactionCount();
    fetchTotalEgoldUserTransactionCount();
    fetchTotalEgoldAUMCount();
    fetchTotalUPIDetailsCount();
    fetchTotalRewardListCount();
    fetchTotalReferAndEarnListCount();
    fetchTotalContestsCount();
    fetchTotalKycCompleted();
  }, []);

  // dynamic api call function 



  const fetchTotalContestsCount = async () => {
    try {
      const data = await totalContestsCount();
      setTotalContestsListCount(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  }

  const fetchTotalReferAndEarnListCount = async () => {
    try {
      const data = await totalReferAndEarnCount();
      setTotalReferAndEarnListCount(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  }

  const fetchTotalRewardListCount = async () => {
    try {
      const data = await totalRewardCount();
      setTotalRewatdListCount(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  }

  const fetchTotalUPIDetailsCount = async () => {
    try {
      const data = await totalUPIDetails();
      setTotalUPIDetailsCount(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  }

  const fetchTotalEgoldUserCount = async () => {
    try {
      const data = await totalEgoldUserCountApi();
      setTotalEgoldUsers(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalEgoldPlanCount = async () => {
    try {
      const data = await totalEgoldPlanCountApi();
      setTotalEgoldPlan(data.count);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalEgoldBankDetailsCount = async () => {
    try {
      const data = await totalEgoldBankDetailsCountApi();
      setEgoldBankDetailsCount(data.total);
    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  };
  //This is to fetch the total SIP Money
  const fetchTotalEgoldWithdrawalCount = async () => {
    try {
      const data = await totalEgoldWithdrawalCountApi();
      setEgoldWithdrawalCount(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalEgoldAugmontProductOrderCount = async () => {
    try {
      const data = await totalEgoldAugmontProductOrdersCountApi();
      setEgoldAugmontProductOrderCount(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalEgoldAugmontTransactionCount = async () => {
    try {
      const data = await totalEgoldAugmontTransactionCountApi();
      setTotalEgoldAugmontTransaction(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalEgoldUserTransactionCount = async () => {
    try {
      const data = await totalEgoldUserTransactionCountApi();
      setTotalEgoldUserTransaction(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalEgoldAUMCount = async () => {
    try {
      const data = await totalEgoldAUMCountApi();
      setTotalEgoldAUM(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };
  const fetchTotalGoldCoinOrders = async () => {
    try {
      const data = await authApi.totalGoldCoinOrders();
      setTotalGoldCoinOrders(data.total);
    } catch (error) {
      // console.log("Error fetching data:", error);
      navigate('/');
    }
  };
  const fetchTotalKycCompleted = async () => {
    try {
      const data = await authApi.totalKycCompleted();
      setTotalKycCompleted(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };

  const fetchTotalContests = async () => {

    try {
      const data = await authApi.totalContests();
      setTotalContests(data.total);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  }
  const fetchTotalUpcomingDeposits = async () => {
    try {
      const data = await authApi.totalUpcomingDeposits();
      setTotalUpcomingDeposits(data.instalments);
    } catch (error) {
      navigate('/');
      console.log("Error fetching data:", error);
    }
  };
  // useEffect(()=>{
  //   fetchTotalWithdrawals();
  // }, []);

  const fetchTotalWithdrawals = async () => {
    const apiUrl = `${base_url}/api/v1/ccapi/withdrawal/total-withdrawal-requests`;
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");

        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setTotalWithdrawals(data.total);

    } catch (error) {
      navigate(`/`);
      console.log("Error fetching data:", error);
    }
  }

  return (
    <>
      <Box margin="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="GOLD DASHBOARD" subtitle="Welcome to your Gold Dashboard" />
        </Box>

        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" component={RouterLink} to={"/home"}>
              Home
            </Link>
            <Link underline="hover" color="inherit" component={RouterLink} to={"#"}>
              Gold Dashboard
            </Link>
          </Breadcrumbs>
        </div>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/totalegoldusers"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {/* Total count of Users Displayed here */}
                    <h2 style={{ textAlign: "center" }}>{totalEgoldUsers}</h2>
                    <h3 style={{ textAlign: "center" }}>Total E-Gold Users</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">E-Gold Users</Button>
                    </div>
                  </div>

                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/egoldplanlist"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalEgoldPlan}</h2>
                    <h3 style={{ textAlign: "center" }}>E-Gold Plan List</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">Plan List</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/upiDetails"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalUPIDetailsCount}</h2>
                    <h3 style={{ textAlign: "center" }}>
                      UPI&nbsp;Details
                    </h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View UPI Details</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/egoldwithdrawal"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{egoldWithdrawalCount}</h2>
                    <h3 style={{ textAlign: "center" }}>
                      Cash Withdrawal
                    </h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Cash Withdrawal List</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/augmontproductorderlist"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>
                      {egoldAugmontProductOrderCount}
                    </h2>
                    <h3 style={{ textAlign: "center" }}>
                      Augmont&nbsp;Product&nbsp;Orders
                    </h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Orders</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/augmonttransactionlist"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalEgoldAugmontTransaction}</h2>
                    <h3 style={{ textAlign: "center" }}>Augmont Transactions</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Transactions</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/egoldusertransactionlist"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalEgoldUserTransaction}</h2>
                    <h3 style={{ textAlign: "center" }}>User Transaction</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Transactions</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/goldAUMActivePlanUsers"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>Rs {parseFloat(totalEgoldAUM).toFixed(2)}</h2>
                    <h3 style={{ textAlign: "center" }}>Total AUM</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Active Users</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            {/* Jeweller Redemption */}
            {/* <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/#"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>3000</h2>
                    <h3 style={{ textAlign: "center" }}>Jeweller&nbsp;Redemptions</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View Jeweller Redemption</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid> */}

            {/* Refer and earn */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/refer_and_earn_list"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalReferAndEarnListCount}</h2>
                    <h3 style={{ textAlign: "center" }}>Refer&nbsp;And Earn</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View List</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Rewards */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/rewardsList"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalRewatdListCount}</h2>
                    <h3 style={{ textAlign: "center" }}>Rewards</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View List</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* KYC LOGS */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/kycDetails"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalKycCompleted}</h2>
                    <h3 style={{ textAlign: "center" }}>KYC Logs</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View List</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>

            {/* Contests */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/contests"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}>{totalContestsListCount}</h2>
                    <h3 style={{ textAlign: "center" }}>Contests</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View List</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
            {/* Support */}
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <RouterLink to={"/supportCards"} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: colors.primary[400],
                    height: "12em",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h2 style={{ textAlign: "center" }}></h2>
                    <h3 style={{ textAlign: "center" }}>Support</h3>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button variant="outlined" color="warning">View</Button>
                    </div>
                  </div>
                </Card>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default GoldDashboard;