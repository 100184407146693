import { Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@emotion/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAccessToken } from "../../utils/authUtils";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import GetAppIcon from '@mui/icons-material';  
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import authApi from "../../api";
import { useUserAuth } from "../../context/AuthProvider";

const KycUserPanLogs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //using this to fetch the userId and phone numbers from TotalUsers dataTable
  const { userId, phoneNumber } = useParams();
  const [kycUserPanLogs, setkycUserPanLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [openImagePopup, setOpenImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Prepping to fetch the API

  const base_url = `https://proapi.getplus.in`;
  const navigate = useNavigate();
  // const access_token = getAccessToken();

  useEffect(() => {
    // Fetch user saving plans based on the userId/investor-id
    fetchUserKycDetails(userId);
  }, [userId]);

  //Fetching the Saving Plans List of a user using userId/investor-id
  const fetchUserKycDetails = async (userId) => {

    setLoading(true);
    const apiUrl = `${base_url}/api/v1/ccapi/kyc/investor-kyc-logs?investor_id=${userId}`;
    try {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        // Handle the case where there is no access token
        console.error("Access token not found");
        navigate('/')
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(apiUrl, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setkycUserPanLogs(data.pan_card); //only the list of saving plans
      // console.log(data.pan_card); //all the data from the api
      //   setTotalRows(data.count);
      //   setNextPage(data.next);
      //   setPreviousPage(data.previous);
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    } finally {
      setLoading(false);
    }
  };
  // calling popup
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenImagePopup(true);
  };

  const handleDownloadLogs = async () => {
    try {
      const response = await authApi.getDownloadLogs({ targeted_investor: userId, data_name: "PAN_CARD" });

      // console.log(response)
    } catch (error) {
      console.error("Error fetching user saving plans:", error);
    }

  }

  const columns = [
    {
      field: "Sl_No",
      headerName: "Sl No",
      renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1

    },
    {

      field: "id",
      headerName: "PAN ID",
      minWidth: 150,
    },
    {
      field: "pan_card",
      headerName: "PAN Image",
      minWidth: 150,
      renderCell: (params) => {
        const imageUrl = params.row.pan_card;

        if (imageUrl) {
          return (
            <img
              src={imageUrl}
              alt="Pan Card"
              style={{ width: '100px', height: 'auto', cursor: 'pointer', }}
              draggable="false"
              onClick={() => handleImageClick(params.value)}
            />
          );
        }

        return null;
      },
    },
    {
      field: "api response valid",
      headerName: "PAN Status",
      minWidth: 100,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const api_response_parsed = params.row.api_response_parsed;

          if (Object.keys(api_response_parsed).length === 0) {
            return <div>No Data</div>;
          } else if (Object.keys(api_response_parsed).length > 0) {
            return (
              <div>
                <p>{api_response_parsed.status}</p>
              </div>
            );
          } else {
            return <div>Unrecognized API Response</div>;
          }
        } else {
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api response name",
      headerName: "Name",
      minWidth: 100,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const api_response_parsed = params.row.api_response_parsed;

          if (Object.keys(api_response_parsed).length === 0) {
            return <div>No Data</div>;
          } else if (Object.keys(api_response_parsed).length > 0) {
            return (
              <div>
                <p>{api_response_parsed.name}</p>
              </div>
            );
          } else {
            return <div>Unrecognized API Response</div>;
          }
        } else {
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api response pan type",
      headerName: "PAN Type",
      minWidth: 100,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const api_response_parsed = params.row.api_response_parsed;

          if (Object.keys(api_response_parsed).length === 0) {
            return <div>No Data</div>;
          } else if (Object.keys(api_response_parsed).length > 0) {
            return (
              <div>
                <p>{api_response_parsed.pan_type}</p>
              </div>
            );
          } else {
            return <div>Unrecognized API Response</div>;
          }
        } else {
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api response pan number",
      headerName: "PAN Number",
      minWidth: 100,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const api_response_parsed = params.row.api_response_parsed;

          if (Object.keys(api_response_parsed).length === 0) {
            return <div>No Data</div>;
          } else if (Object.keys(api_response_parsed).length > 0) {
            return (
              <div>
                <p>{api_response_parsed.pan}</p>
              </div>
            );
          } else {
            return <div>Unrecognized API Response</div>;
          }
        } else {
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api response DOB",
      headerName: "DOB",
      minWidth: 100,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const api_response_parsed = params.row.api_response_parsed;

          if (Object.keys(api_response_parsed).length === 0) {
            return <div>No Data</div>;
          } else if (Object.keys(api_response_parsed).length > 0) {
            return (
              <div>
                <p>{api_response_parsed.dob}</p>
              </div>
            );
          } else {
            return <div>Unrecognized API Response</div>;
          }
        } else {
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "api response father",
      headerName: "Father Name",
      minWidth: 100,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const api_response_parsed = params.row.api_response_parsed;

          if (Object.keys(api_response_parsed).length === 0) {
            return <div>No Data</div>;
          } else if (Object.keys(api_response_parsed).length > 0) {
            return (
              <div>
                <p>{api_response_parsed.father}</p>
              </div>
            );
          } else {
            return <div>Unrecognized API Response</div>;
          }
        } else {
          return <div>No API Response</div>;
        }
      },
    },
    // {
    //   field: "api_response_parsed",
    //   headerName: "API Response",
    //   minWidth: 200,
    //   renderCell: (params) => {
    //     if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
    //       const api_response_parsed = params.row.api_response_parsed;

    //       if (Object.keys(api_response_parsed).length === 0) {
    //         // If the object is empty, display a message for an empty response
    //         return <div>No Data</div>;
    //     //   } else if (api_response_parsed.error) {
    //     //     // If "error" key is present, display the error
    //     //     return <div>{api_response_parsed.error}</div>;
    //       } else if (Object.keys(api_response_parsed).length > 0) {
    //         // If "data" key is present, display the data
    //         return (
    //           <div>
    //             <p>Status: {api_response_parsed.status}</p>
    //             <p>Reference ID: {api_response_parsed.reference_id}</p>
    //             <p>Verification ID: {api_response_parsed.verification_id}</p>
    //             <p>Message: {api_response_parsed.message}</p>
    //             {/* Add more properties as needed */}
    //           </div>
    //         );
    //       } else {
    //         // Handle other cases as needed
    //         return <div>Unrecognized API Response</div>;
    //       }
    //     } else {
    //       // Handle cases where api_response_parsed is undefined or not an object
    //       return <div>No API Response</div>;
    //     }
    //   },
    // },

    {
      field: "api response message",
      headerName: "Message",
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.api_response_parsed && typeof params.row.api_response_parsed === "object") {
          const api_response_parsed = params.row.api_response_parsed;

          if (Object.keys(api_response_parsed).length === 0) {
            return <div>No Data</div>;
          } else if (Object.keys(api_response_parsed).length > 0) {
            return (
              <div>
                {/* <p>Status: {api_response_parsed.status}</p>
                <p>Reference ID: {api_response_parsed.reference_id}</p>
                <p>Verification ID: {api_response_parsed.verification_id}</p> */}
                <p>{api_response_parsed.message}</p>
              </div>
            );
          } else {
            return <div>Unrecognized API Response</div>;
          }
        } else {
          return <div>No API Response</div>;
        }
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      renderCell: (params) => {
        const timestamp = params.row.created_at;
        if (timestamp === null) {
          return <div>No date</div>; // Handle null date
        }

        // Create a new Date object from the timestamp
        const dateObject = new Date(timestamp);

        // Get the date in YYYY-MM-DD format
        const date = dateObject.toISOString().split("T")[0];

        // Get the time in HH:MM:SS format
        const time = dateObject.toTimeString().split(" ")[0];

        return (
          <div>
            <div>Date: {date}</div>
            <div>Time: {time}</div>
          </div>
        );
      },
      minWidth: 100,
    },

  ];

  if (kycUserPanLogs.length === 0) {
    return (<Box m="20px">
      <Header
        title={`User ID: ${userId}`}
        subtitle={`Phone Number: ${phoneNumber}`}
      />
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" component={RouterLink} to="/kycDetails">
            KYC User List
          </Link>
          <Link underline="hover" color="inherit" component={RouterLink} to="#">
            User ID: {userId}
          </Link>
          {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
        </Breadcrumbs>
      </div>
      Not attempted PAN Verification
    </Box>
    );
  }
  else {
    return (
      <>
        <Box m="20px">
          <Header
            title={`User ID: ${userId}`}
            subtitle={`Phone Number: ${phoneNumber}`}
          />
          <Box m="40px 0 0 0" height="65vh">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                  Dashboard
                </Link>
                <Link underline="hover" color="inherit" component={RouterLink} to="/kycDetails">
                  KYC User List
                </Link>
                <Link underline="hover" color="inherit" component={RouterLink} to="#">
                  User ID: {userId}
                </Link>
                {/* <Typography color="text.primary">Breadcrumbs</Typography> */}
              </Breadcrumbs>
            </div>
            <Box height="75vh" width="100%"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none"
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <DataGrid
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal"
                  },
                  "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                    height: "unset !important"
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    // Forced to use important since overriding inline styles
                    maxHeight: "168px !important"
                  },
                  "& .MuiDataGrid-cellContent": {
                    whiteSpace: "normal",
                    overflow: "visible",
                    wordWrap: "break-word",
                  },
                }}
                rowHeight={120}
                rows={kycUserPanLogs}
                columns={columns}
                loading={loading}
                pagination
                getRowId={(row) => row.id}
                hideFooterPagination={true}
                onCellClick={handleRowClick}

              />
            </Box>
            <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
              <DialogTitle>User Details</DialogTitle>
              <DialogContent>
                {selectedRow && (
                  <Box>
                    <table>
                      <tbody>
                        {columns.map((column) => (
                          <tr key={column.field}>
                            {column.field !== 'Sl_No' && ( // Exclude column 1
                              <>
                                <th>{column.headerName}</th>
                                <td>
                                  {column.renderCell
                                    ? column.renderCell({ row: selectedRow })
                                    : column.valueGetter
                                      ? column.valueGetter({ row: selectedRow })
                                      : selectedRow[column.field]}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="warning" onClick={handleCloseModal}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openImagePopup}
              onClose={() => setOpenImagePopup(false)}
              maxWidth="md"
            >
              <DialogTitle>
                PAN Card
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setOpenImagePopup(false)}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <a
                  href={selectedImage}
                  download="panImage.jepg" // Use the custom file name
                  style={{ marginLeft: 'auto', textDecoration: 'none' }}

                >
                  <IconButton
                    color="inherit"
                    aria-label="download"
                    onClick={handleDownloadLogs}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                </a>
              </DialogTitle>

              <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={selectedImage} alt="Image" draggable="false" style={{ width: '50%', borderRadius: '20px' }} />
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
      </>
    );
  }
}

export default KycUserPanLogs;